import { City } from '@/models/entity/City';

/**
 * Common model for profile details for seller, employee and manager.
 *
 * (?) - Non mandatory field since not all three models contain a field.
 */
export class MyProfile {
  constructor(data) {
    if (data) {
      /** Personal details **/
      this.name = data.name;
      this.surname = data.surname;
      this.address = data.address;
      /** @type {City} **/
      this.city = data.city;
      this.phoneNumber = data.phoneNumber;
      /* (?) */
      this.company = data.company;
      /* (?) */
      this.embg = data.embg;

      /** Account details **/
      this.username = data.username;
      this.email = data.email;
      this.role = data.role;
      this.created = data.created;
      this.lastLogin = data.lastLogin;
      this.active = data.active;

    }
  }
}
