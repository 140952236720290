<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog"
       aria-labelledby="signaturePadDialog"
       aria-hidden="true"
       data-keyboard="false" data-backdrop="static"
  >
    <div class="modal-dialog modal-notify modal-info" role="document">
      <!--Content-->
      <div class="modal-content text-center">

        <!--Header-->
        <div class="modal-header d-flex justify-content-center">
          <p class="heading">{{ text }}</p>
        </div>

        <!--Body-->
        <div class="modal-body text-center">
          <VueSignaturePad
              style="border: 1px solid black; border-radius: 5px; margin: auto;"
              id="signature"
              width="300px"
              height="200px"
              ref="signaturePad"
              :options="{onBegin, onEnd}"
          />
        </div>

        <!--Footer-->
        <div class="modal-footer flex-center">
          <a v-if="showSignButton" type="button" class="ternary-button" style="float: left;"
             v-on:click="handleSuccess"
          >
            Потпиши
          </a>
          <a type="button" class="ternary-button" v-on:click="handleCancel">
            Откажи
          </a>
        </div>
      </div>
      <!--/.Content-->
    </div>
  </div>
</template>

<script>
  import $ from 'jquery'
  import { useAppService } from '../../service/AppService';

  const appService = useAppService()

  export default {
    name: 'SignaturePadDialog',
    data() {
      return {
        id: appService.generateRandomAlphanumericString(10),
        showSignButton: false,
        setShowSignButton(value) {
          this.showSignButton = value
        }
      }
    },
    props: {
      text: {
        required: true,
        type: String
      },
      showing: {
        required: true,
        type: Boolean
      },
      onSuccess: {
        type: Function,
        required: true
      },
      onCancel: {
        type: Function,
        required: true
      }
    },
    methods: {
      onBegin() {
        this.$refs.signaturePad.resizeCanvas();
      },
      onEnd() {
        const isEmpty = this.$refs.signaturePad.isEmpty();

        if (!isEmpty) {
          this.setShowSignButton(true);
        }
      },
      handleSuccess() {
        let signature = this.$refs.signaturePad.saveSignature();
        let signatureBase64 = signature.data;

        this.setShowSignButton(false);
        this.$refs.signaturePad.clearSignature();
        this.onSuccess(signatureBase64);
      },
      handleCancel() {
        this.$refs.signaturePad.clearSignature();
        this.setShowSignButton(false);
        this.onCancel();
      },
      updateState() {
        const show = this.showing ? 'show' : 'hide';

        $('#' + this.id).modal(show);
      }
    },
    mounted() {
      this.updateState();
    },
    updated() {
      this.updateState();
    }
  }
</script>

<style scoped src="@/assets/css/partial/dialog-css.css" />