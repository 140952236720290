<template>
  <TitledPage
      title="Додавање на нов корисник продавач во улога на застапник на компанија"
      :on-back="onBack"
  >
    <PersonalInfoRequest helper-creation />
  </TitledPage>
</template>

<script>
  import PersonalInfoRequest from '@/screens/login/PersonalInfoRequest';
  import TitledPage from '@/shared/layout/TitledPage';
  import router, { resolveBaseRoute } from '@/router';

  export default {
    name: 'SellerLegalPersonConnectAccountPage',
    components: {TitledPage, PersonalInfoRequest},
    methods: {
      onBack() {
        router.push({
          name: resolveBaseRoute().nested.Accounts.name
        })
      }
    }
  }
</script>

<style scoped>

</style>