<template>
  <LoadingSpinner />
  <p>Плаќањето е во тек. Не ја освежувајте страницата!</p>
</template>

<script>
  import LoadingSpinner from '../partial/LoadingSpinner';
  import { useRoute } from 'vue-router';
  import axios from 'axios';
  import Response from '@/models/backend/Response';
  import router, { ROUTES } from '@/router';
  import { useNotificationService } from '@/service/NotificationService';
  import { useLocalStorageUtils } from '@/utils/LocalStorageUtils';
  import { useResponseCodes } from '@/models/backend/ResponseCodes';

  const notificationService = useNotificationService()
  const localStorageUtils = useLocalStorageUtils()

  export default {
    name: 'PaymentVerification',
    components: {LoadingSpinner},
    mounted() {
      const {infoMessage} = useRoute().params
      notificationService.maybeShowInfoModal(infoMessage)
      this.verifyPayment();
    },
    methods: {
      verifyPayment() {
        const responseCodes = useResponseCodes()
        const sessionId = localStorageUtils.getPaymentSessionId()

        axios.post(`verifyCheckoutSession?sessionId=${sessionId}`).then(it => {
          let response = new Response(it);

          localStorageUtils.removePaymentSessionId()
          localStorageUtils.removePaymentSessionUrl()

          router.push({
                name: ROUTES.Seller.nested.Invoices.nested.Invoice.name,
                params: {
                  uin: response.data.uin,
                  infoMessage: response.message
                }
              }
          )
        }).catch(it => {
          let response = new Response(it.response);

          switch (response.substatusCode) {
            case responseCodes.SESSION_NOT_FINALIZED: {
              // retry payment with the url
              window.location.href = localStorageUtils.getPaymentSessionUrl()
              return;
            }
            default: {
              // clear local storage containing payment session data
              localStorageUtils.removePaymentSessionId()
              localStorageUtils.removePaymentSessionUrl()
              break;
            }
          }

          notificationService.showInfoModal(response.message)
        }).finally(() => {

        });
      }
    }
  }
</script>

<style scoped>

</style>