<template>
  <div class="outer-spinner">
    <div class="my-spinner"></div>
  </div>
</template>

<script>
  export default {
    name: 'LoadingSpinner'
  }
</script>

<style scoped>
  .outer-spinner {
    position: relative;
  }

  .my-spinner {
    position: absolute;
    top: calc(50% - 35px/2);
    left: calc(50% - 35px/2);
    margin: 0;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 6.4px solid #ffffff;
    animation: spinner-bulqg1 0.96s infinite linear alternate,
    spinner-oaa3wk 1.92s infinite linear;
  }
  @keyframes spinner-bulqg1 {
    0% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }

    12.5% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
    }

    25% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
    }

    50% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    62.5% {
      clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    75% {
      clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }

    100% {
      clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
  }
  @keyframes spinner-oaa3wk {
    0% {
      transform: scaleY(1) rotate(0deg);
    }

    49.99% {
      transform: scaleY(1) rotate(135deg);
    }

    50% {
      transform: scaleY(-1) rotate(0deg);
    }

    100% {
      transform: scaleY(-1) rotate(-135deg);
    }
  }
  /*-----------------*/
</style>