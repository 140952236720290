<template>
  <div v-if="rows" class="row justify-content-center">
    <div class="col-sm-12">
      <DataTable v-if="rows.length" :value="rows"
                 auto-layout
                 :sort-field="defaultSort" :sortOrder="-1" removableSort
                 paginator :rows="10" :rowsPerPageOptions="[10,20,50]"
                 currentPageReportTemplate="Вкупно {totalRecords} записи."
                 paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport"
      >
        <Column v-for="col of columns" :field="col.field" :sort-field="col.sortableField"
                :header="col.header" :key="col.field" :sortable="col.sortable"
        />
        <Column :exportable="false">
          <template #body="slotProps">
            <router-link :to="pathToConnectAccountNavigation(slotProps.data.username)">
              <MainButton
                  small-btn
                  light-layer
                  text="Поврзи корисник"
              />
            </router-link>
          </template>
        </Column>
      </DataTable>
      <div v-else class="alert alert-danger">{{ emptyGridMessage }}</div>
    </div>
  </div>
</template>

<script>
  import MainButton from '@/screens/partial/MainButton';

  export default {
    name: 'AccountsGrid',
    components: {MainButton},
    props: {
      pathToConnectAccountNavigation: {
        required: false,
        type: Function
      },
      rows: {
        required: false,
        type: Array(Object)
      },
      columns: {
        required: true,
        type: Array(Object)
      },
      emptyGridMessage: {
        required: true,
        type: String
      }
    },
    computed: {
      defaultSort() {
        const sortDefault = this.columns.filter(col => col.defaultSortable)[0];

        if (sortDefault) {
          return sortDefault.sortableField ? sortDefault.sortableField : sortDefault.field;
        }
        return undefined;
      }
      /** ------------------------------ Permissions ------------------------------ **/
    }
  }
</script>

<style scoped>

</style>