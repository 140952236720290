<template>
  <PageLayout>
    <div v-if="permissionService.isAuthorizedManager()" class="row justify-content-center">
      <router-link class="col-sm-3 col-sm-3 mb-3" :to="pathToMyProfileNavigation()">
        <CardView :card-title="getLabel('label.title.my_profile')"></CardView>
      </router-link>
      <router-link class="col-sm-3 mb-5" :to="pathToInvoicesNavigation()">
        <CardView card-title="Недоделени фактури"></CardView>
      </router-link>
      <router-link class="col-sm-3 mb-5" :to="pathToInvoicesNavigation(true)">
        <CardView card-title="Доделени фактури"></CardView>
      </router-link>
      <router-link class="col-sm-3 mb-5" :to="pathToUnconnectedAccounts()">
        <CardView card-title="Неповрзани кориснички сметки"></CardView>
      </router-link>
      <router-link class="col-sm-3 mb-5" :to="pathToEmployeesNavigation()">
        <CardView card-title="Вработени"></CardView>
      </router-link>
      <router-link class="col-sm-3 mb-5" :to="pathToCompaniesNavigation()">
        <CardView card-title="Компании"></CardView>
      </router-link>
    </div>
    <div v-if="permissionService.isAuthorizedEmployee()" class="row justify-content-center">
      <router-link class="col-sm-3 mb-5" :to="pathToMyProfileNavigation()">
        <CardView :card-title="getLabel('label.title.my_profile')"></CardView>
      </router-link>
      <router-link class="col-sm-3 mb-5" :to="pathToInvoicesNavigation()">
        <CardView :card-title="getLabel('label.title.my_invoices')"></CardView>
      </router-link>
      <router-link class="col-sm-3 mb-5" :to="pathToInvoicesNavigation(true)">
        <CardView card-title="Завршени фактури"></CardView>
      </router-link>
    </div>
    <div v-if="permissionService.isAuthorizedSeller()" class="row justify-content-center">
      <router-link class="col-sm-3 mb-5" :to="pathToMyProfileNavigation()">
        <CardView :card-title="getLabel('label.title.my_profile')"></CardView>
      </router-link>
      <router-link class="col-sm-3 mb-5" :to="pathToInvoicesNavigation()">
        <CardView :card-title="getLabel('label.title.my_invoices')"></CardView>
      </router-link>
    </div>
  </PageLayout>
</template>

<script>
  import CardView from '../partial/CardView';
  import { useNotificationService } from '@/service/NotificationService';
  import { resolveBaseRoute, ROUTES } from '@/router';
  import { useRoute } from 'vue-router';
  import { useInvoicePermissions } from '@/service/permission/InvoicePermissions';
  import { usePermissionService } from '@/service/PermissionService';
  import PageLayout from '@/shared/layout/PageLayout';
  import {useMessageSource} from "@/config/MessageSource";

  const notificationService = useNotificationService()
  const {getLabel} = useMessageSource()

  export default {
    name: 'Dashboard',
    components: {PageLayout, CardView},
    mounted() {
      // TODO aboykov Why is this used?
      window.scrollTo(0, 0);

      const {infoMessage} = useRoute().params;

      notificationService.maybeShowInfoModal(infoMessage)
    },
    methods: {
      getLabel,
      /** --------------------- Navigation resolver functions --------------------- **/
      pathToMyProfileNavigation() {
        return ROUTES.Dashboard.nested.MyProfile.path;
      },
      pathToCompaniesNavigation: () => {
        return resolveBaseRoute().nested.Companies.path;
      },
      pathToInvoicesNavigation: (special = false) => {
        return special ?
            resolveBaseRoute().nested.Invoices.nested.SpecialInvoices.path :
            resolveBaseRoute().nested.Invoices.path;
      },
      pathToUnconnectedAccounts: () => {
        return ROUTES.Manager.nested.Accounts.path;
      },
      pathToEmployeesNavigation: () => {
        return ROUTES.Manager.nested.Employees.path;
      }
    },
    computed: {
      /** ------------------------------ Permissions ------------------------------ **/
      permissionService() {
        return usePermissionService()
      },
      invoicePermissions() {
        return useInvoicePermissions()
      }
    }
  }
</script>

<style scoped>

</style>