<template>
  <div class="card">
    <div class="row d-flex justify-content-center">
      <div class="col-12">
        <ul :class="special ? 'progressbar progressbar-special' : 'progressbar'" class="text-center">
          <li class="active step0"></li>
          <li class="step0" v-bind:class="statusUtils().shouldFillStatusProgress2()?'active':''"></li>
          <li class="step0" v-bind:class="statusUtils().shouldFillStatusProgress3()?'active':''"></li>
          <li v-if="special" class="step0 special-dp"
              v-bind:class="statusUtils().shouldFillStatusProgress4()?'active':''"
          ></li>
          <li class="step0" v-bind:class="statusUtils().shouldFillStatusProgress5()?'active':''"></li>
        </ul>
      </div>
    </div>

    <div class="row justify-content-between top">
      <div class="row d-flex icon-content">
        <img class="icon" :src="require('@/assets/imgs/delivery-progress/processed.png')" alt="x">
        <div class="d-flex flex-column">
          <p class="font-weight-bold" style="max-width: 100px;">
            {{ getLabel('label.invoice.title.status.processed') }}
          </p>
        </div>
      </div>
      <div class="row d-flex icon-content">
        <img class="icon" :src="require('@/assets/imgs/delivery-progress/package-collected.png')" alt="x">
        <div class="d-flex flex-column">
          <p class="font-weight-bold" style="max-width: 100px;">
            {{ getLabel('label.invoice.title.status.collected') }}
          </p>
        </div>
      </div>
      <div class="row d-flex icon-content">
        <img class="icon" :src="require('@/assets/imgs/delivery-progress/transit.png')" alt="x">
        <div class="d-flex flex-column">
          <p class="font-weight-bold" style="max-width: 100px;">
            {{ getLabel('label.invoice.title.status.transit') }}
          </p>
        </div>
      </div>
      <div v-if="special" class="row d-flex icon-content">
        <img class="icon" :src="require('@/assets/imgs/delivery-progress/office.png')" alt="x">
        <div class="d-flex flex-column">
          <p class="font-weight-bold" style="max-width: 100px;">
            {{ getLabel('label.invoice.title.status.in_office') }}
          </p>
        </div>
      </div>
      <div v-if="special" class="row d-flex icon-content">
        <img v-if="statusUtils().isReturned()" class="icon"
             :src="require('@/assets/imgs/delivery-progress/returned.png')" alt="x"
        >
        <img v-else-if="statusUtils().isPickUpUnsuccessful()" class="icon"
             :src="require('@/assets/imgs/delivery-progress/not-delivered.png')" alt="x"
        >
        <img v-else class="icon" :src="require('@/assets/imgs/delivery-progress/delivered.png')" alt="x">

        <div class="d-flex flex-column">
          <p v-if="statusUtils().isReturned()" class="font-weight-bold">
            {{ getLabel('label.invoice.title.status.returned') }}
          </p>
          <p v-else-if="statusUtils().isPickUpUnsuccessful()" class="font-weight-bold" style="max-width: 100px;">
            {{ getLabel('label.invoice.title.status.pickup_unsuccessful') }}
          </p>
          <p v-else class="font-weight-bold" style="max-width: 100px;">
            {{ getLabel('label.invoice.title.status.pickup_successful') }}
          </p>
        </div>
      </div>
      <div v-if="!special" class="row d-flex icon-content">
        <img v-if="statusUtils().isReturned()" class="icon"
             :src="require('@/assets/imgs/delivery-progress/returned.png')" alt="x"
        >
        <img v-else-if="statusUtils().isDeliveryUnsuccessful()" class="icon"
             :src="require('@/assets/imgs/delivery-progress/not-delivered.png')" alt="x"
        >
        <img v-else class="icon" :src="require('@/assets/imgs/delivery-progress/delivered.png')" alt="x">

        <div class="d-flex flex-column">
          <p v-if="statusUtils().isReturned()" class="font-weight-bold" style="max-width: 100px;">
            {{ getLabel('label.invoice.title.status.returned') }}
          </p>
          <p v-else-if="statusUtils().isDeliveryUnsuccessful()" class="font-weight-bold" style="max-width: 100px;">
            {{ getLabel('label.invoice.title.status.unsuccessful_delivery') }}
          </p>
          <p v-else class="font-weight-bold" style="max-width: 100px;">
            {{ getLabel('label.invoice.title.status.delivered') }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="nextStatuses.length > 0" class="row d-flex justify-content-center">
      <div class="col-6 text-center" style="margin-top: 20px;">
        <MainButton v-for="(next, ind) of nextStatuses" v-bind:key="next.id"
                    :style="ind !== nextStatuses.length-1?'margin-right: 10px;':''"
                    :text="getNextStatusButtonText(next)"
                    :on-click="() => handleNextStatusClick(next.id)"
                    :loading="loading[next.id]"
                    small-btn
        />
      </div>
    </div>
  </div>
</template>

<script>
  import MainButton from './MainButton';
  import { STATUS } from '@/service/backend/StatusService';
  import { InvoiceStatus } from '@/models/entity/InvoiceStatus';
  import {Status} from '@/models/entity/Status';
  import {logg} from "@/config/Logging";
  import {useMessageSource} from "@/config/MessageSource";

  const {getLabel} = useMessageSource()

  export default {
    name: 'DeliveryProgress',
    components: {MainButton},
    data() {
      return {
        loading: []
      }
    },
    props: {
      /** @type {InvoiceStatus[]} **/
      statuses: {
        type: Array,
        required: true,
        default: () => []
      },
      /** @type {Status[]} **/
      nextStatuses: {
        type: Array,
        required: true,
        default: () => []
      },
      /** @type {Function} **/
      onNextStatusClick: {
        type: Function,
        required: true,
        default: () => {
        }
      },
      /** @type {Boolean} **/
      special: {
        type: Boolean,
        required: true,
        default: () => false
      }
    },
    methods: {
      getLabel,
      handleNextStatusClick(statusId) {
        this.loading[statusId] = true;

        this.onNextStatusClick(statusId, () => {
          this.loading[statusId] = false;
        });

      },
      getLatestStatus() {
        if (this.statuses.length === 0) {
          return {
            id: 0,
            name: undefined
          };
        }

        logg(this, 'Getting latest status.');

        // Statuses are sorted from the backend.
        return {
          id: this.statuses[0].status.id,
          name: this.statuses[0].status.name
        };
      },
      getNextStatusButtonText(status) {
        const label = `label.invoice.title.set.status.${status.name.toLocaleLowerCase()}`;
        return getLabel(label);
      },
      statusUtils() {
        const latestStatus = this.getLatestStatus();

        return {
          isDeliveryUnsuccessful: () => latestStatus.name === STATUS.DELIVERY_UNSUCCESSFUL,
          isPickUpUnsuccessful: () => latestStatus.name === STATUS.PICKUP_UNSUCCESSFUL,
          isReturned: () => latestStatus.name === STATUS.PACKAGE_RETURNED,
          shouldFillStatusProgress2: () =>
              this.statuses.some(status => status.status.name === STATUS.COLLECTION_OF_PACKAGE),
          shouldFillStatusProgress3: () =>
              this.statuses.some(status => status.status.name === STATUS.TRANSIT),
          shouldFillStatusProgress4: () =>
              this.statuses.some(status => status.status.name === STATUS.ARRIVED_IN_OFFICE),
          shouldFillStatusProgress5: () => {
            return this.statuses.some(status =>
                status.status.name === STATUS.DELIVERY_SUCCESSFUL ||
                status.status.name === STATUS.DELIVERY_UNSUCCESSFUL ||
                status.status.name === STATUS.PICKUP_SUCCESSFUL ||
                status.status.name === STATUS.PICKUP_UNSUCCESSFUL ||
                status.status.name === STATUS.PACKAGE_RETURNED
            );
          }
        }
      }
    }
  }
</script>

<style scoped>
  body {
    color: #000;
    overflow-x: hidden;
    height: 100%;
    background-color: #8C9EFF;
    background-repeat: no-repeat
  }

  .card {
    z-index: 0;
    background-color: transparent;
    padding-bottom: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px
  }

  .top {
    padding-top: 40px;
    padding-left: 9% !important;
    padding-right: 9% !important
  }

  .progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: #455A64;
    padding-left: 0;
    margin-top: 30px
  }

  .progressbar li {
    list-style-type: none;
    font-size: 13px;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400
  }

  .progressbar-special li {
    width: 20%;
  }

  .progressbar .step0:before {
    font-family: FontAwesome;
    content: "\f10c";
    color: #fff
  }

  .progressbar li:before {
    width: 40px;
    height: 40px;
    line-height: 45px;
    display: block;
    font-size: 20px;
    background: #C5CAE9;
    border-radius: 50%;
    margin: auto;
    padding: 0;
  }

  .progressbar li:after {
    content: '';
    width: 100%;
    height: 12px;
    background: #C5CAE9;
    position: absolute;
    left: 0;
    top: 16px;
    z-index: -1
  }

  .progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    left: -50%
  }

  .progressbar li:nth-child(2):after,
  .progressbar li:nth-child(3):after,
  .progressbar .special-dp:after {
    left: -50%
  }

  .progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    left: 50%
  }

  .progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px
  }

  .progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px
  }

  .progressbar li.active:before,
  .progressbar li.active:after {
    background: #651FFF
  }

  .progressbar li.active:before {
    font-family: FontAwesome;
    content: "\f00c"
  }

  .icon {
    width: 50px;
    height: 50px;
    margin-right: 15px
  }

  .icon-content {
    padding-bottom: 20px
  }

  @media screen and (max-width: 992px) {
    .icon-content {
      width: 50%
    }
  }
</style>