export class Package {
  constructor(data) {
    if (data) {
      this.heightCm = data.heightCm;
      this.lengthCm = data.lengthCm;
      this.name = data.name;
      this.quantity = data.quantity;
      this.singleItemPrice = data.singleItemPrice;
      this.weightGrams = data.weightGrams;
      this.widthCm = data.widthCm;
    }
  }
}
