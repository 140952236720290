<template>
  <PageLayout>
    <div class="row justify-content-md-center">
      <div class="col-md-6">
        <div class="card-form-box">
          <h3>{{ getLabel('label.title.new_profile') }}</h3>
          <form autocomplete="off">
            <Input
                id="email"
                type="text"
                :label="getLabel('label.input.email_address')"
                v-model="email"
            />
            <Input
                id="username"
                type="text"
                :label="getLabel('label.input.username')"
                v-model="username"
            />
              <Input
                  id="password"
                  type="password"
                  :label="getLabel('label.input.password')"
                  v-model="password"
              />
              <Input
                  id="confirmPassword"
                  type="password"
                  :label="getLabel('label.input.confirm_password')"
                  v-model="confirmPassword"
              />
            <MainButton
                :loading="loading"
                :text="getLabel('label.button.signup')"
                :on-click="signup"
            />
          </form>
          <h6>{{ getLabel('label.login.text.yes.profile') }}</h6>
          <h6>
            <router-link id="toLogin" :to="pathToLoginNavigation()">{{ getLabel('label.login.text.login_now') }}
            </router-link>
            <span>!</span>
          </h6>
        </div>
      </div>
    </div>
  </PageLayout>
</template>

<script>
  import Response from '../../models/backend/Response';

  import axios from 'axios';
  import router, { ROUTES } from '../../router';

  import { useNotificationService } from '@/service/NotificationService';
  import { useFormFieldService } from '@/service/FormFieldService';
  import MainButton from '../partial/MainButton';
  import PageLayout from '@/shared/layout/PageLayout';
  import Input from "@/shared/input/Input";
  import {useMessageSource} from "@/config/MessageSource";

  const notificationService = useNotificationService()
  const formFieldService = useFormFieldService()
  const {getLabel} = useMessageSource()

  const stateMutations = {
    /** @param {boolean} data **/
    setLoading: function (data) {
      this.loading = data
    }
  }

  export default {
    name: 'Login',
    components: {Input, PageLayout, MainButton},
    data() {
      return {
        /** @type {boolean} data **/
        loading: false,
        /** @type {string} data **/
        email: '',
        /** @type {string} data **/
        username: '',
        /** @type {string} data **/
        password: '',
        /** @type {string} data **/
        confirmPassword: ''
      }
    },
    methods: {
      getLabel,
      signup() {
        this.setLoading(true)

        formFieldService.resetInputErrors();

        let body = {
          email: this.email,
          username: this.username,
          password: this.password,
          confirmPassword: this.confirmPassword
        }

        axios.post('signup', body).then(it => {
          const response = new Response(it);

          router.push({name: ROUTES.Home.nested.Login.name, params: {infoMessage: response.message}})
        }).catch(it => {
          const response = new Response(it.response);

          if (response.hasErrors()) {
            formFieldService.populateErrorFields(response.errors);
          }

          // Do not show error if the validation violation happens. Errors will show up
          // under every input field.
          if (!response.isEntityNotValidError()) {
            notificationService.showInfoModal(response.message)
          }
        }).finally(() => this.setLoading(false));
      },
      /** --------------------- Navigation resolver functions --------------------- **/
      pathToLoginNavigation() {
        return ROUTES.Home.nested.Login.path;
      },
      ...stateMutations
    }
  }
</script>