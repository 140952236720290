<template>
  <div class="input-box">
    <div v-if="typeof(value) === 'boolean'">
      <i v-if="value" class="fa fa-check-circle icon-true" />
      <i v-else class="fa fa-times-circle icon-false" />
      <input :type="type" disabled="disabled" class="disabled">
      <label>{{ label }}</label>
    </div>
    <div v-else>
      <input :type="type" :value="value" disabled="disabled" class="disabled">
      <label>{{ label }}</label>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DisabledInputField',
    props: {
      value: {
        required: true
      },
      label: {
        type: String,
        required: true
      },
      type: {
        type: String,
        required: false,
        default: () => 'text'
      }
    },
    data() {
      return {
        sideLeft: '10px'
      }
    }
  }
</script>

<style scoped>
  .input-box i {
    position: absolute;
  }

  .icon-true {
    padding-top: 25px;
    color: greenyellow;
  }

  .icon-false {
    padding-top: 25px;
    color: pink;
  }

  .disabled {
    color: rgba(163, 193, 194, 1) !important;
    background: rgba(163, 163, 194, 0.3) !important;
    margin-top: 10px !important;
    pointer-events: unset !important;
    cursor: not-allowed !important;
  }

  .icon-true, .icon-false, .disabled {
    padding-left: 15px !important;
  }
</style>