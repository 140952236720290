import { createRouter, createWebHistory } from 'vue-router';

import Welcome from '../screens/Welcome';
import Login from '../screens/login/Login';
import Signup from '../screens/login/Signup';
import SellerInvoicesPage from '../screens/invoices/sellerView/SellerInvoicesPage';
import InvoiceDetailsPage from '../screens/shared/invoice/InvoiceDetailsPage';
import SellerInvoiceNewPage from '../screens/invoices/sellerView/SellerInvoiceNewPage';
import Contact from '../screens/main/Contact';
import PersonalInfoRequest from '../screens/login/PersonalInfoRequest';
import Dashboard from '../screens/profile/Dashboard';
import About from '../screens/main/About';
import { usePermissionService } from '@/service/PermissionService';
import ManagerUnassignedInvoicesPage from '@/screens/invoices/managerView/ManagerUnassignedInvoicesPage';
import EmployeeAssignedInvoicesPage from '@/screens/invoices/employeeView/EmployeeAssignedInvoicesPage';
import ManagerAssignedInvoicesPage from '@/screens/invoices/managerView/ManagerAssignedInvoicesPage';
import EmployeeFinishedInvoicesPage from '@/screens/invoices/employeeView/EmployeeFinishedInvoicesPage';
import EmployeesPage from '@/screens/employee/EmployeesPage';
import EmployeeDetailsPage from '@/screens/employee/details/EmployeeDetailsPage';
import EmployeeBasicInformationEditPage
  from '@/screens/employee/details/basic-information/EmployeeBasicInformationEditPage';
import EmployeeCreatePage from '@/screens/employee/create/EmployeeCreatePage';
import SellerUnconnectedAccountsPage from '@/screens/seller/account/SellerUnconnectedAccountsPage';
import SellerLegalPersonConnectAccountPage from '@/screens/seller/account/SellerLegalPersonConnectAccountPage';
import CompaniesPage from '@/screens/company/CompaniesPage';
import CompanyCreatePage from '@/screens/company/create/CompanyCreatePage';
import CompanyBasicInformationPage from '@/screens/company/details/CompanyBasicInformationPage';
import CompanyBasicInformationEditPage from '@/screens/company/details/CompanyBasicInformationEditPage';
import MyProfileViewPage from '@/screens/shared/account/my-profile/MyProfileViewPage';
import MyProfileEditPage from '@/screens/shared/account/my-profile/edit/MyProfileEditPage';
import PaymentVerification from '@/screens/payment/PaymentVerification';
import {logg} from "@/config/Logging";

const permissionService = usePermissionService()

const handleRouteLimitedAccess = (callback, notAuthCondition) => {
  if (permissionService.isAuthenticatedUser()) {
    if (notAuthCondition) {
      callback('/');
    } else {
      return;
    }
  }

  callback('/');
};

/**
 * If not logged in, always transfer to homepage.
 *
 * @param callback  The callback function.
 */
const handleUnauthenticatedAccess = (callback) => {
  handleRouteLimitedAccess(callback, !permissionService.isAuthorizedUser());
};

/**
 * If not logged in as seller, always transfer to homepage.
 *
 * @param callback  The callback function.
 */
const handleRouteLimitedAccessSeller = (callback) => {
  handleRouteLimitedAccess(callback, !permissionService.isAuthorizedSeller());
};

/**
 * If not logged in as manager, always transfer to homepage.
 *
 * @param callback  The callback function.
 */
const handleRouteLimitedAccessManager = (callback) => {
  handleRouteLimitedAccess(callback, !permissionService.isAuthorizedManager());
};

/**
 * If not logged in as employee, always transfer to homepage.
 *
 * @param callback  The callback function.
 */
const handleRouteLimitedAccessEmployee = (callback) => {
  handleRouteLimitedAccess(callback, !permissionService.isAuthorizedEmployee());
};

/**
 * Create the final routes used in this app, combining the nested routes. Only call this
 * function once. Never call it twice. Even if for example it is called in an alert(),
 * things will get messy and routes will be messy.
 *
 * @returns {*[]}   The routes.
 */
const routeFactory = () => {
  const nestedRoutes = [];

  const routes = Object.keys(ROUTES).map((key) => {
    let result = ROUTES[key];

    if (ROUTES[key].nested !== undefined) {
      Object.keys(ROUTES[key].nested).map((nestedKey) => {
        const nestedRoute = ROUTES[key].nested[nestedKey];
        nestedRoute.path = (
          ROUTES[key].path + ROUTES[key].nested[nestedKey].path
        ).replaceAll('//', '/');

        nestedRoutes.push(nestedRoute);

        if (nestedRoute.nested) {

          Object.keys(nestedRoute.nested).map((nestedKeySecond) => {
            const nestedRouteSecond = nestedRoute.nested[nestedKeySecond];
            nestedRouteSecond.path = (
              nestedRoute.path + nestedRoute.nested[nestedKeySecond].path
            ).replaceAll('//', '/');

            nestedRoutes.push(nestedRouteSecond);
          })

        }
      });

    }

    return result;
  });

  nestedRoutes.map((r) => routes.push(r));

  return routes;
};

// TODO aboykov Improve this function!!! Update: Remove it with ABO-MC1022.
const resolvePathWithParams = (path, ...params) => {
  logg('index', `Resolving path: ${path}, with params: ${params}`)

  const varParamNumber = path.toString().match(/:/g || []).length;
  if (varParamNumber !== params.length) {
    return;
  }

  let pathToBeChanged = path.toString();

  params.map((param) => {
    const indexOfFirstTwoDots = pathToBeChanged.toString().indexOf(':');
    const lengthOfVarParam = pathToBeChanged
      .toString()
      .slice(indexOfFirstTwoDots)
      .indexOf('/');

    pathToBeChanged =
      pathToBeChanged.toString().slice(0, indexOfFirstTwoDots) +
      param.toString() +
      pathToBeChanged.toString().slice(indexOfFirstTwoDots + lengthOfVarParam);
  });

  const indexOfLastTwoDots = pathToBeChanged.toString().indexOf(':');
  if (indexOfLastTwoDots >= 0) {
    pathToBeChanged = pathToBeChanged
      .toString()
      .slice(0, indexOfLastTwoDots - 1);
  }
  return pathToBeChanged;
};

const resolveBaseRoute = () => {
  if (permissionService.isAuthorizedManager()) {
    return ROUTES.Manager;
  } else if (permissionService.isAuthorizedEmployee()) {
    return ROUTES.Employee;
  } else if (permissionService.isAuthorizedSeller()) {
    return ROUTES.Seller;
  }
}

const ROUTES = {
  // This is only a test route
  TestRoute: {
    name: 'test',
    path: '/test',
    component: 'justForTesting',
    nested: {
      NestedComponent: {
        name: 'Nested',
        path: '/:someId/nested',
        component: 'justForTesting'
      }
    }
  },
  Home: {
    path: '/',
    name: 'Home',
    component: Welcome,
    beforeEnter(to, from, next) {
      if (permissionService.isAuthenticatedUser()) {
        if (!permissionService.isAuthorizedUser()) {
          next('/seller/personalInfoRequest');
        } else {
          next('/dashboard');
        }
      }

      next();
    },
    nested: {
      Login: {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter(to, from, next) {
          if (permissionService.isAuthenticatedUser()) {
            next('/');
          }

          next();
        }
      },
      Signup: {
        path: '/signup',
        name: 'Signup',
        component: Signup,
        beforeEnter(to, from, next) {
          if (permissionService.isAuthenticatedUser()) {
            next('/');
          }

          next();
        }
      },
      Contact: {
        path: '/contact',
        name: 'Contact',
        component: Contact
      },
      About: {
        path: '/about',
        name: 'About',
        component: About
      },
      PaymentVerification: {
        path: '/paymentVerification',
        name: 'PaymentVerification',
        component: PaymentVerification,
        beforeEnter(to, from, next) {
          handleRouteLimitedAccessSeller(next);

          next();
        }
      }
    }
  },
  Dashboard: {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter(to, from, next) {
      handleUnauthenticatedAccess(next);

      next();
    },
    nested: {
      MyProfile: {
        path: '/myProfile',
        name: 'MyProfile',
        component: MyProfileViewPage,
        beforeEnter(to, from, next) {
          handleUnauthenticatedAccess(next)

          next();
        },
        nested: {
          MyProfileEdit: {
            path: '/edit',
            name: 'MyProfileEdit',
            component: MyProfileEditPage,
            beforeEnter(to, from, next) {
              handleUnauthenticatedAccess(next)

              next();
            }
          }
        }
      }
    }
  },
  Seller: {
    // TODO aboykov Currently not going anywhere.
    path: '/seller',
    name: 'Seller',
    beforeEnter(to, from, next) {
      handleRouteLimitedAccessSeller(next);

      next();
    },
    nested: {
      PersonalInfoRequest: {
        path: '/personalInfoRequest',
        name: 'SellerPersonalInfoRequest',
        component: PersonalInfoRequest,
        beforeEnter(to, from, next) {
          if (permissionService.isAuthenticatedUser()) {
            if (permissionService.isAuthorizedUser()) {
              next('/');
            }
          } else {
            next('/');
          }

          next();
        }
      },
      Invoices: {
        path: '/invoices',
        name: 'SellerInvoices',
        component: SellerInvoicesPage,
        beforeEnter(to, from, next) {
          handleRouteLimitedAccessSeller(next);

          next();
        },
        nested: {
          Invoice: {
            path: '/:uin',
            name: 'SellerInvoicesInvoice',
            component: InvoiceDetailsPage,
            beforeEnter(to, from, next) {
              handleRouteLimitedAccessSeller(next);

              next();
            }
          },
          InvoiceNew: {
            path: '/new',
            name: 'SellerInvoicesInvoiceNew',
            component: SellerInvoiceNewPage,
            beforeEnter(to, from, next) {
              handleRouteLimitedAccessSeller(next);

              next();
            }
          }
        }
      }
    }
  },
  Employee: {
    // TODO aboykov Currently not going anywhere.
    path: '/employee',
    name: 'Employee',
    beforeEnter(to, from, next) {
      handleRouteLimitedAccessEmployee(next);

      next();
    },
    nested: {
      Invoices: {
        path: '/invoices',
        name: 'EmployeeInvoices',
        component: EmployeeAssignedInvoicesPage,
        beforeEnter(to, from, next) {
          handleRouteLimitedAccessEmployee(next);

          next();
        },
        nested: {
          SpecialInvoices: {
            path: '/finished',
            name: 'EmployeeInvoicesFinishedInvoices',
            component: EmployeeFinishedInvoicesPage,
            beforeEnter(to, from, next) {
              handleRouteLimitedAccessEmployee(next);

              next();
            }
          },
          Invoice: {
            path: '/:uin',
            name: 'EmployeeInvoicesInvoice',
            component: InvoiceDetailsPage,
            beforeEnter(to, from, next) {
              handleRouteLimitedAccessEmployee(next);

              next();
            }
          }
        }
      }
    }
  },
  Manager: {
    // TODO aboykov Currently not going anywhere.
    path: '/manager',
    name: 'Manager',
    beforeEnter(to, from, next) {
      handleRouteLimitedAccessManager(next);

      next();
    },
    nested: {
      Invoices: {
        path: '/invoices',
        name: 'ManagerInvoices',
        component: ManagerUnassignedInvoicesPage,
        beforeEnter(to, from, next) {
          handleRouteLimitedAccessManager(next);

          next();
        },
        nested: {
          SpecialInvoices: {
            path: '/assigned',
            name: 'ManagerInvoicesAssignedInvoices',
            component: ManagerAssignedInvoicesPage,
            beforeEnter(to, from, next) {
              handleRouteLimitedAccessManager(next);

              next();
            }
          },
          Invoice: {
            path: '/:uin',
            name: 'ManagerInvoicesInvoice',
            component: InvoiceDetailsPage,
            beforeEnter(to, from, next) {
              handleRouteLimitedAccessManager(next);

              next();
            }
          }
        }
      },
      Accounts: {
        path: '/accounts',
        name: 'ManagerAccounts',
        component: SellerUnconnectedAccountsPage,
        beforeEnter(to, from, next) {
          handleRouteLimitedAccessManager(next);

          next();
        },
        nested: {
          ConnectAccount: {
            path: '/:username',
            name: 'ManagerAccountsConnectAccount',
            component: SellerLegalPersonConnectAccountPage,
            beforeEnter(to, from, next) {
              handleRouteLimitedAccessManager(next);

              next();
            }
          }
        }
      },
      Employees: {
        path: '/employees',
        name: 'ManagerEmployees',
        component: EmployeesPage,
        beforeEnter(to, from, next) {
          handleRouteLimitedAccessManager(next);

          next();
        },
        nested: {
          Employee: {
            path: '/:id',
            name: 'ManagerEmployeesEmployee',
            component: EmployeeDetailsPage,
            beforeEnter(to, from, next) {
              handleRouteLimitedAccessManager(next);

              next();
            }
          },
          EmployeeNew: {
            path: '/new',
            name: 'ManagerEmployeesEmployeeNew',
            component: EmployeeCreatePage,
            beforeEnter(to, from, next) {
              handleRouteLimitedAccessManager(next);

              next();
            }
          },
          EmployeeEdit: {
            path: '/:id/edit',
            name: 'ManagerEmployeesEmployeeEdit',
            component: EmployeeBasicInformationEditPage,
            beforeEnter(to, from, next) {
              handleRouteLimitedAccessManager(next);

              next();
            }
          }
        }
      },
      Companies: {
        path: '/companies',
        name: 'ManagerCompanies',
        component: CompaniesPage,
        beforeEnter(to, from, next) {
          handleRouteLimitedAccessManager(next);

          next();
        },
        nested: {
          Company: {
            path: '/:id',
            name: 'ManagerCompaniesCompany',
            component: CompanyBasicInformationPage,
            beforeEnter(to, from, next) {
              handleRouteLimitedAccessManager(next);

              next();
            }
          },
          CompanyNew: {
            path: '/new',
            name: 'ManagerCompaniesCompanyNew',
            component: CompanyCreatePage,
            beforeEnter(to, from, next) {
              handleRouteLimitedAccessManager(next);

              next();
            }
          },
          CompanyEdit: {
            path: '/:id/edit',
            name: 'ManagerCompaniesCompanyEdit',
            component: CompanyBasicInformationEditPage,
            beforeEnter(to, from, next) {
              handleRouteLimitedAccessManager(next);

              next();
            }
          }
        }
      }
    },
    // This should be last, do not add after it
    NonExistent: {
      name: 'NonExistent',
      path: '/:catchAll(.*)',
      redirect: '/'
    }
  }
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routeFactory()
});

// TODO aboykov Quickfix for PROD, bug [ABO-MC1101].
import { useFormFieldService } from '@/service/FormFieldService';
router.afterEach((to, from) => {
  useFormFieldService().resetInputErrors()
})

export default router;
export {
  ROUTES,
  resolvePathWithParams,
  resolveBaseRoute
};
