const isDevelopmentEnv = process.env.NODE_ENV === 'development';

/**
 * Logging config method for development only.
 *
 * @param p     The parent that logs the string.
 * @param v     The string that should be logged.
 */
export const logg = (p, v) => {
  if (isDevelopmentEnv) {
    if (typeof p === 'string') {
      console.log(`LOGG | -- ${p}.js -- ${v}`)
    } else {
      console.log(`LOGG | -- ${p.$options.name}.vue -- ${v}`)
    }
  }
}