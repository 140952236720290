<template>
  <PageLayout>
    <div class="row" style="margin: 30px 0;">
      <div class="col-md-8 col-sm-8">
        <h2>
          {{ getLabel('label.home.section_1.title') }}
        </h2>
        <br/>
        <p style="text-align: justify">
          {{ getLabel('label.home.section_1.content') }}
        </p>
        <br/><br/>
        <MainButton
            :text="getLabel('label.login.text.login_now')"
            :on-click="navigateToLogIn"
        />
      </div>
      <div id="welcome-stamp" class="offset-lg-1 col-md-3 col-sm-3 justify-content-center align-self-center">
        <img style="width: 60%" :src="require('@/assets/imgs/stamp.png')" alt="myCargo"/>
      </div>
    </div>
    <br/><br/><br/>
    <div class="row">
      <div class="col-lg-12">
        <div class="row row-eq-">

          <div class="col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="features-small-item">
              <div class="icon">
                <i><img :src="require('@/assets/imgs/welcome-b-1.png')" alt=""></i>
              </div>
              <h5 class="features-title">
                {{ getLabel('label.home.subsection.strategy.title') }}
              </h5>
              <p>
                {{ getLabel('label.home.subsection.strategy.content') }}
              </p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="features-small-item">
              <div class="icon">
                <i><img :src="require('@/assets/imgs/welcome-b-2.png')" alt=""></i>
              </div>
              <h5 class="features-title">
                {{ getLabel('label.home.subsection.247.title') }}
              </h5>
              <p>
                {{ getLabel('label.home.subsection.247.content') }}
              </p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="features-small-item">
              <div class="icon">
                <i><img :src="require('@/assets/imgs/welcome-b-3.png')" alt=""></i>
              </div>
              <h5 class="features-title">
                {{ getLabel('label.home.subsection.security.title') }}
              </h5>
              <p>
                {{ getLabel('label.home.subsection.security.content') }}
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
    <section class="mini">
      <div class="mini-content">
        <div class="">
          <div class="row">
            <div class="offset-lg-2 col-lg-8">
              <div class="info">
                <h1>
                  {{ getLabel('label.home.section_2.title') }}
                </h1>
                <p>
                  {{ getLabel('label.home.section_2.content') }}
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-2 col-md-3 col-sm-6 col-6">
              <a class="mini-box">
                <i class="fa fa-file-text-o"></i>
                <strong>{{ getLabel('label.home.subsection.invoices.title') }}</strong>
                <span>{{ getLabel('label.home.subsection.invoices.content') }}</span>
              </a>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6 col-6">
              <a class="mini-box">
                <i class="fa fa-credit-card"></i>
                <strong>{{ getLabel('label.home.subsection.e_payment.title') }}</strong>
                <span>{{ getLabel('label.home.subsection.e_payment.content') }}</span>
              </a>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6 col-6">
              <a class="mini-box">
                <i class="fa fa-truck"></i>
                <strong>{{ getLabel('label.home.subsection.invoice_statuses.title') }}</strong>
                <span>{{ getLabel('label.home.subsection.invoice_statuses.content') }}</span>
              </a>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6 col-6">
              <a class="mini-box">
                <i class="fa fa-search"></i>
                <strong>{{ getLabel('label.home.subsection.tracking.title') }}</strong>
                <span>{{ getLabel('label.home.subsection.tracking.content') }}</span>
              </a>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6 col-6">
              <a class="mini-box">
                <i class="fa fa-file"></i>
                <strong>{{ getLabel('label.home.subsection.documentation.title') }}</strong>
                <span>{{ getLabel('label.home.subsection.documentation.content') }}</span>
              </a>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6 col-6">
              <a class="mini-box">
                <i class="fa fa-get-pocket"></i>
                <strong>{{ getLabel('label.home.subsection.delivery.title') }}</strong>
                <span>{{ getLabel('label.home.subsection.delivery.content') }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </PageLayout>
</template>

<script>

import router, {ROUTES} from '@/router';
import PageLayout from '@/shared/layout/PageLayout';
  import { useRoute } from 'vue-router';
import {useNotificationService} from '@/service/NotificationService';
import MainButton from "@/screens/partial/MainButton";
import {logg} from "@/config/Logging";
import {useMessageSource} from "@/config/MessageSource";

const notificationService = useNotificationService()
const {getLabel} = useMessageSource()

  export default {
    name: 'Welcome',
    components: {MainButton, PageLayout},
    props: {
      msg: String
    },
    // The data and the variables that this component will hold
    data() {

    },
    // This is called after the component is mounted
    mounted() {
      logg(this, `Welcome, this is a log only on dev.`)

      const {infoMessage} = useRoute().params;

      notificationService.maybeShowInfoModal(infoMessage)
    },
    // Here are the methods assigned
    methods: {
      getLabel,
      /** --------------------- Navigation resolver functions --------------------- **/
      pathToLoginNavigation() {
        return ROUTES.Home.nested.Login;
      },
      navigateToLogIn() {
        router.push(ROUTES.Home.nested.Login.path);
      }
    },
    // Here are the computed methods assigned
    computed: {}
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }
</style>
