<template>
  <Dropdown
      :id="id"
      class="localDropdown"
      :options="data"
      :optionLabel="optionLabel"
      :optionValue="optionValue"
      scrollHeight="300px"
      placeholder="-- Одберете --"
      :modelValue="modelValue"
      @change="onChange"
  />
  <label class="localLabel">{{ label }}</label>
  <span class="invalid-feedback"></span>
</template>

<script>
import Dropdown from "@/assets/vendor/primevue/dropdown";

export default {
  name: "SelectInput",
  components: {Dropdown},
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: Number,
      required: false,
      default: () => undefined
    },
    data: {
      type: Array,
      required: true
    },
    optionLabel: {
      type: String,
      required: false,
      default: () => 'name'
    },
    optionValue: {
      type: String,
      required: false,
      default: () => 'id'
    },
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    return {
      onChange: (e) => {
        emit('update:modelValue', e.value)
      }
    }
  }
}
</script>

<style scoped>
.localDropdown {
  font-family: inherit;
  margin-right: 10px;
  height: 35px;
  width: 100%;
  background: transparent;
  border-color: var(--main-color);
  box-shadow: none;
  margin-top: 15px !important;
}

.localDropdown:hover, .localDropdown:focus-within {
  border-color: var(--main-color);
  box-shadow: none;
}

.localLabel {
  top: -25px !important;
  left: 0 !important;
  color: var(--main-color) !important;
  font-size: 12px !important;
  padding: 15px 0 !important;
}
</style>