<template>
  <PageLayout>
    <Title text="Листа на компании" />

    <LoadingSpinner v-if="loading.main" />

    <CompanyGrid
        :rows="factoryCompanyRows"
        :columns="columns"
        :empty-grid-message="emptyGridMessage"
        :on-company-details="onCompanyDetails"
        :on-company-create="onCompanyCreate"
    />
  </PageLayout>

</template>

<script>
  import Title from '@/screens/partial/Title';
  import LoadingSpinner from '@/screens/partial/LoadingSpinner';
  import axios from 'axios';
  import Response from '@/models/backend/Response';
  import { useNotificationService } from '@/service/NotificationService';
  import { useEntityParser } from '@/utils/EntityParser';
  import { Company } from '@/models/entity/Company';
  import CompanyGrid from '@/screens/shared/company/CompanyGrid';
  import { City } from '@/models/entity/City';
  import { useRoute } from 'vue-router';
  import router, { ROUTES } from '@/router';
  import PageLayout from '@/shared/layout/PageLayout';

  const notificationService = useNotificationService()
  const entityParser = useEntityParser()

  const stateMutations = {
    /** @param {Array<Company>} data **/
    setCompanies: function (data) {
      this.companies = data
    },
    /** @param {Boolean} data **/
    setLoadingMain: function (data) {
      this.loading.main = data
    }
  }

  const columns = [
    {
      field: 'id',
      header: 'id',
      hidden: true
    },
    {
      field: 'name',
      header: 'Компанија',
      sortable: true,
      defaultSortable: true
    },
    {
      field: 'edb',
      header: 'ЕДБ',
      sortable: true
    },
    {
      field: 'city',
      header: 'Град',
      sortable: true
    },
    {
      field: 'active',
      header: 'Активна',
      sortable: true
    }
  ]

  export default {
    name: 'CompaniesPage',
    components: {PageLayout, CompanyGrid, LoadingSpinner, Title},
    data() {
      return {
        loading: {
          /** @type {Boolean} **/
          main: true
        },
        /** @type {Array<Company>} **/
        companies: undefined,
        columns: columns,
        emptyGridMessage: 'Листата на компании е празна.'
      }
    },
    mounted() {
      const {infoMessage} = useRoute().params;
      notificationService.maybeShowInfoModal(infoMessage)

      this.getData();
    },
    methods: {
      getData() {
        axios.get('company').then(it => {
          const response = new Response(it);

          const companies = entityParser.toCompanies(response.data);

          this.setCompanies(companies);
          this.setLoadingMain(false);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally()
      },
      onCompanyDetails(id) {
        router.push({
          name: ROUTES.Manager.nested.Companies.nested.Company.name,
          params: {
            id: id
          }
        })
      },
      onCompanyCreate() {
        router.push({name: ROUTES.Manager.nested.Companies.nested.CompanyNew.name})
      },
      ...stateMutations
    },
    computed: {
      factoryCompanyRows() {
        if (!this.companies) {
          return;
        }

        const rows = new Array(this.companies.length);

        this.companies.forEach((company, index) => {
          rows[index] = {};

          this.columns.forEach((column) => {
            if (column.field === 'city') {
              const city = new City(company[column.field]);

              rows[index]['city'] = city.name;
            } else {
              rows[index][column.field] = company[column.field];
            }
          });
        })

        return rows;
      }
    }
  }
</script>

<style scoped>

</style>