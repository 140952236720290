<template>
  <Title :text="title" />

  <LoadingSpinner v-if="loading.main" />

  <InvoicesGrid
      :rows="factoryInvoiceRows"
      :columns="columns"
      :empty-grid-message="emptyGridMessage"
      :path-to-invoice-navigation="pathToInvoiceNavigation"
  />
</template>

<script>
  import axios from 'axios';
  import Response from '@/models/backend/Response';
  import { Invoice } from '@/models/entity/Invoice';
  import { useNotificationService } from '@/service/NotificationService';
  import { useEntityParser } from '@/utils/EntityParser';
  import { useDateTimeUtils } from '@/utils/DateTimeUtils';
  import { useRoute } from 'vue-router';
  import InvoicesGrid from '@/screens/shared/invoice/InvoicesGrid';
  import { resolveBaseRoute, resolvePathWithParams, ROUTES } from '@/router';
  import { usePermissionService } from '@/service/PermissionService';
  import LoadingSpinner from '@/screens/partial/LoadingSpinner';
  import { useStatusService } from '@/service/backend/StatusService';
  import Title from '@/screens/partial/Title';

  const notificationService = useNotificationService()
  const dateTimeUtils = useDateTimeUtils()
  const entityParser = useEntityParser()
  const permissionService = usePermissionService()
  const statusService = useStatusService()

  const stateMutations = {
    /** @param {Array<Invoice>} data **/
    setInvoices: function (data) {
      this.invoices = data
    },
    /** @param {boolean} data **/
    setLoadingMain: function (data) {
      this.loading.main = data
    }
  }

  export default {
    name: 'InternalViewInvoices',
    components: {Title, LoadingSpinner, InvoicesGrid},
    props: {
      title: {
        required: true,
        type: String
      },
      special: {
        required: false,
        type: Boolean
      },
      emptyGridMessage: {
        required: true,
        type: String
      },
      columns: {
        required: true,
        type: Array(Object)
      }
    },
    data() {
      return {
        loading: {
          /** @type {Boolean} **/
          main: true
        },
        /** @type {Array<Invoice>} **/
        invoices: undefined
      }
    },
    mounted() {
      const {infoMessage} = useRoute().params;
      notificationService.maybeShowInfoModal(infoMessage)

      this.getData();
    },
    methods: {
      getData() {
        const specialParam = this.special ? '?special=true' : '';

        axios.get(`invoices${specialParam}`).then(it => {
          const response = new Response(it);

          const invoices = entityParser.toInvoices(response.data);

          this.setInvoices(invoices);
          this.setLoadingMain(false);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally()
      },
      /** --------------------- Navigation resolver functions --------------------- **/
      /** @param {string} uin **/
      pathToInvoiceNavigation(uin) {
        return resolvePathWithParams(resolveBaseRoute().nested.Invoices.nested.Invoice.path, uin);
      },
      ...stateMutations
    },
    computed: {
      factoryInvoiceRows() {
        if (!this.invoices) {
          return;
        }

        const rows = new Array(this.invoices.length);

        this.invoices.forEach((invoice, index) => {
          rows[index] = {};

          this.columns.forEach((column) => {
            if (column.field === 'packagesLength') {
              rows[index]['packagesLength'] = invoice.packages.length;
            } else if (column.field === 'buyerCity') {
              rows[index]['buyerCity'] = invoice.buyerCity.name;
            } else if (column.field === 'finished') {
              rows[index]['finished'] = statusService.isStatusDone(invoice.statuses[0]);
            } else {
              rows[index][column.field] = invoice[column.field];
            }

            if (column.sortableField) {
              rows[index][column.field] = dateTimeUtils.toLocalDateTime(invoice[column.field]);
              rows[index][column.sortableField] = invoice[column.field]
            }
          });
        })

        return rows;
      }
    }
  }
</script>

<style>

</style>