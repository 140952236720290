import $ from 'jquery';
import {useMessageSource} from "@/config/MessageSource";

const {getLabel} = useMessageSource()

/**
 * Temporary information modal for a feature that is not yet implemented.
 */
const notImplNotify = () => {
  showInfoModal(getLabel('label.notification.not_implemented'));
}

/**
 * Show information modal.
 *
 * @param message   The message to be shown.
 */
const showInfoModal = (message) => {
  if (!message) {
    internal.unknownError();
  }

  if (message === 'SERVER_DOWN_GLOBAL') {
    internal.unavailableConnection();
    return;
  }

  $('#myCargoInfoModal #mainMessage').text(message);
  $('#myCargoInfoModal').modal('show');
}

/**
 * Show information modal only if the message is persistent (based on some action that the user did).
 *
 * @param message   The message to be shown.
 */
const maybeShowInfoModal = (message) => {
  if (message) {
    showInfoModal(message)
  }
}

const internal = {
  /**
   * Show information modal if the server is down.
   */
  unavailableConnection: () => {
    showInfoModal(getLabel('label.notification.connection_error'))
  },
  /**
   * Show information modal if the servers return an undefined error (check backend code).
   */
  unknownError: () => {
    showInfoModal(getLabel('label.notification.unknown_error'))
  }
}

export const useNotificationService = () => {
  return {
    notImplNotify,
    showInfoModal,
    maybeShowInfoModal
  };
}