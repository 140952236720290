<template>
  <PageLayout>
    <LoadingSpinner v-if="loading.main" />

    <div v-else class="header-text">
      <div class="row">
        <div class="col-sm-12">
          <div class="card-form-box">
            <form autocomplete="off">
              <Input
                  id="name"
                  type="text"
                  label="Име"
                  v-model="person.name"
              />
              <Input
                  id="surname"
                  type="text"
                  label="Презиме"
                  v-model="person.surname"
              />
              <MaskedInput
                  id="embg"
                  label="ЕМБГ"
                  v-model="person.embg"
                  mask="#############"
              />
              <Input
                  id="address"
                  type="text"
                  label="Адреса"
                  v-model="person.address"
              />
              <div class="user-box" style="text-align: left;">
                <div class="row row-eq-height align-items-center d-flex align-items-stretch">
                  <div class="col-sm-6 p-0">
                    <SelectInput
                        id="idCity"
                        label="Град"
                        :data="cities"
                        v-model="person.idCity"
                    />
                  </div>
                  <div class="col-sm-6" style="padding-top: 1.2rem!important;">
                    <span>Р. Северна Македонија</span>
                  </div>
                </div>
              </div>
              <MaskedInput
                  id="phoneNumber"
                  label="Телефонски број"
                  v-model="person.phoneNumber"
                  mask="+389 7# ### ###"
              />
              <MainButton
                  :loading="loading.save"
                  text="Зачувај"
                  :on-click="saveEmployee"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  </PageLayout>
</template>

<script>
import {Employee} from '@/models/entity/Employee';
import {City} from '@/models/entity/City';
import axios from 'axios';
import Response from '@/models/backend/Response';
import {useNotificationService} from '@/service/NotificationService';
import {useFormFieldService} from '@/service/FormFieldService';
import {useEntityParser} from '@/utils/EntityParser';
import LoadingSpinner from '@/screens/partial/LoadingSpinner';
import MainButton from '@/screens/partial/MainButton';
import router, {resolveBaseRoute, resolvePathWithParams, ROUTES} from '@/router';
import PageLayout from '@/shared/layout/PageLayout';
import Input from "@/shared/input/Input";
import MaskedInput from "@/shared/input/MaskedInput";
import SelectInput from "@/shared/input/SelectInput";

const notificationService = useNotificationService()
const formFieldService = useFormFieldService()
const entityParser = useEntityParser()

const stateMutations = {
  /** @param {boolean} data **/
  setLoadingMain: function (data) {
    this.loading.main = data
  },
    /** @param {boolean} data **/
    setLoadingSave: function (data) {
      this.loading.save = data
    },
    /** @param {Array<City>} data **/
    setCities: function (data) {
      this.cities = data;
    }
  }

  export default {
    name: 'EmployeeNew',
    components: {SelectInput, MaskedInput, Input, PageLayout, MainButton, LoadingSpinner},
    data() {
      return {
        loading: {
          /** @type {boolean} data **/
          main: true,
          /** @type {boolean} data **/
          save: false
        },
        /** @type {Employee} **/
        person: {
          name: undefined,
          surname: undefined,
          address: undefined,
          embg: undefined,
          phoneNumber: undefined,
          idCity: undefined
        },
        /** @type {Array<City>} **/
        cities: undefined
      }
    },
    mounted() {
      this.fetchCities();
    },
    methods: {
      fetchCities() {
        axios.get('city').then(it => {
          const response = new Response(it);

          const cities = entityParser.toCities(response.data);

          this.setCities(cities);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally(() => this.setLoadingMain(false))
      },
      saveEmployee() {
        this.setLoadingSave(true);

        formFieldService.resetInputErrors();

        axios.post('employees', this.person).then(it => {
          const response = new Response(it);

          const employee = new Employee(response.data);

          router.push({
            name: resolveBaseRoute().nested.Employees.nested.Employee.name,
            params: {
              id: employee.id,
              infoMessage: response.message
            }
          });

        }).catch(it => {
          const response = new Response(it.response);

          if (response.hasErrors()) {
            formFieldService.populateErrorFields(response.errors);
          }

          // Do not show error if the validation violation happens. Errors will show up
          // under every input field.
          if (!response.isEntityNotValidError()) {
            notificationService.showInfoModal(response.message)
          }
        }).finally(() => this.setLoadingSave(false));
      },
      ...stateMutations
    }
  }
</script>

<style scoped>

</style>