export const i18n_messages = {
  "mk": {
    "label.test": "Тест: var1 = {0} и var2 = {1}.",
    "label.mycargo": "моеКарго",
    "label.home.section_1.title": "Добредојдовте на најновата платформа на пазарот - „моеКарго“",
    "label.home.section_1.content": "Нашата веб апликација овозможува брз и лесен транспорт на пратките кои имаат потреба за достава. Едноставно и лесно од удобноста на вашиот дом можете да обезбедите транспорт до било која дестинација во државата без да се грижите за ништо. Нашиот тим од професионалци ќе се погрижи производите да пристигнат на својата дестинација најбрзо и најсигурно. Нека започне процесот на достава на производите на кои им треба транспорт уште веднаш!",
    "label.home.section_2.title": "Карго услуги",
    "label.home.section_2.content": "Ние нудиме флексибилни карго услуги кои одговараат на секого според потребите. Без разлика дали сакате пратката да ја доставиме на точно посакуваната адреса или да ја подигнеме пратката од точно посакуваната адреса, ние сме тука за тоа.",
    "label.home.subsection.strategy.title": "Висококвалитетна стратегија",
    "label.home.subsection.strategy.content": "Нашата стратегија за транспорт на пратките е висококвалитетна, не грижете се!",
    "label.home.subsection.247.title": "Достапни 24/7",
    "label.home.subsection.247.content": "Контактирајте нѐ во било кое време за да добиете било каква информација!",
    "label.home.subsection.security.title": "Безбедност и сигурност",
    "label.home.subsection.security.content": "Ние се грижиме производите да бидат доставени 100% безбедно!",
    "label.home.subsection.invoices.title": "Фактури",
    "label.home.subsection.invoices.content": "Генерирање на фактури.",
    "label.home.subsection.e_payment.title": "е-Плаќање",
    "label.home.subsection.e_payment.content": "Систем за електронско плаќање.",
    "label.home.subsection.invoice_statuses.title": "Статуси",
    "label.home.subsection.invoice_statuses.content": "Промена на статус на пратка.",
    "label.home.subsection.tracking.title": "Следење",
    "label.home.subsection.tracking.content": "До каде е доставата на пратката.",
    "label.home.subsection.documentation.title": "Документација",
    "label.home.subsection.documentation.content": "Подготвување на целосна документација.",
    "label.home.subsection.delivery.title": "Достава",
    "label.home.subsection.delivery.content": "Успешна достава на пратката.",
    "label.contact.emergency_text": "За итно барање, контактирајте нѐ на +49 152 1333 93 23 (+WhatsApp)!",
    "label.navigation.home": "Почетна",
    "label.navigation.login": "Најава",
    "label.navigation.logout": "Одјава",
    "label.navigation.about": "За моеКарго",
    "label.navigation.contact": "Контакт",
    "label.navigation.invoices": "Фактури",
    "label.navigation.assigned_invoices": "Доделени Фактури",
    "label.navigation.not_assigned_invoices": "Недоделени Фактури",
    "label.navigation.user_accounts": "Кориснички сметки",
    "label.navigation.unconnected_user_accounts": "Неповрзани кориснички сметки",
    "label.navigation.employees": "Вработени",
    "label.navigation.companies": "Компании",
    "label.navigation.my_invoices": "Мои фактури",
    "label.navigation.finished_invoices": "Завршени фактури",
    "label.title.login": "Најава",
    "label.title.new_profile": "Нов профил",
    "label.title.information": "Информација",
    "label.title.confirmation": "Потврда",
    "label.title.payment_total": "Вкупно за наплата: {0} {1}",
    "label.title.contact": "Контакт",
    "label.title.my_profile": "Мој профил",
    "label.title.my_invoices": "Мои фактури",
    "label.title.personal_data": "Лични податоци",
    "label.title.user_profile": "Кориснички профил",
    "label.title.new_invoice": "Нова фактура",
    "label.title.natural_person": "Физичко лице",
    "label.title.legal_person": "Правно лице",
    "label.title.from": "Од",
    "label.title.to": "До",
    "label.title.me": "Јас",
    "label.input.name": "Име",
    "label.input.surname": "Презиме",
    "label.input.address": "Адреса",
    "label.input.city": "Град",
    "label.input.phone_number": "Телефонски број",
    "label.input.email_address": "Емаил адреса",
    "label.input.username": "Корисничко име",
    "label.input.password": "Лозинка",
    "label.input.confirm_password": "Повторно лозинка",
    "label.input.name_surname": "Име и презиме",
    "label.input.message": "Порака",
    "label.input.embg": "ЕМБГ",
    "label.input.user_role": "Корисничка улога",
    "label.input.user_since": "Корисник од",
    "label.input.last_login": "Последна најава",
    "label.input.active_profile": "Активен профил",
    "label.input.assigned_company": "Компанија која ја застапувате",
    "label.button.login": "Најава",
    "label.button.signup": "Регистрација",
    "label.button.ok": "Во ред",
    "label.button.yes": "Да",
    "label.button.no": "Не",
    "label.button.send": "Испрати",
    "label.button.edit_data": "Промени податоци",
    "label.button.delete": "Избриши",
    "label.button.save": "Зачувај",
    "label.button.access_invoice": "Види фактура",
    "label.button.confirm": "Потврди",
    "label.button.sign": "Потпиши",
    "label.button.sign_again": "Повторно потпиши",
    "label.button.create_invoice": "Креирај фактура",
    "label.button.pay_cargo_fee": "Плати карго трошок",
    "label.button.receive_payment_for_cargo_fee": "Наплати средства за карго трошокот",
    "label.button.receive_payment_for_products": "Наплати средства за производите",
    "label.invoice.title.invoice_number": "Бр. на фактура",
    "label.invoice.title.created_at": "Креирана",
    "label.invoice.title.ordered_by": "Нарачал",
    "label.invoice.title.total_packages": "Вкупно пратки",
    "label.invoice.title.empty_invoice_list": "Листата на ваши фактури е празна.",
    "label.invoice.title.invoice_list_count": "Вкупно {0} записи.",
    "label.invoice.title.send_as": "Испраќа",
    "label.invoice.title.buy_as": "Прима",
    "label.invoice.title.choose_total_packages": "Одберете колку пакети ќе има нарачката",
    "label.invoice.title.invoice_information": "Информации во врска со пратката",
    "label.invoice.title.who_pays_cargo_fee": "Карго услугата ја плаќа",
    "label.invoice.title.seller_pays_cargo_fee": "Испраќачот",
    "label.invoice.title.buyer_pays_cargo_fee": "Примачот",
    "label.invoice.title.paid_for_products": "Наплата за пратката е остварена",
    "label.invoice.title.paid_for_cargo_fee": "Наплата за карго услугата е остварена",
    "label.invoice.title.pickup_from_where": "Пратката се зема од",
    "label.invoice.title.deliver_to_where": "Пратката се носи до",
    "label.invoice.title.delivery_mycargo": "„моеКарго“ канцеларија",
    "label.invoice.title.delivery_home": "Дома",
    "label.invoice.title.seller_signature": "Потпис на праќачот",
    "label.invoice.title.buyer_signature": "Потпис на купувачот",
    "label.invoice.title.product": "Производ",
    "label.invoice.title.height": "Висина",
    "label.invoice.title.width": "Ширина",
    "label.invoice.title.length": "Должина",
    "label.invoice.title.mass": "Маса",
    "label.invoice.title.quantity": "Количина",
    "label.invoice.title.single_price": "Единечна цена",
    "label.invoice.title.price": "Цена",
    "label.invoice.title.total": "Вкупно",
    "label.invoice.title.delete_invoice": "Избриши фактура",
    "label.invoice.title.send_as_company": "во име на",
    "label.invoice.title.cargo_fee_with_tax_included": "Карго со вклучено 18% ДДВ",
    "label.invoice.title.discount": "Попуст",
    "label.invoice.title.paid_services": "Следните услуги се наплатени",
    "label.invoice.title.status": "Статус на пратката",
    "label.invoice.title.status.processed": "Процесирана нарачка",
    "label.invoice.title.status.collected": "Нарачка превземена",
    "label.invoice.title.status.transit": "Во транзит",
    "label.invoice.title.status.delivered": "Испорачана нарачка",
    "label.invoice.title.status.in_office": "Нарачка во канцеларија",
    "label.invoice.title.status.returned": "Вратена пратка",
    "label.invoice.title.status.pickup_unsuccessful": "Неподигната нарачка",
    "label.invoice.title.status.pickup_successful": "Подигната нарачка",
    "label.invoice.title.status.unsuccessful_delivery": "Неуспешна испорака",
    "label.invoice.title.set.status.collection_of_package": "Преземи нарачка",
    "label.invoice.title.set.status.transit": "Постави во транзит",
    "label.invoice.title.set.status.delivery_successful": "Потврди доставена пратка",
    "label.invoice.title.set.status.delivery_unsuccessful": "Недоставена пратка",
    "label.invoice.title.set.status.arrived_in_office": "Пристигната во канцеларија",
    "label.invoice.title.set.status.pickup_successful": "Потврди подигната пратка",
    "label.invoice.title.set.status.pickup_unsuccessful": "Неподигната пратка",
    "label.invoice.title.set.status.package_returned": "Потврди вратена пратка",
    "label.role.admin": "Админ",
    "label.role.manager": "Менаџер",
    "label.role.employee": "Доставувач",
    "label.role.seller": "Продавач",
    "label.placeholder.your_message": "Вашата порака",
    "label.placeholder.enter_invoice_information": "Внесете дополнителни информации за нарачката",
    "label.placeholder.product_title": "Назив на производот",
    "label.login.text.no.profile": "Немате профил?",
    "label.login.text.yes.profile": "Имате профил?",
    "label.login.text.create_profile_now": "Креирајте ваш профил сега!",
    "label.login.text.login_now": "Најавете се сега",
    "label.notification.connection_error": "Проблеми со конекција до серверот. Обидете се подоцна!",
    "label.notification.unknown_error": "Настана некоја грешка. Обидете се повторно!",
    "label.notification.not_implemented": "Не е имплементирано!",
    "label.notification.inactive_user_profile": "Вашиот кориснички профил во моментот е неактивен. Контактирајте нѐ за повеќе информации и повторно активирање на профилот.",
    "label.notification.prices_are_in_currency": "Цените во оваа фактура се изразени во {0}.",
    "label.notification.check_invoice_details": "Ве молиме проверете ги сите информации во оваа фактура. Доколку има некоја грешка, ве молиме избришете ја фактурата, а потоа креирајте нова. Фактурата можете да ја избришете само во наредните 10 минути од креирањето на истата.",
    "label.confirmation.receive_cargo_payment": "Дали навистина сакате да извршите наплата на трошокот за карго услугата?",
    "label.confirmation.pay_cargo_fee": "Дали навистина сакате го платите трошокот за карго услугата?",
    "label.confirmation.receive_package_payment": "Дали навистина сакате да извршите наплата на производите во фактурата?",
    "label.confirmation.change_invoice_status": "Дали сакате да го промените статусот на фактурата?",
    "label.confirmation.delete_invoice": "Дали навистина сакате да ја избришете фактурата?",
    "label.currency.mkd": "МКД",
    "label.currency.eur": "ЕУР",
    "label.currency.usd": "УСД",
    "label.unit.gram": "гр.",
    "label.unit.centimeter": "цм.",
    "label.country.north_macedonia": "Република Северна Македонија",
    "label.city.skopje": "Скопје"
  },
  "en": {
    "label.test": "Test",
    "label.mycargo": "myCargo",
    "label.home.section_1.title": "Welcome to the latest platform on the market - 'myCargo'",
    "label.home.section_1.content": "Our web application provides quick and easy transport of shipments that need delivery. Simply and easily from the comfort of your home you can provide transport to any destination in the country without worrying about anything. Our team of professionals will make sure that the products arrive at their destination as quickly and safely as possible. Let the process of delivery of products that need transport begin immediately!",
    "label.home.section_2.title": "Cargo services",
    "label.home.section_2.content": "We offer flexible cargo services that suit everyone according their needs. Whether you want to deliver the shipment to the exactly desired address or pick up the shipment from the exactly desired address, we are here for it.",
    "label.home.subsection.strategy.title": "High-quality strategy",
    "label.home.subsection.strategy.content": "Our strategy for transporting shipments is of high quality, don't worry!",
    "label.home.subsection.247.title": "Available 24/7",
    "label.home.subsection.247.content": "Contact us at any time to get any information!",
    "label.home.subsection.security.title": "Safety and security",
    "label.home.subsection.security.content": "We make sure that the products are delivered 100% safely!",
    "label.home.subsection.invoices.title": "Invoices",
    "label.home.subsection.invoices.content": "Generate invoices.",
    "label.home.subsection.e_payment.title": "e-Payment",
    "label.home.subsection.e_payment.content": "Electronic payment system.",
    "label.home.subsection.invoice_statuses.title": "Statuses",
    "label.home.subsection.invoice_statuses.content": "Change the status of the shipment.",
    "label.home.subsection.tracking.title": "Tracking",
    "label.home.subsection.tracking.content": "What is the progress of the shipment delivery?",
    "label.home.subsection.documentation.title": "Documentation",
    "label.home.subsection.documentation.content": "Preparation of complete documentation.",
    "label.home.subsection.delivery.title": "Delivery",
    "label.home.subsection.delivery.content": "Successful delivery of the shipment.",
    "label.contact.emergency_text": "If urgent, please contact us on +49 152 1333 93 23 (+WhatsApp)!",
    "label.navigation.home": "Home",
    "label.navigation.login": "Login",
    "label.navigation.logout": "Logout",
    "label.navigation.about": "About myCargo",
    "label.navigation.contact": "Contact",
    "label.navigation.invoices": "Invoices",
    "label.navigation.assigned_invoices": "Assigned invoices",
    "label.navigation.not_assigned_invoices": "Not assigned invoices",
    "label.navigation.user_accounts": "User Accounts",
    "label.navigation.unconnected_user_accounts": "Unconnected user accounts",
    "label.navigation.employees": "Employees",
    "label.navigation.companies": "Companies",
    "label.navigation.my_invoices": "My invoices",
    "label.navigation.finished_invoices": "Finished invoices",
    "label.title.login": "Login",
    "label.title.new_profile": "New profile",
    "label.title.information": "Information",
    "label.title.confirmation": "Confirmation",
    "label.title.payment_total": "Payment total: {1} {0}",
    "label.title.contact": "Contact",
    "label.title.my_profile": "My profile",
    "label.title.my_invoices": "My invoices",
    "label.title.personal_data": "Personal data",
    "label.title.user_profile": "User profile",
    "label.title.new_invoice": "New invoice",
    "label.title.natural_person": "Natural person",
    "label.title.legal_person": "Legal person",
    "label.title.from": "From",
    "label.title.to": "To",
    "label.title.me": "Me",
    "label.input.name": "Name",
    "label.input.surname": "Surname",
    "label.input.address": "Address",
    "label.input.city": "City",
    "label.input.phone_number": "Phone number",
    "label.input.email_address": "Email address",
    "label.input.username": "Username",
    "label.input.password": "Password",
    "label.input.confirm_password": "Confirm password",
    "label.input.name_surname": "Name and surname",
    "label.input.message": "Message",
    "label.input.embg": "Personal number (EMBG)",
    "label.input.user_role": "User role",
    "label.input.user_since": "User since",
    "label.input.last_login": "Last login",
    "label.input.active_profile": "Active profile",
    "label.input.assigned_company": "Assigned company",
    "label.button.login": "Login",
    "label.button.signup": "Sign up",
    "label.button.ok": "OK",
    "label.button.yes": "Yes",
    "label.button.no": "No",
    "label.button.send": "Send",
    "label.button.edit_data": "Edit data",
    "label.button.delete": "Delete",
    "label.button.save": "Save",
    "label.button.access_invoice": "View invoice",
    "label.button.confirm": "Confirm",
    "label.button.sign": "Sign",
    "label.button.sign_again": "Sign again",
    "label.button.create_invoice": "Create invoice",
    "label.button.pay_cargo_fee": "Pay the cargo fee",
    "label.button.receive_payment_for_cargo_fee": "Receive payment for cargo fee",
    "label.button.receive_payment_for_products": "Receive payment for products",
    "label.invoice.title.invoice_number": "Invoice No.",
    "label.invoice.title.created_at": "Created at",
    "label.invoice.title.ordered_by": "Ordered by",
    "label.invoice.title.total_packages": "Total packages",
    "label.invoice.title.empty_invoice_list": "Your invoice list is empty.",
    "label.invoice.title.invoice_list_count": "A total of {0} records.",
    "label.invoice.title.send_as": "Send as",
    "label.invoice.title.buy_as": "Deliver to",
    "label.invoice.title.choose_total_packages": "Number of packages that will contain this shipment",
    "label.invoice.title.invoice_information": "Information about the delivery",
    "label.invoice.title.who_pays_cargo_fee": "The cargo fee is paid by",
    "label.invoice.title.seller_pays_cargo_fee": "The seller",
    "label.invoice.title.buyer_pays_cargo_fee": "The buyer",
    "label.invoice.title.paid_for_products": "The payment for products is already done",
    "label.invoice.title.paid_for_cargo_fee": "The payment for the cargo fee is already done",
    "label.invoice.title.pickup_from_where": "Pickup from",
    "label.invoice.title.deliver_to_where": "Deliver to",
    "label.invoice.title.delivery_mycargo": "'myCargo' office",
    "label.invoice.title.delivery_home": "Home",
    "label.invoice.title.seller_signature": "Seller's signature",
    "label.invoice.title.buyer_signature": "Buyer's signature",
    "label.invoice.title.product": "Product",
    "label.invoice.title.height": "Height",
    "label.invoice.title.width": "Width",
    "label.invoice.title.length": "Length",
    "label.invoice.title.mass": "Mass",
    "label.invoice.title.quantity": "Quantity",
    "label.invoice.title.single_price": "Single price",
    "label.invoice.title.price": "Price",
    "label.invoice.title.total": "Total",
    "label.invoice.title.delete_invoice": "Delete Invoice",
    "label.invoice.title.send_as_company": "as",
    "label.invoice.title.cargo_fee_with_tax_included": "Cargo fee with 18% tax included",
    "label.invoice.title.discount": "Discount",
    "label.invoice.title.paid_services": "The following services are paid",
    "label.invoice.title.status": "Shipment status",
    "label.invoice.title.status.processed": "Processed",
    "label.invoice.title.status.collected": "Collected",
    "label.invoice.title.status.transit": "In transit",
    "label.invoice.title.status.delivered": "Delivered",
    "label.invoice.title.status.in_office": "In office",
    "label.invoice.title.status.returned": "Returned shipment",
    "label.invoice.title.status.pickup_unsuccessful": "Order not picked up",
    "label.invoice.title.status.pickup_successful": "Order picked up",
    "label.invoice.title.status.unsuccessful_delivery": "Unsuccessful delivery",
    "label.invoice.title.set.status.collection_of_package": "Collect package",
    "label.invoice.title.set.status.transit": "Set to transit",
    "label.invoice.title.set.status.delivery_successful": "Confirm delivered shipment",
    "label.invoice.title.set.status.delivery_unsuccessful": "Undelivered shipment",
    "label.invoice.title.set.status.arrived_in_office": "Arrived at the office",
    "label.invoice.title.set.status.pickup_successful": "Confirm picked up shipment",
    "label.invoice.title.set.status.pickup_unsuccessful": "Shipment not picked up ",
    "label.invoice.title.set.status.package_returned": "Confirm returned shipment",
    "label.role.admin": "Admin",
    "label.role.manager": "Manager",
    "label.role.employee": "Delivery guy",
    "label.role.seller": "Seller",
    "label.placeholder.your_message": "Your message",
    "label.placeholder.enter_invoice_information": "Enter additional shipment information",
    "label.placeholder.product_title": "Product title",
    "label.login.text.no.profile": "You do not have an account?",
    "label.login.text.yes.profile": "You have an account?",
    "label.login.text.create_profile_now": "Create your account now!",
    "label.login.text.login_now": "Login now",
    "label.notification.connection_error": "Network connection issues. Try again later!",
    "label.notification.unknown_error": "An error occurred. Try again!",
    "label.notification.not_implemented": "Not implemented yet!",
    "label.notification.inactive_user_profile": "Your user profile is inactive at the moment. Please contact us for further information and activating your profile again.",
    "label.notification.prices_are_in_currency": "The currency used in this invoice is {0}.",
    "label.notification.check_invoice_details": "Please check all the information in this invoice. If there is an incorrect data, please delete the invoice, and then create a new one. You can only delete the invoice within the next 10 minutes.",
    "label.confirmation.receive_cargo_payment": "Do you really want to register the payment for the cargo service fee?",
    "label.confirmation.pay_cargo_fee": "Do you really want to pay for the cargo service fee?",
    "label.confirmation.receive_package_payment": "Do you really want to register the payment for the package products?",
    "label.confirmation.change_invoice_status": "Do you really want to change the invoice status?",
    "label.confirmation.delete_invoice": "Do you really want to delete the invoice?",
    "label.currency.mkd": "MKD",
    "label.currency.eur": "EUR",
    "label.currency.usd": "USD",
    "label.unit.gram": "g.",
    "label.unit.centimeter": "cm.",
    "label.country.north_macedonia": "Republic of North Macedonia",
    "label.city.skopje": "Skopje"
  }
}