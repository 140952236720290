import { City } from '@/models/entity/City';

export class Employee {
  constructor(data) {
    if (data) {
      this.email = data.email
      this.username = data.username
      this.embg = data.embg
      this.id = data.id
      this.name = data.name
      this.phoneNumber = data.phoneNumber
      this.surname = data.surname
      this.address = data.address
      /** @type {City} **/
      this.city = data.city
      this.hasConnectedAccount = data.hasConnectedAccount
      this.accountActive = data.accountActive
      this.canBeDeleted = data.canBeDeleted
    }
  }
}
