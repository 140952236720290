<template>
  <PageLayout>
    <Title :text="getLabel('label.title.my_invoices')"/>

    <LoadingSpinner v-if="loading.main" />

    <InvoicesGrid
        :rows="factoryInvoiceRows"
        :columns="columns"
        :empty-grid-message="emptyGridMessage"
        :path-to-invoice-navigation="pathToInvoiceNavigation"
        :path-to-invoice-new="pathToInvoiceNew()"
    />
  </PageLayout>
</template>

<script>
  import axios from 'axios';
  import {useNotificationService} from '@/service/NotificationService';
  import {ROUTES, resolvePathWithParams} from '@/router';
  import Response from '../../../models/backend/Response';
  import { useEntityParser } from '@/utils/EntityParser';
  import { useRoute } from 'vue-router';
  import { useDateTimeUtils } from '@/utils/DateTimeUtils';
  import { Invoice } from '@/models/entity/Invoice';
  import LoadingSpinner from '@/screens/partial/LoadingSpinner';
  import Title from '@/screens/partial/Title';
  import InvoicesGrid from '@/screens/shared/invoice/InvoicesGrid';
  import PageLayout from '@/shared/layout/PageLayout';
  import {useMessageSource} from "@/config/MessageSource";

  const notificationService = useNotificationService()
  const entityParser = useEntityParser()
  const dateTimeUtils = useDateTimeUtils()
  const {getLabel} = useMessageSource()

  const stateMutations = {
    /** @param {Array<Invoice>} data **/
    setInvoices: function (data) {
      this.invoices = data
    },
    /** @param {boolean} data **/
    setLoadingMain: function (data) {
      this.loading.main = data
    }
  }

  export default {
    name: 'SellerInvoicesPage',
    components: {PageLayout, InvoicesGrid, Title, LoadingSpinner},
    data() {
      return {
        loading: {
          /** @type {Boolean} **/
          main: true
        },
        /** @type {Array<Invoice>} **/
        invoices: undefined,
        /** Notype **/
        columns: [
          {
            field: 'uin',
            header: getLabel('label.invoice.title.invoice_number')
          },
          {
            field: 'creation',
            sortableField: 'creationSortable',
            header: getLabel('label.invoice.title.created_at'),
            sortable: true,
            defaultSortable: true
          },
          {
            field: 'buyerNameSurname',
            header: getLabel('label.invoice.title.ordered_by'),
            sortable: true
          },
          {
            field: 'packagesLength',
            header: getLabel('label.invoice.title.total_packages'),
            sortable: true
          }
        ],
        emptyGridMessage: getLabel('label.invoice.title.empty_invoice_list')
      }
    },
    mounted() {
      const {infoMessage} = useRoute().params;

      notificationService.maybeShowInfoModal(infoMessage)

      this.getData();
    },
    methods: {
      getLabel,
      getData() {
        axios.get('invoices').then(it => {
          const response = new Response(it);

          const invoices = entityParser.toInvoices(response.data);

          this.setInvoices(invoices);
          this.setLoadingMain(false);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally()
      },
      /** --------------------- Navigation resolver functions --------------------- **/
      /** @param {string} uin **/
      pathToInvoiceNavigation(uin) {
        return resolvePathWithParams(ROUTES.Seller.nested.Invoices.nested.Invoice.path, uin);
      },
      pathToInvoiceNew() {
        return ROUTES.Seller.nested.Invoices.nested.InvoiceNew.path
      },
      ...stateMutations
    },
    computed: {
      factoryInvoiceRows() {
        if (!this.invoices) {
          return;
        }

        const rows = new Array(this.invoices.length);

        this.invoices.forEach((invoice, index) => {
          rows[index] = {};

          this.columns.forEach((column) => {
            if (column.field === 'packagesLength') {
              rows[index]['packagesLength'] = invoice.packages.length;
            } else {
              rows[index][column.field] = invoice[column.field];
            }

            if (column.sortableField) {
              rows[index][column.field] = dateTimeUtils.toLocalDateTime(invoice[column.field]);
              rows[index][column.sortableField] = invoice[column.field]
            }
          });
        })

        return rows;
      }
    }
  }
</script>

<style scoped>
  .container-table100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 33px 30px
  }

  .wrap-table100 {
    width: 1170px
  }

  .table100 {
    background-color: #fff
  }

  table {
    width: 100%
  }

  .column1 {
    width: 33%;
    padding-left: 40px
  }

  .column2 {
    width: 13%
  }

  .column3 {
    width: 22%
  }

  .column4 {
    width: 19%
  }

  .column5 {
    width: 13%
  }

  .table100-head th {
    padding-top: 18px;
    padding-bottom: 18px
  }

  .table100-body td {
    padding-top: 16px;
    padding-bottom: 16px
  }

  .table100 {
    position: relative;
    padding-top: 60px
  }

  .table100-head {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0
  }

  .table100-body {
    max-height: 585px;
    overflow: auto
  }

  .table100.ver1 th {
    font-size: 18px;
    color: #fff;
    line-height: 1.4;
    background-color: #6c7ae0
  }

  .table100.ver1 td {
    font-size: 15px;
    color: gray;
    line-height: 1.4
  }

  .table100.ver1 .table100-body tr:nth-child(even) {
    background-color: #f8f6ff
  }

  .table100.ver1 {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -o-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -ms-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15)
  }

  .table100.ver2 .table100-head {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
    -o-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
    -ms-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1)
  }

  .table100.ver2 th {
    font-family: Lato-Bold;
    font-size: 18px;
    color: #fa4251;
    line-height: 1.4;
    background-color: transparent
  }

  .table100.ver2 td {
    font-family: Lato-Regular;
    font-size: 15px;
    color: gray;
    line-height: 1.4
  }

  .table100.ver2 .table100-body tr {
    border-bottom: 1px solid #f2f2f2
  }

  .table100.ver2 {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -o-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -ms-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15)
  }

  .table100.ver3 th {
    font-family: Lato-Bold;
    font-size: 15px;
    color: #00ad5f;
    line-height: 1.4;
    text-transform: uppercase;
    background-color: #393939
  }

  .table100.ver3 td {
    font-family: Lato-Regular;
    font-size: 15px;
    color: gray;
    line-height: 1.4;
    background-color: #222
  }

  .table100.ver4 .table100-head {
    padding-right: 20px
  }

  .table100.ver4 th {
    font-family: Lato-Bold;
    font-size: 18px;
    color: #4272d7;
    line-height: 1.4;
    background-color: transparent;
    border-bottom: 2px solid #f2f2f2
  }

  .table100.ver4 .column1 {
    padding-left: 7px
  }

  .table100.ver4 td {
    font-family: Lato-Regular;
    font-size: 15px;
    color: gray;
    line-height: 1.4
  }

  .table100.ver4 .table100-body tr {
    border-bottom: 1px solid #f2f2f2
  }

  .table100.ver5 .table100-head {
    padding-right: 30px
  }

  .table100.ver5 th {
    font-family: Lato-Bold;
    font-size: 14px;
    color: #555;
    line-height: 1.4;
    text-transform: uppercase;
    background-color: transparent
  }

  .table100.ver5 td {
    font-family: Lato-Regular;
    font-size: 15px;
    color: gray;
    line-height: 1.4;
    background-color: #f7f7f7
  }

  .table100.ver5 .table100-body tr {
    overflow: hidden;
    border-bottom: 10px solid #fff;
    border-radius: 10px
  }

  .table100.ver5 .table100-body table {
    border-collapse: separate;
    border-spacing: 0 10px
  }

  .table100.ver5 .table100-body td {
    border: solid 1px transparent;
    border-style: solid none;
    padding-top: 10px;
    padding-bottom: 10px
  }

  .table100.ver5 .table100-body td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px
  }

  .table100.ver5 .table100-body td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px
  }

  .table100.ver5 tr:hover td {
    background-color: #ebebeb;
    cursor: pointer
  }

  .table100.ver5 .table100-head th {
    padding-top: 25px;
    padding-bottom: 25px
  }

  .table100.ver5 .table100-body {
    padding-right: 30px
  }

  .table100.ver5 .ps__rail-y .ps__thumb-y::before {
    background-color: #ccc
  }
</style>