<template>
  <div class="eight">
    <h1>{{ text }}</h1>
  </div>
</template>

<script>
  export default {
    name: 'Title',
    props: {
      text: {
        required: true,
        type: String
      }
    }
  }
</script>

<style scoped>
  .eight h1 {
    text-align: center;

    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 1px;

    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 14px 0;
    grid-gap: 22px;
  }

  .eight h1:after, .eight h1:before {
    content: " ";
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.85);
    background-color: transparent;
  }

  h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-weight: 100;
    font-size: 40px;
    color: rgba(255, 255, 255, 0.85);
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }

  h1 span {
    display: block;
    font-size: 0.5em;
    line-height: 1.3;
  }

  h1 em {
    font-style: normal;
    font-weight: 600;
  }

  *,
  *:before,
  *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .eight {
    position: relative;
    width: 90%;
    max-width: 700px;
    padding: 2em;
    margin: 0 auto 1.5em;
  }

</style>