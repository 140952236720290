export class Company {
  constructor(data) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.edb = data.edb;
      this.address = data.address;
      this.city = data.city;
      this.phoneNumber = data.phoneNumber;
      this.active = data.active;
      this.canBeDeleted = data.canBeDeleted
    }
  }
}
