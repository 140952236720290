<template>
  <a class="main-button"
     :class="[
         loading?'disabled':'',
         smallBtn?'smallBtnStyle':'',
         lightLayer?'lightLayerStyle':''
     ]"
     v-on:click="onClick"
  >
    <span :style="loading?'visibility: hidden;':''">
      <slot name="icon" />
      {{ text }}
    </span>
    <div v-if="loading"
         style="position: absolute; top: 50%; left: 50%; transform:scale(0.5); transform-origin: top left;"
    >
      <LoadingSpinner style=""/>
    </div>
  </a>
</template>

<script>
import LoadingSpinner from '@/screens/partial/LoadingSpinner';

export default {
    name: 'MainButton',
    components: {LoadingSpinner},
    props: {
      text: {
        required: true,
        type: String
      },
      onClick: {
        required: false,
        type: Function,
        default: () => undefined
      },
      loading: {
        required: false,
        type: Boolean,
        default: () => false
      },
      smallBtn: {
        required: false,
        type: Boolean
      },
      lightLayer: {
        required: false,
        type: Boolean
      }
    }
  }
</script>

<style scoped>
  @import "../../assets/css/theme.css";

  /* This is used only in this file. */
  .smallBtnStyle {
    padding: 6px 10px;
  }

  .lightLayerStyle {
    color: var(--main-color) !important;
  }

  .lightLayerStyle:hover {
    color: white !important;
  }
</style>