import { useResponseCodes } from '@/models/backend/ResponseCodes';

class Response {
  constructor(backendResponse) {
    if (backendResponse) {
      this.statusCode = backendResponse.data.httpCodes.statusCode;
      this.substatusCode = backendResponse.data.httpCodes.substatusCode;
      this.message = backendResponse.data.message;
      this.data = backendResponse.data.data;
      this.errors = backendResponse.data.errors;
    } else {
      // Server unavailable
      this.message = 'SERVER_DOWN_GLOBAL';
    }
  }

  isEntityNotValidError() {
    const responseCodes = useResponseCodes()
    return this.substatusCode === responseCodes.ENTITY_NOT_VALID;
  }

  hasErrors() {
    return this.errors && true;
  }
}

export default Response;
