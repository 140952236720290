import $ from 'jquery'

$(document).ready(function () {
  'use strict';

  // Window Resize Mobile Menu Fix
  mobileNav();

  // Scroll animation init
  // TODO	aboykov	This effect should be added in the future.
  //window.sr = new scrollReveal();

  $(document).ready(function () {
    $(document).on('scroll', onScroll);

    //smoothscroll
    $('a[href^="#"]').on('click', function (e) {
      e.preventDefault();
      $(document).off('scroll');

      $('a').each(function () {
        $(this).removeClass('active');
      })
      $(this).addClass('active');

      // TODO	aboykov	Commented because throws some errors in the console.
      // var target = this.hash,
      // 	menu = target;
      // var target = $(this.hash);
      // $('html, body').stop().animate({
      // 	scrollTop: (target.offset().top) - 130
      // }, 500, 'swing', function () {
      // 	window.location.hash = target;
      // 	$(document).on("scroll", onScroll);
      // });
    });
  });

  function onScroll(event) {
    // TODO	aboykov	Commented because throws some errors in the console.
    // var scrollPos = $(document).scrollTop();
    // $('.nav a').each(function () {
    // 	var currLink = $(this);
    // 	var refElement = $(currLink.attr("href"));
    // 	if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
    // 		$('.nav ul li a').removeClass("active");
    // 		currLink.addClass("active");
    // 	} else {
    // 		currLink.removeClass("active");
    // 	}
    // });
  }


  // Home seperator
  if ($('.home-seperator').length) {
    $('.home-seperator .left-item, .home-seperator .right-item').imgfix();
  }


  // Home number counterup
  if ($('.count-item').length) {
    $('.count-item strong').counterUp({
      delay: 10,
      time: 1000
    });
  }


  // Page loading animation
  $(window).on('load', function () {
    if ($('.cover').length) {
      $('.cover').parallax({
        imageSrc: $('.cover').data('image'),
        zIndex: '1'
      });
    }

    $('#preloader').animate({
      'opacity': '0'
    }, 600, function () {
      setTimeout(function () {
        $('#preloader').css('visibility', 'hidden').fadeOut();
      }, 300);
    });
  });


  // Window Resize Mobile Menu Fix
  $(window).on('resize', function () {
    mobileNav();
  });


  // Window Resize Mobile Menu Fix
  function mobileNav() {
    var width = $(window).width();
    $('.submenu').on('click', function () {
      if (width < 992) {
        $('.submenu ul').removeClass('active');
        $(this).find('ul').toggleClass('active');
      }
    });
  }
});