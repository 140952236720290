import {useLocalStore} from "@/utils/store";

const localStore = useLocalStore();

const isAuthenticatedUser = () => {
  return localStore.hasAuthenticatedUser();
};

const isAuthorizedUser = () => {
  return isAuthorizedAdmin() ||
    isAuthorizedEmployee() ||
    isAuthorizedManager() ||
    isAuthorizedSeller();
}

const isAuthorizedSeller = () => {
  return localStore.isAuthorizedSeller();
};

const isAuthorizedEmployee = () => {
  return localStore.isAuthorizedEmployee();
};

const isAuthorizedManager = () => {
  return localStore.isAuthorizedManager();
}

const isAuthorizedAdmin = () => {
  return localStore.isAuthorizedAdmin();
};

export const usePermissionService = () => {
  return {
    isAuthenticatedUser,
    isAuthorizedUser,
    isAuthorizedSeller,
    isAuthorizedEmployee,
    isAuthorizedManager
  };
};
