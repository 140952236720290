<template>
  <TitledPage
      :title="getLabel('label.title.my_profile')"
      :on-back="onBack"
      :on-save="saveProfile"
      :loading-save="loading.save"
  >
    <LoadingSpinner v-if="loading.main" />

    <div v-else class="card-body form-input-box">
      <div>
        <Title :text="getLabel('label.title.personal_data')"/>

        <DisabledInputField
            :label="getLabel('label.input.name')"
            :value="myProfileModel.name"
        />
        <DisabledInputField
            :label="getLabel('label.input.surname')"
            :value="myProfileModel.surname"
        />
        <DisabledInputField
            v-if="myProfileModel.embg"
            :label="getLabel('label.input.embg')"
            :value="myProfileModel.embg"
        />
        <Input
            id="address"
            type="text"
            :label="getLabel('label.input.address')"
            v-model="myProfileModel.address"
        />
        <div class="input-box" style="text-align: left;">
          <div class="row row-eq-height align-items-center d-flex align-items-stretch">
            <div class="col-sm-6 p-0">
              <SelectInput
                  id="idCity"
                  :label="getLabel('label.input.city')"
                  :data="cities"
                  v-model="myProfileModel.city.id"
              />
            </div>
            <div class="col-sm-6" style="padding-top: 1.2rem!important;">
              <span>{{ getLabel('label.country.north_macedonia') }}</span>
            </div>
          </div>
        </div>
          <MaskedInput
              id="phoneNumber"
              :label="getLabel('label.input.phone_number')"
              v-model="myProfileModel.phoneNumber"
              mask="+389 7# ### ###"
          />

        <DisabledInputField
            v-if="myProfileModel.company"
            label="Компанија која ја застапувате"
            :value="myProfileModel.company"
        />
      </div>
    </div>
  </TitledPage>
</template>

<script>
  import TitledPage from '@/shared/layout/TitledPage';
  import router, { ROUTES } from '@/router';
  import { MyProfile } from '@/models/entity/MyProfile';
  import { City } from '@/models/entity/City';
  import LoadingSpinner from '@/screens/partial/LoadingSpinner';
  import axios from 'axios';
  import Response from '@/models/backend/Response';
  import {useNotificationService} from '@/service/NotificationService';
  import {useDateTimeUtils} from '@/utils/DateTimeUtils';
  import {useRoute} from 'vue-router';
  import Title from '@/screens/partial/Title';
  import DisabledInputField from '@/screens/partial/DisabledInputField';
  import {useFormFieldService} from '@/service/FormFieldService';
  import {useEntityParser} from '@/utils/EntityParser';
  import MaskedInput from "@/shared/input/MaskedInput";
  import Input from "@/shared/input/Input";
  import SelectInput from "@/shared/input/SelectInput";
  import {useMessageSource} from "@/config/MessageSource";

  const notificationService = useNotificationService()
  const dateTimeUtils = useDateTimeUtils()
  const entityParser = useEntityParser()
  const formFieldService = useFormFieldService()
  const {getLabel} = useMessageSource()

  const stateMutations = {
    /** @param {MyProfile} data **/
    setMyProfileModel: function (data) {
      this.myProfileModel = data
    },
    /** @param {Array<City>} data **/
    setCities: function (data) {
      this.cities = data
    },
    /** @param {boolean} value **/
    setLoadingMain: function (value) {
      this.loading.main = value
    },
    /** @param {boolean} value **/
    setLoadingSave: function (value) {
      this.loading.save = value
    }
  }

  export default {
    name: 'MyProfileEditPage',
    components: {SelectInput, Input, MaskedInput, DisabledInputField, Title, LoadingSpinner, TitledPage},
    data() {
      return {
        /** @type {MyProfile} **/
        myProfileModel: undefined,
        /** @type {Array<City>} **/
        cities: [],
        loading: {
          /** @type {Boolean} **/
          main: true,
          /** @type {Boolean} **/
          save: false
        }
      }
    },
    mounted() {
      const {infoMessage} = useRoute().params;
      notificationService.maybeShowInfoModal(infoMessage)

      this.getData()
      this.fetchCities()
    },
    methods: {
      getLabel,
      getData() {
        axios.get(`myProfile`).then(it => {
          const response = new Response(it);

          const myProfile = new MyProfile(response.data);

          myProfile.created = dateTimeUtils.toLocalDate(myProfile.created)
          myProfile.lastLogin = dateTimeUtils.toLocalDateTime(myProfile.lastLogin)

          this.setMyProfileModel(myProfile);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally()
      },
      saveProfile() {
        this.setLoadingSave(true);

        formFieldService.resetInputErrors();

        const mutationObject = {
          address: this.myProfileModel.address,
          idCity: this.myProfileModel.city.id,
          phoneNumber: this.myProfileModel.phoneNumber
        }

        axios.put(`myProfile`, mutationObject).then(it => {
          const response = new Response(it);

          router.push({
            name: ROUTES.Dashboard.nested.MyProfile.name,
            params: {
              infoMessage: response.message
            }
          });
        }).catch(it => {
          const response = new Response(it.response);

          if (response.hasErrors()) {
            formFieldService.populateErrorFields(response.errors);
          }

          // Do not show error if the validation violation happens. Errors will show up
          // under every input field.
          if (!response.isEntityNotValidError()) {
            notificationService.showInfoModal(response.message)
          }
        }).finally(() => this.setLoadingSave(false));
      },
      fetchCities() {
        axios.get('city').then(it => {
          const response = new Response(it);

          const cities = entityParser.toCities(response.data);

          this.setCities(cities);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally()
      },

      onBack() {
        router.push({
          name: ROUTES.Dashboard.nested.MyProfile.name
        })
      },
      ...stateMutations
    },
    watch: {
      cities() {
        if (this.myProfileModel && this.cities.length) {
          this.setLoadingMain(false);
        }
      },
      myProfileModel() {
        if (this.myProfileModel && this.cities.length) {
          this.setLoadingMain(false);
        }
      }
    }
  }
</script>

<style scoped>

</style>