import router, {ROUTES} from '@/router';
import {useLocalStorageUtils} from '@/utils/LocalStorageUtils';
import {usePermissionService} from '@/service/PermissionService';
import {useLocalStore} from "@/utils/store";
import {useMessageSource} from "@/config/MessageSource";

const localStorageUtils = useLocalStorageUtils();
const permissionService = usePermissionService();
const localStore = useLocalStore()
const {getLabel} = useMessageSource()

const changeLanguage = (lang) => {
  localStorageUtils.setLanguage(lang);
  location.reload();
}

const logout = async () => {
  await localStore.localLogOut();
  await router.push(ROUTES.Home.path)
}

function resolveMenuItems() {
  const logoutMenuItem = {
    label: getLabel('label.navigation.logout'),
    icon: 'pi pi-fw pi-power-off',
    command: logout
  }
  const languageOptionsMenuItem = {
    label: localStorageUtils.getLanguage().toUpperCase(),
    icon: 'pi pi-fw pi-globe',
    items: [
      {
        label: '🇲🇰 МК',
        command: () => changeLanguage('mk')
      },
      {
        label: '🇬🇧 EN',
        command: () => changeLanguage('en')
      }
    ]
  };
  const notAuthenticatedMenuItems = [
    {
      label: getLabel('label.navigation.home'),
      icon: 'pi pi-fw pi-home',
      to: ROUTES.Home.path
    },
    {
      label: getLabel('label.navigation.login'),
      icon: 'pi pi-fw pi-user',
      to: ROUTES.Home.nested.Login.path
    },
    {
      label: getLabel('label.navigation.about'),
      icon: 'pi pi-fw pi-info-circle',
      to: ROUTES.Home.nested.About.path
    },
    {
      label: getLabel('label.navigation.contact'),
      icon: 'pi pi-fw pi-book',
      to: ROUTES.Home.nested.Contact.path
    }
  ]
  const authorizedManagerMenuItems = [
    {
      label: getLabel('label.navigation.invoices'),
      icon: 'pi pi-fw pi-copy',
      items: [
        {
          label: getLabel('label.navigation.assigned_invoices'),
          icon: 'pi pi-fw pi-file',
          to: ROUTES.Manager.nested.Invoices.nested.SpecialInvoices.path
        },
        {
          label: getLabel('label.navigation.not_assigned_invoices'),
          icon: 'pi pi-fw pi-file-excel',
          to: ROUTES.Manager.nested.Invoices.path
        }
      ]
    },
    {
      label: getLabel('label.navigation.user_accounts'),
      icon: 'pi pi-fw pi-flag',
      items: [
        {
          label: getLabel('label.navigation.unconnected_user_accounts'),
          icon: 'pi pi-fw pi-flag',
          to: ROUTES.Manager.nested.Accounts.path
        }
      ]
    },
    {
      label: getLabel('label.navigation.employees'),
      icon: 'pi pi-fw pi-users',
      to: ROUTES.Manager.nested.Employees.path
    },
    {
      label: getLabel('label.navigation.companies'),
      icon: 'pi pi-fw pi-building',
      to: ROUTES.Manager.nested.Companies.path
    }

  ]
  const authorizedEmployeeMenuItems = [
    {
      label: getLabel('label.navigation.my_invoices'),
      icon: 'pi pi-fw pi-copy',
      to: ROUTES.Employee.nested.Invoices.path
    },
    {
      label: getLabel('label.navigation.finished_invoices'),
      icon: 'pi pi-fw pi-copy',
      to: ROUTES.Employee.nested.Invoices.nested.SpecialInvoices.path
    }
  ]
  const authorizedSellerMenuItems = [
    {
      label: getLabel('label.navigation.my_invoices'),
      icon: 'pi pi-fw pi-copy',
      to: ROUTES.Seller.nested.Invoices.path
    }
  ]

  let menuItems = [];

  if (!permissionService.isAuthenticatedUser()) {
    menuItems = notAuthenticatedMenuItems;
  } else if (permissionService.isAuthorizedManager()) {
    menuItems = authorizedManagerMenuItems;
  } else if (permissionService.isAuthorizedEmployee()) {
    menuItems = authorizedEmployeeMenuItems;
  } else if (permissionService.isAuthorizedSeller()) {
    menuItems = authorizedSellerMenuItems;
  }

  if (permissionService.isAuthenticatedUser()) {
    menuItems.push(logoutMenuItem)
  }

  menuItems.push(languageOptionsMenuItem)

  return menuItems;
}

export const useNavigationMenuResolver = () => {
  return {
    resolveMenuItems
  }
}