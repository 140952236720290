<template>
  <PageLayout>
    <div class="row">
      <div class="col-sm-12">
        <div class="card-form-box">
          <h3>{{ getLabel('label.title.contact') }}</h3>
          <form autocomplete="off">
            <Input
                id="nameSurname"
                type="text"
                :label="getLabel('label.input.name_surname')"
                v-model="nameSurname"
            />
            <Input
                id="email"
                type="text"
                :label="getLabel('label.input.email_address')"
                v-model="email"
            />
            <TextAreaInput
                id="message"
                :label="getLabel('label.input.message')"
                :placeholder="getLabel('label.placeholder.your_message')"
                v-model="message"
            />
            <MainButton
                :loading="loadingSendContactForm"
                :text="getLabel('label.button.send')"
                :on-click="sendContactMessage"
            />
          </form>
        </div>
        <br/>
        * {{ getLabel('label.contact.emergency_text') }}
      </div>
    </div>
  </PageLayout>
</template>

<script>
import {useNotificationService} from '@/service/NotificationService';
import MainButton from '../partial/MainButton';
import PageLayout from '@/shared/layout/PageLayout';
import Input from "@/shared/input/Input";
import TextAreaInput from "@/shared/input/TextAreaInput";
import {useFormFieldService} from "@/service/FormFieldService";
import axios from "axios";
import Response from "@/models/backend/Response";
import {useMessageSource} from "@/config/MessageSource";

const notificationService = useNotificationService()
const formFieldService = useFormFieldService()
const {getLabel} = useMessageSource()

const stateMutations = {
  /** @param {boolean} value **/
  setLoadingSendContactForm(value) {
    this.loadingSendContactForm = value
  },
}

export default {
  name: 'Contact',
  components: {TextAreaInput, Input, PageLayout, MainButton},
  data() {
    return {
      loadingSendContactForm: false,
      nameSurname: undefined,
      email: undefined,
      message: undefined
    }
  },
  methods: {
    getLabel,
    async sendContactMessage() {
      this.setLoadingSendContactForm(true);
      formFieldService.resetInputErrors();

      const mutationObject = {
        nameSurname: this.nameSurname,
        email: this.email,
        message: this.message
      }

      axios.post('contact', mutationObject).then(it => {
        const response = new Response(it);

        this.message = undefined;
        this.email = undefined;
        this.nameSurname = undefined;

        notificationService.showInfoModal(response.message)
      }).catch(it => {
        const response = new Response(it.response);

        if (response.hasErrors()) {
          formFieldService.populateErrorFields(response.errors);
        }

        // Do not show error if the validation violation happens. Errors will show up
        // under every input field.
        if (!response.isEntityNotValidError()) {
          notificationService.showInfoModal(response.message)
        }
      }).finally(() => this.setLoadingSendContactForm(false))
    },
    ...stateMutations
  }
}
</script>

<style scoped>

</style>