<template>
  <LoadingSpinner v-if="loading.main" />

  <PageLayout v-else class="bootstrap snippets">
    <div class="row">
      <div class="col-sm-12">
        <div class="panel panel-default invoice" id="invoice">
          <div class="panel-body">

            <div class="row align-items-center">

              <div class="col-sm-4 top">
                <p>
                  {{ getLabel('label.mycargo') }}
                  <br />
                  {{ getLabel('label.city.skopje') }}, {{ getLabel('label.country.north_macedonia') }}
                </p>
              </div>
              <div class="col-sm-4">
                <img style="width: 150px; transform: rotate(-10deg);" :src="require('@/assets/imgs/stamp.png')"
                     alt="x"
                />
              </div>
              <div class="col-sm-4">
                <p>{{ getLabel('label.title.new_invoice') }}</p>
              </div>

            </div>

            <div class="row align-items-center">
              <div class="col-sm-12">
                <p style="font-size: 0.8em; font-weight: 1000; margin-top: 10px;">
                  {{ `* ${getLabel('label.notification.prices_are_in_currency', getLabel('label.currency.mkd'))}` }}
                </p>
              </div>
            </div>

            <div v-if="invoice.packages.length > 0">
              <br />
              <MainButton
                  :loading="loading.add"
                  :text="getLabel('label.button.create_invoice')"
                  :on-click="generateInvoice"
              />
            </div>

            <hr>

            <div class="row">

              <div class="col-sm-6" style="text-align: left">
                <p class="lead marginbottom">
                  {{ `${getLabel('label.invoice.title.send_as')}:` }}
                </p>
                <hr style="padding: 0; margin: 2px 0 4px 0;"/>
                <div v-if="myProfile">
                  <div v-if="!myProfile.company">
                    <p><strong>{{ getLabel('label.title.natural_person').toUpperCase() }}</strong></p>
                    <p>{{ `${myProfile.name} ${myProfile.surname}` }}</p>
                    <p>{{ `${myProfile.city.name}, ${getLabel('label.country.north_macedonia')}` }}</p>
                    <p>{{ myProfile.address }}</p>
                    <p>{{ myProfile.phoneNumber }}</p>
                  </div>
                  <div v-else>
                    <p><strong>{{ getLabel('label.title.legal_person').toUpperCase() }}</strong></p>
                    <p><u>{{ `${myProfile.name} ${myProfile.surname}` }}</u></p>
                    {{ getLabel('label.invoice.title.send_as_company') }}
                    <p><u>{{ myProfile.company }}</u></p>
                  </div>
                </div>

              </div>
              <div class="col-sm-6" style="text-align: left">
                <p class="lead marginbottom">{{ `${getLabel('label.invoice.title.buy_as')}:` }}</p>
                <hr style="padding: 0; margin: 2px 0 4px 0;" />
                <form autocomplete="off">
                  <div class="form-group">
                    <Input
                        id="buyerNameSurname"
                        raw
                        small
                        type="text"
                        :placeholder="getLabel('label.input.name_surname')"
                        v-model="invoice.buyerNameSurname"
                    />
                  </div>
                  <div class="form-group">
                    <Input
                        id="buyerAddress"
                        raw
                        small
                        type="text"
                        :placeholder="getLabel('label.input.address')"
                        v-model="invoice.buyerAddress"
                    />
                  </div>
                  <div class="form-group">
                    <select class="form-control form-control-sm" v-model="invoice.idCity"
                            style="display: inline; width: 50%; margin-right: 10px;"
                    >
                      <option v-for="city in cities" :value="city.id" :key="city.id">{{ city.name }}</option>
                    </select>
                    <span>{{ getLabel('label.country.north_macedonia') }}</span>
                  </div>
                  <div class="form-group">
                    <MaskedInput
                        id="buyerPhoneNumber"
                        raw
                        small
                        :placeholder="getLabel('label.input.phone_number')"
                        v-model="invoice.buyerPhoneNumber"
                        mask="+389 7# ### ###"
                    />
                  </div>
                </form>
              </div>

            </div>

            <hr />

            <div v-if="invoice.packages.length > 0">
              <div class="row table-row">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th style="width:30%">{{ getLabel('label.invoice.title.product') }}</th>

                    <th class="text-right" style="width:10%">
                      {{ `${getLabel('label.invoice.title.height')} (${getLabel('label.unit.centimeter')})` }}
                    </th>
                    <th class="text-right" style="width:10%">
                      {{ `${getLabel('label.invoice.title.width')} (${getLabel('label.unit.centimeter')})` }}
                    </th>
                    <th class="text-right" style="width:10%">
                      {{ `${getLabel('label.invoice.title.length')} (${getLabel('label.unit.centimeter')})` }}
                    </th>
                    <th class="text-right" style="width:10%">
                      {{ `${getLabel('label.invoice.title.mass')} (${getLabel('label.unit.gram')})` }}
                    </th>

                    <th class="text-right" style="width:10%">{{ getLabel('label.invoice.title.quantity') }}</th>
                    <th class="text-right" style="width:10%">{{ getLabel('label.invoice.title.single_price') }}</th>
                    <th class="text-right" style="width:10%">{{ getLabel('label.invoice.title.total') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item,i) in invoice.packages" :key="item"
                        :class="i === invoice.packages.length-1 ? 'last-row':''"
                    >
                      <td>
                        <TextAreaInput
                            :id="`packages${i}name`"
                            raw
                            small
                            :placeholder="getLabel('label.placeholder.product_title')"
                            v-model="item.name"
                        />
                      </td>
                      <td class="text-right">
                        <Input
                            raw
                            small
                            type="number"
                            :id="`packages${i}heightCm`"
                            :placeholder="getLabel('label.invoice.title.height')"
                            v-model="item.heightCm"
                        />
                      </td>
                      <td class="text-right">
                        <Input
                            raw
                            small
                            type="number"
                            :id="`packages${i}widthCm`"
                            :placeholder="getLabel('label.invoice.title.width')"
                            v-model="item.widthCm"
                        />
                      </td>
                      <td class="text-right">
                        <Input
                            raw
                            small
                            type="number"
                            :id="`packages${i}lengthCm`"
                            :placeholder="getLabel('label.invoice.title.length')"
                            v-model="item.lengthCm"
                        />
                      </td>
                      <td class="text-right">
                        <Input
                            raw
                            small
                            type="number"
                            :id="`packages${i}weightGrams`"
                            :placeholder="getLabel('label.invoice.title.mass')"
                            v-model="item.weightGrams"
                        />
                      </td>
                      <td class="text-right">
                        <Input
                            raw
                            small
                            type="number"
                            :id="`packages${i}quantity`"
                            :placeholder="getLabel('label.invoice.title.quantity')"
                            v-model="item.quantity"
                        />
                      </td>
                      <td class="text-right">
                        <Input
                            raw
                            small
                            type="number"
                            :id="`packages${i}singleItemPrice`"
                            :placeholder="getLabel('label.invoice.title.price')"
                            v-model="item.singleItemPrice"
                        />
                      </td>
                      <td class="text-right">
                          <span v-if="item.singleItemPrice > 0 && item.quantity > 0"
                          >{{ item.singleItemPrice * item.quantity }}</span>
                        <span v-else>0</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-sm-12">
                <p>
                  {{ `${getLabel('label.invoice.title.choose_total_packages')}:` }}
                  <Input
                      raw
                      small
                      style="width: 10%; display: inline-block"
                      type="number"
                      v-model="numberOfPackages"
                  />
                  <br/><br/>
                  <button class="btn btn-sm btn-info" :disabled="numberOfPackages<1"
                          v-on:click="saveNumberOfPackages"
                  >
                    {{ getLabel('label.button.confirm') }}
                  </button>
                </p>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col-sm-12" style="text-align: left">
                {{ `${getLabel('label.invoice.title.invoice_information')}:` }}
                <br/><br/>
                <TextAreaInput
                    id="description"
                    raw
                    :placeholder="getLabel('label.placeholder.enter_invoice_information')"
                    v-model="invoice.description"
                />
              </div>
            </div>

            <hr />

            <div class="row justify-content-between">
              <div class="card col-sm-3" style="text-align: left;">
                <p>{{ `${getLabel('label.invoice.title.who_pays_cargo_fee')}:` }}</p>
                <hr style="padding: 0; margin: 2px 0 4px 0;" />
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="cargoPaysWho" v-model="invoice.sellerPaysCargo"
                         value="true"
                  >
                  <label class="form-check-label">
                    {{ getLabel('label.invoice.title.seller_pays_cargo_fee') }}
                  </label>
                </div>
                <div class="form-check" style="margin-bottom: 30px;">
                  <input class="form-check-input" type="radio" name="cargoPaysWho" v-model="invoice.sellerPaysCargo"
                         value="false"
                  >
                  <label class="form-check-label">
                    {{ getLabel('label.invoice.title.buyer_pays_cargo_fee') }}
                  </label>
                </div>

                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="checkboxForInvoice1"
                         v-model="invoice.paymentPackageDone"
                  >
                  <label class="form-check-label" for="checkboxForInvoice1">
                    {{ getLabel('label.invoice.title.paid_for_products') }}
                  </label>
                </div>
              </div>
              <div class="card col-sm-3" style="text-align: left;">
                <p>
                  {{ `${getLabel('label.invoice.title.pickup_from_where')}:` }}
                </p>
                <hr style="padding: 0; margin: 2px 0 4px 0;"/>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="startDestination" v-model="invoice.fromMcOffice"
                         value="true"
                  >
                  <label class="form-check-label">
                    {{ getLabel('label.invoice.title.delivery_mycargo') }}
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="startDestination" v-model="invoice.fromMcOffice"
                         value="false"
                  >
                  <label class="form-check-label">
                    {{ getLabel('label.invoice.title.delivery_home') }}
                  </label>
                </div>
              </div>
              <div class="card col-sm-3" style="text-align: left;">
                <p>{{ `${getLabel('label.invoice.title.deliver_to_where')}:` }}</p>
                <hr style="padding: 0; margin: 2px 0 4px 0;" />
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="endDestination" v-model="invoice.toMcOffice"
                         value="true"
                  >
                  <label class="form-check-label">
                    {{ getLabel('label.invoice.title.delivery_mycargo') }}
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="endDestination" v-model="invoice.toMcOffice"
                         value="false"
                  >
                  <label class="form-check-label">
                    {{ getLabel('label.invoice.title.delivery_home') }}
                  </label>
                </div>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col-sm-3 align-self-bottom" style="text-align: left;">
                <div v-if="invoice.sellerSignature">
                  <img :src="invoice.sellerSignature" style="width: 100%;" alt="x"/>
                  <button class="btn btn-sm btn-danger" style="margin: 5px 0;" v-on:click="clearSignature">
                    {{ getLabel('label.button.sign_again') }}
                  </button>
                </div>
                <div v-if="!invoice.sellerSignature">
                  <VueSignaturePad
                      style="border: 1px solid black; border-radius: 5px;"
                      id="signature"
                      width="300px"
                      height="200px"
                      ref="signaturePad"
                  />
                  <button class="btn btn-sm btn-info" style="margin: 5px 0;" v-on:click="saveSignature">
                    {{ getLabel('label.button.sign') }}
                  </button>
                  <span id="sellerSignature"/>
                  <span class="invalid-feedback"></span>
                </div>

                <hr style="padding: 0; margin: 4px 0 2px 0;" />
                <p>{{ getLabel('label.invoice.title.seller_signature') }}</p>
              </div>
              <div class="col-sm-9" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageLayout>
</template>

<script>
  import axios from 'axios';
  import Response from '../../../models/backend/Response';
  import router, { ROUTES } from '../../../router';
  import { useFormFieldService } from '@/service/FormFieldService';
  import MainButton from '../../partial/MainButton';
  import { useNotificationService } from '@/service/NotificationService';
  import { Seller } from '@/models/entity/Seller';
  import { Invoice } from '@/models/entity/Invoice';
  import { City } from '@/models/entity/City';
  import { useEntityParser } from '@/utils/EntityParser';
  import LoadingSpinner from '@/screens/partial/LoadingSpinner';
  import PageLayout from '@/shared/layout/PageLayout';
  import MaskedInput from '@/shared/input/MaskedInput';
  import Input from '@/shared/input/Input';
  import TextAreaInput from "@/shared/input/TextAreaInput";
  import {useMessageSource} from "@/config/MessageSource";

  const notificationService = useNotificationService()
  const formFieldService = useFormFieldService()
  const entityParser = useEntityParser()
  const {getLabel} = useMessageSource()

  const stateMutations = {
    /** @param {boolean} data **/
    setLoadingMain: function (data) {
      this.loading.main = data
    },
    /** @param {boolean} data **/
    setLoadingAdd: function (data) {
      this.loading.add = data
    },
    /** @param {Seller} data **/
    // TODO aboykov Rename this to Seller based naming and not MyProfile (which is not, because there is
    //  new implementation for MyProfile which is different from this).
    setMyProfile: function (data) {
      this.myProfile = data;
    },
    /** @param {City[]} data **/
    setCities: function (data) {
      this.cities = data;
    }
  }

  export default {
    name: 'SellerInvoiceNewPage',
    components: {TextAreaInput, Input, MaskedInput, PageLayout, LoadingSpinner, MainButton},
    data() {
      return {
        loading: {
          /** @type {boolean} **/
          main: true,
          /** @type {boolean} **/
          add: false
        },
        /** @type {number} **/
        numberOfPackages: 0,
        /** @type {City[]} **/
        cities: [],
        /** @type {Seller} **/
        myProfile: undefined,
        /** @type {Invoice} **/
        invoice: {
          buyerNameSurname: undefined,
          buyerAddress: undefined,
          buyerPhoneNumber: undefined,
          description: undefined,
          packages: [],
          paymentPackageDone: false,
          sellerPaysCargo: true,
          fromMcOffice: true,
          toMcOffice: true,
          idCity: 31,
          sellerSignature: undefined
        }
      }
    },
    mounted() {
      this.fetchCities();
      this.fetchMyProfile();
    },
    methods: {
      getLabel,
      clearSignature() {
        this.invoice.sellerSignature = undefined;
      },
      saveSignature() {
        const signature = this.$refs.signaturePad.saveSignature();
        const signatureBase64 = signature.data;
        this.invoice.sellerSignature = signatureBase64;
      },
      saveNumberOfPackages() {
        for (let i = 0; i < this.numberOfPackages; i++) {
          this.invoice.packages.push({});
        }
      },
      generateInvoice() {
        this.setLoadingAdd(true);

        formFieldService.resetInputErrors();

        const mutationObject = {
          ...this.invoice,
        }


        axios.post('invoice', mutationObject).then(it => {
          const response = new Response(it);

          const invoice = new Invoice(response.data);

          router.push({
            name: ROUTES.Seller.nested.Invoices.nested.Invoice.name,
            params: {
              uin: invoice.uin,
              infoMessage: response.message
            }
          })
        }).catch(it => {
          const response = new Response(it.response);

          if (response.hasErrors()) {
            formFieldService.populateErrorFields(response.errors);
          }

          // Do not show error if the validation violation happens. Errors will show up
          // under every input field.
          if (!response.isEntityNotValidError()) {
            notificationService.showInfoModal(response.message)
          }
        }).finally(() => this.setLoadingAdd(false))
      },
      fetchCities() {
        axios.get('city').then(it => {
          const response = new Response(it);

          const cities = entityParser.toCities(response.data);

          this.setCities(cities);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally();
      },
      fetchMyProfile() {
        axios.get('myProfile').then(it => {
          const response = new Response(it);

          this.setMyProfile(new Seller(response.data));
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally();
      },
      ...stateMutations
    },
    watch: {
      cities() {
        if (this.myProfile && this.cities.length > 0) {
          this.setLoadingMain(false);
        }
      },
      myProfile() {
        if (this.myProfile && this.cities.length > 0) {
          this.setLoadingMain(false);
        }
      }
    }
  }
</script>

<style scoped src="../../../assets/css/partial/invoice-css.css" />
<style scoped>
  .card {
    z-index: 0;
    background-color: transparent;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px
  }
</style>