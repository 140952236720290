<template>
  <PageLayout>
    <Title :text="title" />

    <LoadingSpinner v-if="loading.main" />

    <AccountsGrid
        :rows="factoryAccountRows"
        :columns="columns"
        :empty-grid-message="emptyGridMessage"
        :path-to-connect-account-navigation="pathToConnectAccountNavigation"
    />
  </PageLayout>

</template>

<script>
  import AccountsGrid from '@/screens/shared/account/AccountsGrid';
  import Title from '@/screens/partial/Title';
  import LoadingSpinner from '@/screens/partial/LoadingSpinner';
  import { useRoute } from 'vue-router';
  import { useNotificationService } from '@/service/NotificationService';
  import axios from 'axios';
  import Response from '@/models/backend/Response';
  import { useEntityParser } from '@/utils/EntityParser';
  import { Account } from '@/models/entity/Account';
  import { useDateTimeUtils } from '@/utils/DateTimeUtils';
  import { resolvePathWithParams, ROUTES } from '@/router';
  import PageLayout from '@/shared/layout/PageLayout';

  const columns = [
    {
      field: 'username',
      header: 'Корисничко име',
      sortable: true
    },
    {
      field: 'email',
      header: 'Емаил адреса',
      sortable: true
    },
    {
      field: 'created',
      sortableField: 'createdSortable',
      header: 'Креирана',
      sortable: true,
      defaultSortable: true
    }
  ]

  const notificationService = useNotificationService()
  const entityParser = useEntityParser()
  const dateTimeUtils = useDateTimeUtils()

  const stateMutations = {
    /** @param {Array<Account>} data **/
    setAccounts: function (data) {
      this.accounts = data
    },
    /** @param {boolean} data **/
    setLoadingMain: function (data) {
      this.loading.main = data
    }
  }

  export default {
    name: 'SellerUnconnectedAccountsPage',
    components: {PageLayout, LoadingSpinner, Title, AccountsGrid},
    data() {
      return {
        loading: {
          /** @type {boolean} **/
          main: true
        },
        /** @type {Array<Account>} **/
        accounts: undefined,
        columns: columns,
        /** @type {string} **/
        emptyGridMessage: 'Листата на кориснички сметки кои чекаат поврзување со корисник е празна.',
        /** @type {string} **/
        title: 'Листа на неповрзани кориснички сметки'
      }
    },
    mounted() {
      const {infoMessage} = useRoute().params;
      notificationService.maybeShowInfoModal(infoMessage)

      this.getData();
    },
    methods: {
      getData() {
        axios.get('unconnectedAccounts').then(it => {
          const response = new Response(it);

          const accounts = entityParser.toAccounts(response.data);

          this.setAccounts(accounts);
          this.setLoadingMain(false);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally()
      },
      /** --------------------- Navigation resolver functions --------------------- **/
      /** @param {string} username **/
      pathToConnectAccountNavigation(username) {
        return resolvePathWithParams(ROUTES.Manager.nested.Accounts.nested.ConnectAccount.path, username);
      },
      ...stateMutations
    },
    computed: {
      factoryAccountRows() {
        if (!this.accounts) {
          return;
        }

        const rows = new Array(this.accounts.length);

        this.accounts.forEach((account, index) => {
          rows[index] = {};

          this.columns.forEach((column) => {
            rows[index][column.field] = account[column.field];

            if (column.sortableField) {
              rows[index][column.field] = dateTimeUtils.toLocalDateTime(account[column.field]);
              rows[index][column.sortableField] = account[column.field]
            }
          });
        })

        return rows;
      }
    }
  }
</script>

<style scoped>

</style>