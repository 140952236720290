<template>
  <div class="user-box input-box">
    <InputText
        :id="id"
        class="localInput form-control"
        :class="[
          small ? 'form-control-sm' : '',
          !raw ? 'input-box-inner' : ''
      ]"
        v-bind:type="type"
        v-bind:placeholder="placeholder"
        :modelValue="modelValue"
        @input="onChange"
        :onkeydown="(event) => validateInput(event.key)"
        required
    />
    <label v-if="label">{{ this.label }}</label>
    <span class="invalid-feedback"></span>
  </div>
</template>

<script>

import InputText from "@/assets/vendor/primevue/inputtext";

export default {
  name: 'Input',
  components: {InputText},
  props: {
    id: {
      type: String,
      required: true
    },
    raw: {
      type: Boolean,
      required: false,
      default: () => false
    },
    small: {
      type: Boolean,
      required: false,
      default: () => false
    },
    alphanumericOnly: {
      type: Boolean,
      required: false,
      default: () => false
    },
    type: {
      type: String,
      required: true,
      validator: (value) => {
        return ['text', 'number', 'password'].includes(value);
      }
    },
    placeholder: {
      type: String,
      required: false,
      default: () => ''
    },
    modelValue: {
      type: String,
      required: false,
      default: () => ''
    },
    label: {
      type: String,
      required: false,
      default: () => undefined
    }
  },
  methods: {
    validateInput(input) {
      if (this.alphanumericOnly) {
        return /^[A-Za-zА-Ша-ш0-9]*$/ig.test(input)
      }
      return true;
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    return {
      onChange: (e) => {
        emit('update:modelValue', e.currentTarget.value)
      }
    }
  }
}
</script>

<style scoped>
.localInput, .localInput:focus {
  font-family: inherit;
}

/* DO NOT REMOVE, THIS IS USED ABOVE */
.input-box-inner {
  border-radius: 0;
  border-bottom: 1px solid #fff;
}

/* DO NOT REMOVE, THIS IS USED ABOVE */
.input-box-inner:focus {
  box-shadow: none;
  border-color: white;
}

/* DO NOT REMOVE, THIS IS USED ABOVE */
.input-box-inner:hover {
  border-color: white;
}
</style>