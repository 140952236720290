<template>
  <a>
    <div class="figure" :cardTitle="cardTitle">
      <div class="card-icon">
        <span class="fa fa-mouse-pointer" />
      </div>
      <div class="figcaption">
        <p>
          {{ cardTitle }}
        </p>
      </div>
    </div>
  </a>
</template>

<script>
  // TODO aboykov Maybe add dynamic background for this component.
  //:style="{ backgroundImage: 'url(' + require('@/assets/imgs/banner-bg.png') + ')'}"

  export default {
    name: 'CardView',
    props: {
      cardTitle: String
    }
  }
</script>

<style scoped>
  *, *:before, *:after {
    box-sizing: border-box;
    outline: none;
  }

  a {
    text-decoration: none;
  }

  .figure {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 300px;
    padding: 50% 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    overflow: hidden;
  }

  .figure:before, .figure:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .figure:before {
    content: '';
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 0;
  }

  .figure:after {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    overflow: hidden;
  }

  .figure:hover {
    background-size: 175%;
  }

  .figure:hover:after {
    content: attr(cardTitle);
    font-weight: bold;
    font-size: 1.3em;
    background-color: rgba(241, 196, 15, 0.9);
    color: white;
    z-index: 2;
  }

  .figure:hover .card-icon {
    bottom: -59px;
  }

  .figure:hover .figcaption {
    transform: translateY(-110%);
  }

  .figure .card-icon {
    position: absolute;
    bottom: 0;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    background-color: rgba(241, 196, 15, 0.8);
    text-shadow: 1px 1px rgba(0, 0, 0, 0.7);
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .figure .card-icon span {
    color: white;
    line-height: 1;
  }

  .figure .card-icon span:first-child {
    font-size: 20px;
    font-weight: 900;
  }

  .figure .card-icon span:last-child {
    font-size: 14px;
    font-weight: 400;
  }

  .figure .figcaption {
    color: white;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    z-index: 1;
  }

  .figure .figcaption p {
    margin: 0;
    line-height: 1.5;
  }

</style>