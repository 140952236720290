import { Invoice } from '@/models/entity/Invoice';
import { Package } from '@/models/entity/Package';
import { City } from '@/models/entity/City';
import { Employee } from '@/models/entity/Employee';
import { Account } from '@/models/entity/Account';
import { Company } from '@/models/entity/Company';

/**
 * Converts the array of objects to array of invoices.
 *
 * @param   {Array<Object>}            data       The data object.
 * @returns {Array<Invoice>}                      The parsed object.
 */
const toInvoices = (data) => {
  return data.map((item) => {
    return new Invoice(item);
  });
};

/**
 * Converts the array of objects to array of packages.
 *
 * @param   {Array<Object>}            data       The data object.
 * @returns {Array<Package>}                      The parsed object.
 */
const toPackages = (data) => {
  return data.map((item) => {
    return new Package(item);
  });
};

/**
 * Converts the array of objects to array of employees.
 *
 * @param   {Array<Object>}            data       The data object.
 * @returns {Array<Employee>}                     The parsed object.
 */
const toEmployees = (data) => {
  return data.map((item) => {
    return new Employee(item);
  });
};

/**
 * Converts the array of objects to array of cities.
 *
 * @param   {Array<Object>}            data       The data object.
 * @returns {Array<City>}                         The parsed object.
 */
const toCities = (data) => {
  return data.map((item) => {
    return new City(item);
  });
};

/**
 * Converts the array of objects to array of accounts.
 *
 * @param   {Array<Object>}            data       The data object.
 * @returns {Array<Account>}                      The parsed object.
 */
const toAccounts = (data) => {
  return data.map((item) => {
    return new Account(item);
  });
};

/**
 * Converts the array of objects to array of companies.
 *
 * @param   {Array<Object>}            data       The data object.
 * @returns {Array<Company>}                      The parsed object.
 */
const toCompanies = (data) => {
  return data.map((item) => {
    return new Company(item);
  });
};

export const useEntityParser = () => {
  return {
    toInvoices,
    toPackages,
    toEmployees,
    toCities,
    toAccounts,
    toCompanies
  };
};
