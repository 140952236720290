<template>
  <TitledPage
      title="Промена на податоци за компанија"
      :on-back="onBack"
      :on-save="!loading.main ? saveCompany : undefined"
      :loading-save="loading.save"
  >
    <LoadingSpinner v-if="loading.main" />

    <div v-if="!loading.main">
      <div class="card-body form-input-box">
        <form autocomplete="off">
          <DisabledInputField
              label="Компанија"
              :value="company.name"
          />
          <DisabledInputField
              label="Единствен Даночен Број (ЕДБ)"
              :value="company.edb"
          />
          <Input
              id="address"
              type="text"
              label="Адреса"
              v-model="company.address"
          />

          <div class="input-box" style="text-align: left;">
            <div class="row row-eq-height align-items-center d-flex align-items-stretch">
              <div class="col-sm-6 p-0">
                <SelectInput
                    id="idCity"
                    label="Град"
                    :data="cities"
                    v-model="company.city.id"
                />
              </div>
              <div class="col-sm-6" style="padding-top: 1.2rem!important;">
                <span>Р. Северна Македонија</span>
              </div>
            </div>
          </div>

          <MaskedInput
              id="phoneNumber"
              label="Телефонски број"
              v-model="company.phoneNumber"
              mask="+389 7# ### ###"
          />

        </form>
      </div>
    </div>
  </TitledPage>
</template>

<script>
  import axios from 'axios';
  import Response from '@/models/backend/Response';
  import {Company} from '@/models/entity/Company';
  import {useNotificationService} from '@/service/NotificationService';
  import {useEntityParser} from '@/utils/EntityParser';
  import {useRoute} from 'vue-router';
  import LoadingSpinner from '@/screens/partial/LoadingSpinner';
  import router, {resolveBaseRoute} from '@/router';
  import {useFormFieldService} from '@/service/FormFieldService';
  import DisabledInputField from '@/screens/partial/DisabledInputField';
  import TitledPage from '@/shared/layout/TitledPage';
  import Input from "@/shared/input/Input";
  import MaskedInput from "@/shared/input/MaskedInput";
  import SelectInput from "@/shared/input/SelectInput";

  const notificationService = useNotificationService()
  const entityParser = useEntityParser()
  const formFieldService = useFormFieldService()

  const stateMutations = {
    /** @param {Company} data **/
    setCompany: function (data) {
      this.company = data
    },
    /** @param {Array<City>} data **/
    setCities: function (data) {
      this.cities = data
    },
    /** @param {boolean} value **/
    setLoadingMain: function (value) {
      this.loading.main = value
    },
    /** @param {boolean} value **/
    setLoadingSave: function (value) {
      this.loading.save = value
    }
  }

  export default {
    name: 'CompanyBasicInformationEditPage',
    components: {SelectInput, MaskedInput, Input, TitledPage, DisabledInputField, LoadingSpinner},
    data() {
      return {
        /** @type {Number} **/
        companyId: undefined,
        loading: {
          /** @type {Boolean} **/
          main: true,
          /** @type {Boolean} **/
          save: false
        },
        /** @type {Company} **/
        company: undefined,
        /** @type {Array<City>} **/
        cities: undefined
      }
    },
    mounted() {
      const {id} = useRoute().params
      this.companyId = parseInt(id.toString())

      this.getData();
      this.fetchCities();
    },
    methods: {
      onBack() {
        router.push({
          name: resolveBaseRoute().nested.Companies.nested.Company.name,
          params: {
            id: this.companyId
          }
        })
      },
      getData() {
        axios.get(`company/${this.companyId}`).then(it => {
          const response = new Response(it);

          const company = new Company(response.data);

          this.setCompany(company);
          this.setLoadingMain(false);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally()
      },
      fetchCities() {
        axios.get('city').then(it => {
          const response = new Response(it);

          const cities = entityParser.toCities(response.data);

          this.setCities(cities);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally(() => this.setLoadingMain(false))
      },
      saveCompany() {
        this.setLoadingSave(true);

        formFieldService.resetInputErrors();

        const requestObject = this.company;
        requestObject.idCity = requestObject.city.id;

        axios.put(`company/${this.companyId}`, requestObject).then(it => {
          const response = new Response(it);

          router.push({
            name: resolveBaseRoute().nested.Companies.nested.Company.name,
            params: {
              id: this.companyId,
              infoMessage: response.message
            }
          });

        }).catch(it => {
          const response = new Response(it.response);

          if (response.hasErrors()) {
            formFieldService.populateErrorFields(response.errors);
          }

          // Do not show error if the validation violation happens. Errors will show up
          // under every input field.
          if (!response.isEntityNotValidError()) {
            notificationService.showInfoModal(response.message)
          }
        }).finally(() => this.setLoadingSave(false));
      },
      ...stateMutations
    }
  }
</script>

<style scoped>

</style>