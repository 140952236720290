export class Account {
  constructor(data) {
    if (data) {
      this.id = data.id;
      this.email = data.email;
      this.username = data.username;
      this.password = data.password;
      this.role = data.role;
      this.created = data.created;
      this.lastLogin = data.lastLogin;
      this.isActive = data.isActive;
    }
  }
}
