<template>
  <div class="input-box user-box">
    <div v-if="label" style="padding-bottom: 17px;">
      <label class="localTextInputLabel">{{ label }}</label>
    </div>
    <TextArea
        :id="id"
        class="localTextInput form-control"
        :class="[
          small ? 'form-control-sm' : '',
          !raw ? 'input-box-inner' : ''
      ]"
        :value="modelValue"
        @input="onChange"
        v-bind:placeholder="placeholder"
        :autoResize="true"
    />
    <span class="invalid-feedback"></span>
  </div>
</template>

<script>
import TextArea from "@/assets/vendor/primevue/textarea";

export default {
  name: 'TextAreaInput',
  components: {TextArea},
  props: {
    id: {
      type: String,
      required: true
    },
    raw: {
      type: Boolean,
      required: false,
      default: () => false
    },
    small: {
      type: Boolean,
      required: false,
      default: () => false
    },
    label: {
      type: String,
      required: false,
      default: () => undefined
    },
    placeholder: {
      type: String,
      required: false,
      default: () => ''
    },
    modelValue: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    return {
      onChange: (e) => {
        emit('update:modelValue', e.currentTarget.value)
      }
    }
  }
}
</script>

<style scoped>
.localTextInputLabel {
  margin-bottom: 10px !important;
  top: -25px !important;
  left: 0 !important;
  color: var(--main-color) !important;
  font-size: 12px !important;
  padding: 20px 0 !important;
}

.localTextInput {
  font-family: inherit;
  border-color: white;
}

.input-box-inner {
  border-bottom: 1px solid #fff;
  color: white;
  background: transparent;
}

.input-box-inner::placeholder {
  color: #FFFFFF99;
}

.input-box-inner:focus {
  box-shadow: none;
  border-color: white;
  color: white;
  background: transparent;
}

.input-box-inner:hover {
  border-color: white;
}
</style>