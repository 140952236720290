<template>
  <PageLayout>
    <Title text="Листа на вработени" />

    <LoadingSpinner v-if="loading.main" />

    <EmployeeGrid
        :rows="factoryInvoiceRows"
        :columns="columns"
        :empty-grid-message="emptyGridMessage"
        :path-to-employee-details="pathToEmployeeDetails"
        :path-to-employee-new="pathToEmployeeNew()"
    />
  </PageLayout>

</template>

<script>
  import EmployeeGrid from '@/screens/shared/employee/EmployeeGrid';
  import Title from '@/screens/partial/Title';
  import LoadingSpinner from '@/screens/partial/LoadingSpinner';
  import axios from 'axios';
  import Response from '@/models/backend/Response';
  import { useNotificationService } from '@/service/NotificationService';
  import { useEntityParser } from '@/utils/EntityParser';
  import { Employee } from '@/models/entity/Employee';
  import { useRoute } from 'vue-router';
  import { resolveBaseRoute, resolvePathWithParams } from '@/router';
  import { City } from '@/models/entity/City';
  import PageLayout from '@/shared/layout/PageLayout';

  const notificationService = useNotificationService()
  const entityParser = useEntityParser()

  const columns = [
    {
      field: 'id',
      header: 'id',
      hidden: true
    },
    {
      field: 'name',
      header: 'Име',
      sortable: true
    },
    {
      field: 'surname',
      header: 'Презиме',
      sortable: true,
      defaultSortable: true
    },
    {
      field: 'city',
      header: 'Град',
      sortable: true
    },
    {
      field: 'hasConnectedAccount',
      header: 'Акаунт',
      sortable: true
    }
  ]

  const stateMutations = {
    /** @param {Array<Employee>} data **/
    setEmployees: function (data) {
      this.employees = data
    },
    /** @param {boolean} data **/
    setLoadingMain: function (data) {
      this.loading.main = data
    }
  }

  export default {
    name: 'EmployeesPage',
    components: {PageLayout, LoadingSpinner, Title, EmployeeGrid},
    data() {
      return {
        loading: {
          /** @type {Boolean} **/
          main: true
        },
        /** @type {Array<Employee>} **/
        employees: undefined,
        columns: columns,
        emptyGridMessage: 'Листата на вработени е празна.'
      }
    },
    mounted() {
      const {infoMessage} = useRoute().params;
      notificationService.maybeShowInfoModal(infoMessage)

      this.getData();
    },
    methods: {
      getData() {
        axios.get('employees').then(it => {
          const response = new Response(it);

          const employees = entityParser.toEmployees(response.data);

          this.setEmployees(employees);
          this.setLoadingMain(false);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally()
      },
      /** --------------------- Navigation resolver functions --------------------- **/
      /** @param {string} id **/
      pathToEmployeeDetails(id) {
        return resolvePathWithParams(resolveBaseRoute().nested.Employees.nested.Employee.path, id);
      },
      pathToEmployeeNew() {
        return resolveBaseRoute().nested.Employees.nested.EmployeeNew.path;
      },
      ...stateMutations
    },
    computed: {
      factoryInvoiceRows() {
        if (!this.employees) {
          return;
        }

        const rows = new Array(this.employees.length);

        this.employees.forEach((employee, index) => {
          rows[index] = {};

          this.columns.forEach((column) => {
            if (column.field === 'city') {
              const city = new City(employee[column.field]);

              rows[index]['city'] = city.name;
            } else {
              rows[index][column.field] = employee[column.field];
            }
          });
        })

        return rows;
      }
    }
  }
</script>

<style scoped>

</style>