<template>
  <div>Додавање на нов вработен</div>
  <br />
  <EmployeeNew />
</template>

<script>
  import EmployeeNew from '@/screens/shared/employee/EmployeeNew';

  export default {
    name: 'EmployeeCreatePage',
    components: {EmployeeNew}
  }
</script>

<style scoped>

</style>