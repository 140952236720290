<template>
  <PageLayout>
    <InternalViewInvoices
        :title="title"
        special
        :columns="columns"
        :empty-grid-message="emptyGridMessage"
    />
  </PageLayout>

</template>

<script>
  import InternalViewInvoices from '../../shared/invoice/InternalViewInvoices';
  import PageLayout from '@/shared/layout/PageLayout';

  const columns = [
    {
      field: 'uin',
      header: 'Бр. на фактура'
    },
    {
      field: 'assignedTimestamp',
      sortableField: 'assignedTimestampSortable',
      header: 'Доделена',
      sortable: true,
      defaultSortable: true
    },
    {
      field: 'buyerNameSurname',
      header: 'Нарачал',
      sortable: true
    },
    {
      field: 'packagesLength',
      header: 'Вкупно пратки',
      sortable: true
    }
  ]

  export default {
    name: 'ManagerAssignedInvoicesPage',
    components: {PageLayout, InternalViewInvoices},
    data() {
      return {
        columns: columns,
        emptyGridMessage: 'Листата на фактури кои ги имате доделено е празна.',
        title: 'Листа на доделени фактури'
      }
    }
  }
</script>

<style scoped>

</style>