<template>
  <!--   TODO aboykov  Is PageLayout needed here, and also inside the 'PrivacyPolicy' and 'TermsAndConditions'?   -->
  <PageLayout>
    <div v-if="!isInMacedonianLanguage()" class="alert alert-danger">
      <i class="fa fa-warning"/>
      This page is available in the following languages only: MK. Please switch the language to access the page.
    </div>
    <div v-else>
      <OptionSwitcher style="margin: 25px auto;"
                      option1="Политика на приватност"
                      option2="Услови на користење"
                      v-on:click="switcherOptionSwitch"
      ></OptionSwitcher>
      <PrivacyPolicy v-if="privacyPolicySelected"></PrivacyPolicy>
      <TermsAndConditions v-else></TermsAndConditions>
    </div>
  </PageLayout>
</template>

<script>
import PrivacyPolicy from './PrivacyPolicy';
import OptionSwitcher from '../partial/OptionSwitcher';
import TermsAndConditions from './TermsAndContitions';
import PageLayout from '@/shared/layout/PageLayout';
import {useLocalStorageUtils} from "@/utils/LocalStorageUtils";

const {getLanguage} = useLocalStorageUtils()

export default {
  name: 'About',
  components: {PageLayout, TermsAndConditions, OptionSwitcher, PrivacyPolicy},
  data() {
    return {
      privacyPolicySelected: true
    }
  },
  methods: {
    switcherOptionSwitch() {
      this.privacyPolicySelected = !this.privacyPolicySelected;
    },
    isInMacedonianLanguage() {
      return getLanguage() === 'mk'
    }
  }
  }
</script>