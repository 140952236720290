<template>
  <PageLayout id="privacyPolicy">
    <div class="row">
      <div class="col-md-12">
        <h1> Политика на приватност за „моеКарго“ </h1>
        <hr />
        <p> На „моеКарго“, достапен од mycargo.aboykov.com, еден од нашите главни приоритети е приватноста на нашите
          посетители. Овој документ за политика на приватност содржи типови на информации што ги собира и евидентира
          „моеКарго“ и како истите се користат.</p>
        <p> Ако имате дополнителни прашања или ви требаат повеќе информации за нашата политика на приватност, не
          двоумете се да нè контактирате.</p>
        <p> Оваа политика на приватност се однесува само на нашите активности на интернет и важи за посетителите на
          нашата веб-страница во однос на информациите што се споделуваат и/или се собираат од „моеКарго“. Оваа
          политика не се применува за какви било информации собрани преку интернет или преку канали, освен од оваа
          веб-страница.</p>
        <h2> Согласност </h2>
        <p> Со користење на нашата веб-страница, вие се согласувате со нашата политика на приватност и се
          согласувате со нејзините услови.</p>
        <h2> Информации што ги собираме </h2>
        <p> Личните информации што од вас се бара да ги дадете и причините поради кои се бара да ги доставите, ќе ви
          бидат јасни во моментот кога ќе ве замолиме да ги дадете вашите лични информации.</p>
        <p> Ако контактирате директно со нас, може да добиеме дополнителни информации за вас, како што се вашето
          име, адреса за е-пошта, телефонски број, содржината на пораката и/или прилозите што може да ни ги
          испратите и сите други информации што може да изберете да ги доставите.</p>
        <p> Кога регистрирате користичка сметка, ние може да побараме информации за контакт, вклучувајќи име, име на
          компанија, адреса, адреса на е-пошта и телефонски број.</p>
        <h2> Како ги користиме вашите податоци </h2>
        <p> Ние ги користиме информациите што ги собираме на различни начини, вклучително: </p>
        <ul>
          <li> Обезбедување, оперирање и одржување на нашата веб-страница</li>
          <li> Подобрување, персонализирање и проширување на нашата веб-страница</li>
          <li> Разбирање и анализа како се користи нашата веб-страница</li>
          <li> Развивање нови производи, услуги, карактеристики и функционалности</li>
          <li> Комуницирање со вас, директно или преку некој од нашите партнери, вклучително и за услуги на
            клиентите, со цел добивање ажурирања и други информации во врска со веб-страницата, како и за маркетинг
            и промотивни цели
          </li>
          <li> Испраќање е-пошта</li>
          <li> Пронаоѓање и спречување измама</li>
        </ul>
        <h2> Датотечни логови </h2>
        <p> „моеКарго“ следи стандардна постапка за користење датотечни логови. Овие датотеки ги логираат
          посетителите кога посетуваат веб-страници. Сите компании за хостирање го прават ова и е дел од аналитиката
          на хостинг услугите. Информациите собрани од датотеките за најавување вклучуваат адреси на интернет
          протокол (IP), тип на прелистувач, Давател на услуги на интернет (ISP), печат за датум и време, страници
          за упатување/излез и евентуално број на кликови. Овие не се поврзани со никакви информации што се лично
          препознатливи. Целта на информациите е да се анализираат трендовите, да се администрира страницата, да се
          следи движењето на корисниците на веб-страницата и да се соберат демографски информации.</p>
        <h2> Колачиња и „web beacons“ </h2>
        <p> Како и секоја друга веб-страница, „моеКарго“ користи „колачиња“. Овие колачиња се користат за чување
          информации, вклучувајќи ги преференциите на посетителите, како и страниците на веб-страницата до која
          посетителот пристапувал или ги посетил. Информациите се користат за оптимизирање на искуството на
          корисниците со прилагодување на содржината на нашата веб-страница заснована на типот на прелистувачот на
          посетителите и/или други информации.</p>
        <h2> Политики за приватност на партнери за рекламирање </h2>
        <p> Сервери за рекламирање на трети страни или мрежни реклами користат технологии како колачиња, JavaScript
          или „web beacons“ што се користат во нивните соодветни реклами и врски што се појавуваат на „моеКарго“,
          кои се испраќаат директно до прелистувачот на корисниците. Тие автоматски ја добиваат вашата IP адреса
          кога тоа ќе се случи. Овие технологии се користат за мерење на ефективноста на нивните рекламни кампањи
          и/или за персонализирање на содржината за рекламирање што ја гледате на веб-страниците што ги
          посетувате.</p>
        <p> Имајте предвид дека „моеКарго“ нема пристап до или контрола над овие колачиња што ги користат трети
          рекламни страни.</p>
        <h2> Политики за приватност на трети страни </h2>
        <p>Политиката за приватност на „моеКарго“ не се однесува на други рекламни или веб-страници. Така што ве
          советуваме да се консултирате со соодветните полиси за приватност на овие трети лица за рекламирање за
          подетални информации. Може да ги вклучи нивните практики и упатства за тоа како да се откажете од одредени
          опции. </p>
        <p> Можете да изберете да ги оневозможите колачињата преку опциите за индивидуални прелистувачи. За да
          дознаете подетални информации за управување со колачиња со специфични веб прелистувачи, посетете ги
          соодветните веб-страници на прелистувачите.</p>
        <h2> Права на заштита на податоците на GDPR </h2>
        <p> Би сакале да се осигураме дека сте целосно свесни за сите ваши права за заштита на податоците. Секој
          корисник има право на следново: </p>
        <ul>
          <li> Право на пристап - Вие имате право да побарате копии од вашите лични податоци. Можеби ќе ви наплатиме
            мала такса за оваа услуга.
          </li>
          <li> Право на исправка - Вие имате право да побарате да ги исправиме сите информации кои сметате дека се
            неточни. Вие исто така имате право да побарате да ги комплетираме информациите за кои сметате дека се
            нецелосни.
          </li>
          <li> Право на бришење - Вие имате право да побарате да ги избришеме вашите лични податоци, под одредени
            услови.
          </li>
          <li> Право на ограничување на обработката - Вие имате право да побарате да ја ограничиме обработката на
            вашите лични податоци, под одредени услови.
          </li>
          <li> Право на приговор на обработка - Вие имате право да се спротивставувате на нашата обработка на вашите
            лични податоци, под одредени услови.
          </li>
          <li> Право на преносливост на податоци - Вие имате право да побарате да ги пренесеме податоците што се
            собрани за вас на друга организација, или директно на вас, под одредени услови.
          </li>
          <li> Ако поднесете барање, имаме еден месец да ви одговориме. Доколку сакате да остварите некое од овие
            права, контактирајте нè.
          </li>
        </ul>
        <h2> Информации за деца </h2>
        <p> Друг дел од нашиот приоритет е додавање на заштита на децата додека се користи интернет. Ги охрабруваме
          родителите и старателите да ја набљудуваат, учествуваат и/или следат и водат нивната активност преку
          интернет.</p>
        <p> „моеКарго“ не собира лични информации за идентификација од деца под 13 годишна возраст. Ако мислите дека
          вашето дете обезбедило некаков вид на информации на нашата веб-страница, ние ве охрабруваме веднаш да
          стапите во контакт со нас и ќе вложиме максимални напори за навремено отстранување на таквите информации
          од нашите записи.</p>
        <h2>Важење на Политиката на приватност</h2>
        <p>Стапува на сила на 12.02.2022 и сè уште важи. Овој документ е генериран и сите наведени информации во
          него се фиктивни.</p>
      </div>
    </div>
  </PageLayout>
</template>

<script>
  import PageLayout from '@/shared/layout/PageLayout';

  export default {
    name: 'PrivacyPolicy',
    components: {PageLayout}
  }
</script>

<style scoped>
  #privacyPolicy {
    text-align: justify;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p {
    margin: 10px 0;
  }

  ul li {
    list-style-type: circle;
    margin-left: 50px;
  }
</style>