<template>
  <TitledPage
      title="Податоци зa компанија"
      :on-back="onBack"
      :on-edit="!loading.main ? handleEditBasicInformation : undefined"
      :on-delete="(!loading.main && company.canBeDeleted) ? deleteCompany : undefined"
      :loading-delete="loading.deleteCompany"
  >

    <LoadingSpinner v-if="loading.main" />

    <div v-if="!loading.main" class="tab-pane fade active show">
      <div class="card-body form-input-box">
        <div class="">
          <DisabledInputField
              label="Компанија"
              :value="company.name"
          />
          <DisabledInputField
              label="Единствен Даночен Број (ЕДБ)"
              :value="company.edb"
          />
          <DisabledInputField
              label="Адреса"
              :value="company.address"
          />
          <DisabledInputField
              label="Град"
              :value="company.city.name"
          />
          <DisabledInputField
              label="Телефонски број"
              :value="company.phoneNumber"
          />

        </div>

      </div>

    </div>

  </TitledPage>
  <ConfirmationDialog
      id="delete-company"
      text="Дали сте сигурни дека сакате да ја избришете компанијата и сите нејзини податоци?"
      :showing="showing.deleteCompany"
      :on-success="doDeleteCompany"
      :on-cancel="cancelDeleteCompany"
  />
</template>

<script>
  import LoadingSpinner from '../../partial/LoadingSpinner';
  import DisabledInputField from '../../partial/DisabledInputField';
  import { Company } from '@/models/entity/Company';
  import axios from 'axios';
  import Response from '../../../models/backend/Response';
  import { useNotificationService } from '@/service/NotificationService';
  import { useEntityParser } from '@/utils/EntityParser';
  import { useRoute } from 'vue-router';
  import ConfirmationDialog from '@/screens/partial/ConfirmationDialog';
  import router, { resolveBaseRoute, resolvePathWithParams } from '@/router';
  import TitledPage from '@/shared/layout/TitledPage';

  const notificationService = useNotificationService()
  const entityParser = useEntityParser()

  const stateMutations = {
    /** @param {Company} data **/
    setCompany: function (data) {
      this.company = data
    },
    /** @param {boolean} value **/
    setLoadingMain: function (value) {
      this.loading.main = value
    },
    /** @param {boolean} value **/
    setLoadingDeleteCompany: function (value) {
      this.loading.deleteCompany = value
    },
    /** @param {boolean} value **/
    setShowingDeleteCompany: function (value) {
      this.showing.deleteCompany = value;
    }
  }

  export default {
    name: 'CompanyBasicInformationPage',
    components: {TitledPage, ConfirmationDialog, DisabledInputField, LoadingSpinner},
    data() {
      return {
        /** @type {Number} **/
        companyId: undefined,
        loading: {
          /** @type {Boolean} **/
          main: true,
          /** @type {Boolean} **/
          deleteCompany: false
        },
        showing: {
          /** @type {Boolean} **/
          deleteCompany: false
        },
        /** @type {Company} **/
        company: undefined
      }
    },
    mounted() {
      const {infoMessage, id} = useRoute().params;
      this.companyId = parseInt(id.toString());

      notificationService.maybeShowInfoModal(infoMessage)

      this.getData();
    },
    methods: {
      getData() {
        // TODO aboykov This is left intentionally in case I want to present this during the main
        //  presentation in front of the professors to show the fetch waiting process.
        // await new Promise(r => setTimeout(r, 1000));

        axios.get(`company/${this.companyId}`).then(it => {
          const response = new Response(it);

          const company = new Company(response.data);

          this.setCompany(company);
          this.setLoadingMain(false);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally()
      },
      handleEditBasicInformation() {
        const route = resolvePathWithParams(resolveBaseRoute().nested.Companies.nested.CompanyEdit.path, this.companyId);
        router.push(route)
      },
      deleteCompany() {
        this.setLoadingDeleteCompany(true)
        this.setShowingDeleteCompany(true)
      },
      doDeleteCompany() {
        this.setShowingDeleteCompany(false);

        axios.delete(`company/${this.companyId}`).then(it => {
          let response = new Response(it);

          router.push({
            name: resolveBaseRoute().nested.Companies.name,
            params: {
              infoMessage: response.message
            }
          })
        }).catch(it => {
          let response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally(() => this.setLoadingDeleteCompany(false));
      },
      cancelDeleteCompany() {
        this.setLoadingDeleteCompany(false)
        this.setShowingDeleteCompany(false)
      },
      onBack() {
        router.push({
          name: resolveBaseRoute().nested.Companies.name
        })
      },
      ...stateMutations
    }
  }
</script>