export const ROLE = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_EMPLOYEE: 'ROLE_EMPLOYEE',
  ROLE_SELLER: 'ROLE_SELLER',
  ROLE_MANAGER: 'ROLE_MANAGER'
};

export const useRoleService = () => {
  return {};
};
