import mitt from 'mitt';

const RELOAD_MENU_ITEMS_EMITTER = 'reloadMenuItems';

let appEmitter = undefined;

const onReloadMenuItems = (callback) => {
  appEmitter.on(RELOAD_MENU_ITEMS_EMITTER, async (reload) => {
    if (reload) {
      callback();
    }
  });
}

const emitReloadMenuItems = () => {
  appEmitter.emit(RELOAD_MENU_ITEMS_EMITTER, true)
}

export const configureMitt = (app) => {
  if (!appEmitter) {
    app.config.globalProperties.emitter = mitt()
    appEmitter = app.config.globalProperties.emitter
  }
}

export const useEmitter = () => {
  return {
    onReloadMenuItems,
    emitReloadMenuItems
  }
}