import { usePermissionService } from '@/service/PermissionService';

const permissionService = usePermissionService();

/**
 * Only managers can create employees.
 */
const canCreateEmployee = () => {
  return permissionService.isAuthorizedManager();
};

export const useEmployeePermissions = () => {
  return {
    canCreateEmployee
  };
};