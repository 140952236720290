<template>
  <PageLayout>
    <TabView>
      <TabPanel class="ui-tabs-left" header="Основни податоци">
        <EmployeeBasicInformation />
      </TabPanel>
      <TabPanel class="ui-tabs-left" header="Профил">
        <EmployeeAccountCreation />
      </TabPanel>
    </TabView>
  </PageLayout>
</template>

<script>
  import EmployeeAccountCreation from '@/screens/employee/details/account/EmployeeAccountCreation';
  import EmployeeBasicInformation from '@/screens/employee/details/basic-information/EmployeeBasicInformation';
  import PageLayout from '@/shared/layout/PageLayout';


  export default {
    name: 'EmployeeDetailsPage',
    components: {PageLayout, EmployeeBasicInformation, EmployeeAccountCreation}
  }
</script>

<style scoped>

</style>