<template>
  <TitledPage
      title="Додавање нова компанија"
      :on-back="onBack"
  >
    <LoadingSpinner v-if="loading.main" />

    <div v-else class="row">
      <div class="col-sm-12">
        <div class="card-form-box">
          <form autocomplete="off">
            <Input
                alpha-only
                id="name"
                type="text"
                label="Име на компанијата"
                v-model="company.name"
            />
            <Input
                alphanumeric-only
                id="edb"
                type="text"
                label="Единствен Даночен Број (ЕДБ)"
                v-model="company.edb"
            />
            <Input
                id="address"
                type="text"
                label="Адреса"
                v-model="company.address"
            />
            <div class="user-box" style="text-align: left;">
              <div class="row row-eq-height align-items-center d-flex align-items-stretch">
                <div class="col-sm-6 p-0">
                  <SelectInput
                      id="idCity"
                      label="Град"
                      :data="cities"
                      v-model="company.idCity"
                  />
                </div>
                <div class="col-sm-6" style="padding-top: 1.2rem!important;">
                  <span>Р. Северна Македонија</span>
                </div>
              </div>
            </div>
            <MaskedInput
                id="phoneNumber"
                label="Телефонски број"
                v-model="company.phoneNumber"
                mask="+389 7# ### ###"
            />
            <MainButton
                :loading="loading.save"
                text="Зачувај"
                :on-click="saveCompany"
            />
          </form>
        </div>
      </div>
    </div>
  </TitledPage>
</template>

<script>
  import LoadingSpinner from '@/screens/partial/LoadingSpinner';
  import {Company} from '@/models/entity/Company';
  import {City} from '@/models/entity/City';
  import axios from 'axios';
  import Response from '@/models/backend/Response';
  import {useNotificationService} from '@/service/NotificationService';
  import {useFormFieldService} from '@/service/FormFieldService';
  import {useEntityParser} from '@/utils/EntityParser';
  import MainButton from '@/screens/partial/MainButton';
  import router, {resolveBaseRoute} from '@/router';
  import TitledPage from '@/shared/layout/TitledPage';
  import Input from "@/shared/input/Input";
  import MaskedInput from "@/shared/input/MaskedInput";
  import SelectInput from "@/shared/input/SelectInput";

  const notificationService = useNotificationService()
  const formFieldService = useFormFieldService()
  const entityParser = useEntityParser()

  const stateMutations = {
    /** @param {boolean} value **/
    setLoadingMain: function (value) {
      this.loading.main = value
    },
    /** @param {boolean} value **/
    setLoadingSave: function (value) {
      this.loading.save = value
    },
    /** @param {Array<City>} data **/
    setCities: function (data) {
      this.cities = data;
    }
  }

  export default {
    name: 'CompanyCreatePage',
    components: {SelectInput, MaskedInput, Input, TitledPage, MainButton, LoadingSpinner},
    data() {
      return {
        loading: {
          /** @type {boolean} **/
          main: true,
          /** @type {boolean} **/
          save: false
        },
        /** @type {Company} **/
        company: {
          name: undefined,
          edb: undefined,
          address: undefined,
          phoneNumber: undefined,
          idCity: undefined
        },
        /** @type {Array<City>} **/
        cities: undefined
      }
    },
    mounted() {
      this.fetchCities()
    },
    methods: {
      fetchCities() {
        axios.get('city').then(it => {
          const response = new Response(it);

          const cities = entityParser.toCities(response.data);

          this.setCities(cities);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally(() => this.setLoadingMain(false))
      },
      saveCompany() {
        this.setLoadingSave(true);

        formFieldService.resetInputErrors();

        axios.post('company', this.company).then(it => {
          const response = new Response(it);

          const company = new Company(response.data);

          router.push({
            name: resolveBaseRoute().nested.Companies.nested.Company.name,
            params: {
              id: company.id,
              infoMessage: response.message
            }
          });

        }).catch(it => {
          const response = new Response(it.response);

          if (response.hasErrors()) {
            formFieldService.populateErrorFields(response.errors);
          }

          // Do not show error if the validation violation happens. Errors will show up
          // under every input field.
          if (!response.isEntityNotValidError()) {
            notificationService.showInfoModal(response.message)
          }
        }).finally(() => this.setLoadingSave(false));
      },
      onBack() {
        router.push({
          name: resolveBaseRoute().nested.Companies.name
        })
      },
      ...stateMutations
    }
  }
</script>

<style scoped>

</style>