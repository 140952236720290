<template>
  <ConfirmationDialog
      id="changeStatusInvoice"
      :text="getLabel('label.confirmation.change_invoice_status')"
      :showing="showing.changeStatusConfirm"
      :on-success="doChangeToNextStatus"
      :on-cancel="() => {
        setShowingChangeStatusConfirm(false);
        flowable.nextChosenStatusId = undefined;
        flowable.revertDeliveryProgressButtonLoading();
      }"
  ></ConfirmationDialog>
  <SignaturePadDialog
      text="Потпис на купувачот"
      :showing="showing.signaturePadDialog"
      :onSuccess="onBuyerSigned"
      :onCancel="() => {
        setShowingSignaturePadDialog(false);
        flowable.nextChosenStatusId = undefined;
        flowable.revertDeliveryProgressButtonLoading();
      }"
  />

  <LoadingSpinner v-if="loading.main" />

  <div v-if="error" class="row justify-content-center">
    <div class="col-sm-6 alert alert-danger">
      {{ error }}
    </div>
  </div>
  <PageLayout v-if="invoice" class="bootstrap snippets">
    <ConfirmationDialog
        id="confirmPayCargo"
        :text="getPayCargoConfirmationText()"
        :price="invoice.cargoPrice"
        :showing="showing.paymentCargoConfirm"
        :on-success="() => doPayForInvoice(true)"
        :on-cancel="() => {
            this.setLoadingPaymentCargo(false);
            this.setShowingPaymentCargoConfirm(false);
          }"
    ></ConfirmationDialog>
    <ConfirmationDialog
        id="confirmPayPackage"
        :text="getLabel('label.confirmation.receive_package_payment')"
        :price="totalInvoicePrice"
        :showing="showing.paymentPackageConfirm"
        :on-success="() => doPayForInvoice(false)"
        :on-cancel="() => {
            this.setLoadingPaymentPackage(false);
            this.setShowingPaymentPackageConfirm(false);
          }"
    ></ConfirmationDialog>

    <div class="row">
      <div class="col-sm-12">
        <div class="panel panel-default invoice" id="invoice">
          <div class="panel-body">

            <div v-if="deletableInvoice">
              <div class="row align-items-center">

                <div class="col-sm-12">
                  <p class="alert alert-danger" style="font-size: 12px; font-weight: 1000;">
                    {{ `* ${getLabel('label.notification.check_invoice_details')}` }}
                  </p>
                </div>

                <br /><br /><br />

                <div class="col-sm-12">
                  <ConfirmationDialog
                      id="confirmInvoiceRemoval"
                      :text="getLabel('label.confirmation.delete_invoice')"
                      :showing="showing.deleteConfirm"
                      :on-success="doRemoveInvoice"
                      :on-cancel="() => {
                          this.setShowingDeleteConfirm(false);
                          this.setLoadingDelete(false);
                        }"
                  />
                  <MainButton
                      :loading="loading.delete"
                      :text="getLabel('label.invoice.title.delete_invoice')"
                      :on-click="removeInvoice"
                      small-btn
                  />
                </div>

              </div>

              <hr />
            </div>

            <div class="row align-items-center">

              <div class="col-sm-4 top">
                <p>
                  {{ getLabel('label.mycargo') }}
                  <br />
                  {{ getLabel('label.city.skopje') }}, {{ getLabel('label.country.north_macedonia') }}
                </p>
              </div>
              <div class="col-sm-4">
                <!-- TODO aboykov Change the value url!!! -->
                <qrcode-vue v-if="uin" style="border: 2px solid black; border-radius: 10px; padding: 10px;"
                            :value="getQrCodeLinkToThisInvoice()"
                            :size="150"
                            :background="'transparent'"
                            level="H"
                />
              </div>
              <div class="col-sm-4">
                <p class="marginright">
                  {{ getLabel('label.invoice.title.invoice_number') }}
                  <br />
                  <strong>{{ uin }}</strong>
                </p>
              </div>

            </div>

            <div class="row align-items-center">
              <div class="col-sm-12">
                <p style="font-size: 0.8em; font-weight: 1000; margin-top: 10px;">
                  {{ `* ${getLabel('label.notification.prices_are_in_currency', getLabel('label.currency.mkd'))}` }}
                </p>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-sm-2">
                <img id="main-stamp" style="transform: rotate(-10deg);" :src="require('@/assets/imgs/stamp.png')"
                     alt="x"
                />
              </div>
              <div class="col-sm-9" style="text-align: left;">
                <span><strong></strong></span><span>{{ this.invoice.creation }}</span>
                <div class="row top">
                  <div class="col-sm-12 p-0">
                    <i class="fa fa-truck"/>
                    {{ getLabel('label.title.from') }}
                    <u v-if="!invoice.fromMcOffice">
                      {{ getLabel('label.invoice.title.delivery_home').toLocaleLowerCase() }}
                    </u>
                    <u v-else>
                      {{ getLabel('label.invoice.title.delivery_mycargo') }}
                    </u>, {{ getLabel('label.title.to').toLocaleLowerCase() }}
                    <u v-if="!invoice.toMcOffice">
                      {{ getLabel('label.invoice.title.delivery_home').toLocaleLowerCase() }}
                    </u>
                    <u v-else>
                      {{ getLabel('label.invoice.title.delivery_mycargo') }}
                    </u>
                  </div>
                </div>

              </div>
            </div>

            <hr>

            <div class="row">

              <div class="col-sm-6" style="text-align: left">
                <p class="lead marginbottom">
                  {{ `${getLabel('label.invoice.title.send_as')}:` }}
                </p>
                <hr style="padding: 0; margin: 2px 0 4px 0;"/>

                <div v-if="!invoice.seller.company">
                  <p><strong>{{ getLabel('label.title.natural_person').toUpperCase() }}</strong></p>
                  <p>{{ `${invoice.seller.name} ${invoice.seller.surname}` }}</p>
                  <p>{{ `${invoice.seller.city.name}, ${getLabel('label.country.north_macedonia')}` }}</p>
                  <p>{{ invoice.seller.address }}</p>
                  <p>{{ invoice.seller.phoneNumber }}</p>
                </div>
                <div v-else>
                  <p><strong>{{ getLabel('label.title.legal_person').toUpperCase() }}</strong></p>
                  <p>{{ invoice.seller.company.name }}</p>
                  <p>{{ `${invoice.seller.company.city.name}, ${getLabel('label.country.north_macedonia')}` }}</p>
                  <p>{{ invoice.seller.company.address }}</p>
                  <p>{{ invoice.seller.company.phoneNumber }}</p>
                </div>

              </div>
              <div class="col-sm-6" style="text-align: left">
                <p class="lead marginbottom">
                  {{ `${getLabel('label.invoice.title.buy_as')}:` }}
                </p>
                <hr style="padding: 0; margin: 2px 0 4px 0;"/>
                <p>{{ invoice.buyerNameSurname }}</p>
                <p>{{ invoice.buyerAddress }}</p>
                <p>{{ `${invoice.buyerCity.name}, ${getLabel('label.country.north_macedonia')}` }}</p>
                <p>{{ invoice.buyerPhoneNumber }}</p>

              </div>

            </div>

            <div class="row table-row">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th style="width:50%">{{ getLabel('label.invoice.title.product') }}</th>
                  <th class="text-right" style="width:15%">{{ getLabel('label.invoice.title.quantity') }}</th>
                  <th class="text-right" style="width:15%">{{ getLabel('label.invoice.title.single_price') }}</th>
                  <th class="text-right" style="width:15%">{{ getLabel('label.invoice.title.total') }}</th>
                </tr>
                </thead>
                <tbody>
                  <tr
                      v-for="(item, i) in invoice.packages"
                      :key="item.name"
                      :class="i === invoice.packages.length-1 ? 'last-row':''"
                  >
                    <td>{{ item.name }}</td>
                    <td class="text-right">{{ item.quantity }}</td>
                    <td class="text-right">{{ item.singleItemPrice }}</td>
                    <td class="text-right">{{ item.singleItemPrice * item.quantity }}</td>
                  </tr>
                </tbody>
              </table>

            </div>

            <div class="row">
              <div class="col-sm-12 text-right pull-right invoice-total">
                <p>{{ getLabel('label.invoice.title.total') }}: <span
                    style="display: inline-block; width: 50px;">{{ totalInvoicePrice }}</span></p>
                <p>{{ getLabel('label.invoice.title.cargo_fee_with_tax_included') }}: <span
                    style="display: inline-block; width: 50px;">{{
                    invoice.cargoPrice
                  }}</span></p>
                <p>{{ getLabel('label.invoice.title.discount') }}: <span
                    style="display: inline-block; width: 50px;">{{ invoice.discount }}</span></p>
                <hr/>
                <p><strong>{{ getLabel('label.invoice.title.total').toUpperCase() }}: </strong><span
                    style="display: inline-block; width: 50px;"
                >{{ totalInvoicePrice + invoice.cargoPrice }}</span>
                </p>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col-sm-10" style="text-align: left">
                {{ `${getLabel('label.invoice.title.invoice_information')}:` }}
                <br /><br />
                <textarea readonly class="form-control" style="color: black;"
                          v-model="this.invoice.description"
                ></textarea>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col-sm-8" style="text-align: left">
                <p>
                  {{ `${getLabel('label.invoice.title.paid_services')}:` }}
                </p>
                <hr style="padding: 0; margin: 2px 0 4px 0;"/>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="chkboxForInvoice"
                         :checked="invoice.paymentPackage" onclick="return false;"
                  >
                  <label class="form-check-label" for="chkboxForInvoice">
                    {{ getLabel('label.invoice.title.paid_for_products') }}
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="chkboxForCargo"
                         :checked="invoice.paymentCargo" onclick="return false;"
                  >
                  <label class="form-check-label" for="chkboxForCargo">
                    {{ getLabel('label.invoice.title.paid_for_cargo_fee') }}
                  </label>
                </div>
                <br v-if="!invoice.paymentCargo || !invoice.paymentPackage" />
                <span
                    v-if="invoicePermissions.canUpdateInvoicePaymentCargo(invoice.sellerPaysCargo) && !invoice.paymentCargo"
                >
                  <MainButton
                      :loading="loading.paymentCargo"
                      :text="getPayCargoButtonText()"
                      :on-click="() => payForInvoice(true)"
                      small-btn
                  />
                  <span style="margin-right: 10px;" />
                </span>
                <span
                    v-if="invoicePermissions.canUpdateInvoicePaymentPackage() && !invoice.paymentPackage"
                >
                  <MainButton
                      :loading="loading.paymentPackage"
                      :text="getLabel('label.button.receive_payment_for_products')"
                      :on-click="() => payForInvoice(false)"
                      small-btn
                  />
                </span>
                <div v-if="invoicePermissions.canAssignInvoice()">
                  <br />
                  <div v-if="invoice.assignedEmployeeFullName">
                    Одговорен вработен за достава:
                    <br />
                    {{ invoice.assignedEmployeeFullName }}
                  </div>
                  <div v-else>
                    <!-- TODO aboykov Use custom dropdown. -->
                    <select class="form-control form-control-sm" v-model="flowable.selectedEmployeeId"
                            style="display: inline; width: 65%; margin-right: 10px;"
                    >
                      <option :value="undefined" :key="0" disabled>
                        - Одберете вработен -
                      </option>
                      <option v-for="emp in employees"
                              :value="emp.id"
                              :key="emp.id"
                      >
                        {{ `${emp.surname} ${emp.name} (${emp.email})` }}
                      </option>
                    </select>
                    <MainButton
                        v-if="flowable.selectedEmployeeId"
                        :loading="loading.assignInvoice"
                        text="Додели на вработен"
                        :on-click="() => assignInvoice()"
                        small-btn
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-4" style="text-align: left">
                <p>{{ `${getLabel('label.invoice.title.who_pays_cargo_fee')}:` }}</p>
                <hr style="padding: 0; margin: 2px 0 4px 0;" />
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="flexRadioDefault1"
                         :checked="invoice.sellerPaysCargo" onclick="return false;"
                  >
                  <label class="form-check-label" for="flexRadioDefault1">
                    {{ getLabel('label.invoice.title.seller_pays_cargo_fee') }}
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="flexRadioDefault2"
                         :checked="!invoice.sellerPaysCargo" onclick="return false;"
                  >
                  <label class="form-check-label" for="flexRadioDefault2">
                    {{ getLabel('label.invoice.title.buyer_pays_cargo_fee') }}
                  </label>
                </div>
              </div>
            </div>

            <hr />

            <div class="row" style="text-align: left">
              <div class="col-sm-12">
                {{ `${getLabel('label.invoice.title.status')}:` }}
                <DeliveryProgress
                    :statuses="invoice.statuses"
                    :nextStatuses="nextStatuses"
                    :onNextStatusClick="changeToNextStatus"
                    :special="invoice.toMcOffice"
                />
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col-sm-3 align-self-bottom" style="text-align: left;">
                <!--                  <img :src="require('@/assets/signature-example.png')" style="width: 100%;"/>-->
                <img :src="invoice.sellerSignature" style="width: 100%;" alt="x"/>
                <hr style="padding: 0; margin: 4px 0 2px 0;"/>
                <p>
                  {{ getLabel('label.invoice.title.seller_signature') }}
                </p>
              </div>
              <div v-if="invoice.buyerSignature" class="col-sm-3 align-self-bottom"
                   style="text-align: left;"
              >
                <img :src="invoice.buyerSignature" style="width: 100%;" alt="x"/>
                <hr style="padding: 0; margin: 4px 0 2px 0;"/>
                <p>
                  {{ getLabel('label.invoice.title.buyer_signature') }}
                </p>
              </div>
              <div class="col-sm-6" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageLayout>
</template>

<script>
  import { useDateTimeUtils } from '@/utils/DateTimeUtils';
  import QrcodeVue from 'qrcode.vue'
  import DeliveryProgress from '../../partial/DeliveryProgress';
  import axios from 'axios';
  import Response from '../../../models/backend/Response';
  import router, { ROUTES } from '../../../router';
  import { useNotificationService } from '@/service/NotificationService';
  import MainButton from '../../partial/MainButton';
  import ConfirmationDialog from '../../partial/ConfirmationDialog';
  import SignaturePadDialog from '../../partial/SignaturePadDialog';
  import { useStatusService } from '@/service/backend/StatusService';
  import { useRoute } from 'vue-router';
  import { Invoice } from '@/models/entity/Invoice';
  import { Status } from '@/models/entity/Status';
  import { Employee } from '@/models/entity/Employee';
  import { useInvoicePermissions } from '@/service/permission/InvoicePermissions';
  import { useEntityParser } from '@/utils/EntityParser';
  import LoadingSpinner from '@/screens/partial/LoadingSpinner';
  import PageLayout from '@/shared/layout/PageLayout';
  import {useLocalStorageUtils} from '@/utils/LocalStorageUtils';
  import {usePermissionService} from "@/service/PermissionService";
  import {useMessageSource} from "@/config/MessageSource";

  const notificationService = useNotificationService();
  const statusService = useStatusService();
  const dateTimeUtils = useDateTimeUtils();
  const permissionService = usePermissionService();
  const entityParser = useEntityParser();
  const invoicePermissions = useInvoicePermissions()
  const {getLabel} = useMessageSource()

  const stateMutations = {
    /** @param {boolean} value **/
    setLoadingMain: function (value) {
      this.loading.main = value
    },
    /** @param {boolean} value **/
    setLoadingDelete: function (value) {
      this.loading.delete = value
    },
    /** @param {boolean} value **/
    setLoadingPaymentCargo: function (value) {
      this.loading.paymentCargo = value
    },
    /** @param {boolean} value **/
    setLoadingPaymentPackage: function (value) {
      this.loading.paymentPackage = value
    },
    /** @param {boolean} value **/
    setLoadingAssignInvoice: function (value) {
      this.loading.assignInvoice = value
    },
    /** @param {boolean} value **/
    setShowingDeleteConfirm: function (value) {
      this.showing.deleteConfirm = value;
    },
    /** @param {boolean} value **/
    setShowingPaymentCargoConfirm: function (value) {
      this.showing.paymentCargoConfirm = value;
    },
    /** @param {boolean} value **/
    setShowingPaymentPackageConfirm: function (value) {
      this.showing.paymentPackageConfirm = value;
    },
    /** @param {boolean} value **/
    setShowingChangeStatusConfirm: function (value) {
      this.showing.changeStatusConfirm = value;
    },
    /** @param {boolean} value **/
    setShowingSignaturePadDialog: function (value) {
      this.showing.signaturePadDialog = value;
    },
    /** @param {number} value **/
    setFlowableNextChosenStatusId: function (value) {
      this.flowable.nextChosenStatusId = value;
    },
    /** @param {function} value **/
    setFlowableRevertDeliveryProgressButtonLoading: function (value) {
      this.flowable.revertDeliveryProgressButtonLoading = value;
    },
    /** @param {string} value **/
    setFlowableBuyerSignature: function (value) {
      this.flowable.buyerSignature = value;
    },
    /** @param {number} value **/
    setFlowableSelectedEmployeeId: function (value) {
      this.flowable.selectedEmployeeId = value;
    },
    /** @param {string} value **/
    setError: function (value) {
      this.error = value;
    },
    /** @param {string} value **/
    setUin: function (value) {
      this.uin = value;
    },
    /** @param {Invoice} value **/
    setInvoice: function (value) {
      this.invoice = value;
    },
    /** @param {Status} value **/
    setNextStatuses: function (value) {
      this.nextStatuses = value;
    },
    /** @param {Array<Employee>} value **/
    setEmployees: function (value) {
      this.employees = value;
    },
    /** @param {number} value **/
    setTotalInvoicePrice: function (value) {
      this.totalInvoicePrice = value;
    }
  }

  export default {
    name: 'InvoiceDetailsPage',
    components: {
      PageLayout,
      LoadingSpinner,
      SignaturePadDialog,
      ConfirmationDialog,
      MainButton,
      DeliveryProgress,
      QrcodeVue
    },
    data() {
      return {
        loading: {
          /** @param {boolean} value **/
          main: true,
          /** @param {boolean} value **/
          delete: false,
          /** @param {boolean} value **/
          paymentCargo: false,
          /** @param {boolean} value **/
          paymentPackage: false,
          /** @param {boolean} value **/
          assignInvoice: false
        },
        showing: {
          /** @param {boolean} value **/
          deleteConfirm: false,
          /** @param {boolean} value **/
          paymentCargoConfirm: false,
          /** @param {boolean} value **/
          paymentPackageConfirm: false,
          /** @param {boolean} value **/
          changeStatusConfirm: false,
          /** @param {boolean} value **/
          signaturePadDialog: false
        },
        flowable: {
          /** @param {number} value **/
          nextChosenStatusId: undefined,
          /** @param {function} value **/
          revertDeliveryProgressButtonLoading: undefined,
          /** @param {string} value **/
          buyerSignature: undefined,
          /** @param {number} value **/
          selectedEmployeeId: undefined
        },
        /** @param {string} value **/
        error: undefined,
        /** @param {string} value **/
        uin: undefined,
        /** @param {Invoice} value **/
        invoice: undefined,
        /** @param {Status} value **/
        nextStatuses: undefined,
        /** @param {Array<Employee>} value **/
        employees: undefined,
        /** @param {number} value **/
        totalInvoicePrice: 0
      }
    },
    mounted() {
      const {uin, infoMessage} = useRoute().params

      this.setUin(uin);

      notificationService.maybeShowInfoModal(infoMessage);

      this.getData();
    },
    methods: {
      getLabel,
      getData() {
        axios.get(`invoice?uin=${this.uin}`).then(it => {
          let response = new Response(it);

          this.setInvoice(new Invoice(response.data));

          this.setLoadingMain(false);

          this.setTotalInvoicePrice(0)
          this.invoice.packages.forEach(item => {
            this.totalInvoicePrice += item.singleItemPrice * item.quantity;
          })

          // Change the invoice so that the datetime will be shown in a different format.
          this.invoice.creation = dateTimeUtils.toLocalDateTime(this.invoice.creation);

          if (this.invoicePermissions.canUpdateInvoiceStatus()) {
            this.fetchAvailableNextInvoiceStatuses();
          }

          if (this.invoicePermissions.canAssignInvoice()) {
            this.fetchEmployees();
          }
        }).catch(it => {
          let response = new Response(it.response);

          this.setError(response.message);
          this.setLoadingMain(false);

          notificationService.showInfoModal(response.message);
        }).finally()
      },
      fetchAvailableNextInvoiceStatuses() {
        axios.get(`flowable/nextInvoiceStatuses/${this.invoice.id}`).then(it => {
          const response = new Response(it);

          this.setNextStatuses(response.data);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally();
      },
      fetchEmployees() {
        // TODO aboykov Maybe add some kind of spinner while fetching the employees? Or main spinner? Or not?
        axios.get('employees?ready=true').then(it => {
          const response = new Response(it);

          const employees = entityParser.toEmployees(response.data);

          this.setEmployees(employees);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally();
      },
      removeInvoice() {
        this.setLoadingDelete(true);
        this.setShowingDeleteConfirm(true);
      },
      doRemoveInvoice() {
        this.setShowingDeleteConfirm(false);

        axios.delete(`invoice?uin=${this.uin}`).then(it => {
          let response = new Response(it);

          router.push({name: ROUTES.Seller.nested.Invoices.name, params: {infoMessage: response.message}})
        }).catch(it => {
          let response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally(() => this.setLoadingDelete(false));
      },
      payForInvoice(cargo) {
        if (cargo) {
          this.setLoadingPaymentCargo(true);
          this.setShowingPaymentCargoConfirm(true);
        } else {
          this.setLoadingPaymentPackage(true);
          this.setShowingPaymentPackageConfirm(true);
        }
      },
      proceedToPaymentCheckout() {
        const localStorageUtils = useLocalStorageUtils()

        // TODO aboykov Maybe add this complex stuff later.
        // if (localStorageUtils.getPaymentSessionId() && localStorageUtils.getPaymentSessionUrl()) {
        //   router.push({
        //     name: ROUTES.Home.nested.PaymentVerification.name
        //   })
        //   return;
        // }

        axios.post(`createCheckoutSession?uin=${this.uin}`).then(it => {
          let response = new Response(it);

          const url = response.data.url;
          const sessionId = response.data.sessionId;

          localStorageUtils.setPaymentSessionId(sessionId);
          localStorageUtils.setPaymentSessionUrl(url);
          window.location.href = url;
        }).catch(it => {
          let response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally(() => {
          this.setLoadingPaymentCargo(false)
        });
      },
      doPayForInvoice(cargo) {
        const isSeller = permissionService.isAuthorizedSeller();

        this.setShowingPaymentCargoConfirm(false);
        this.setShowingPaymentPackageConfirm(false);

        if (cargo && invoicePermissions.canUpdateInvoicePaymentCargo(this.invoice.sellerPaysCargo) && isSeller) {
          this.proceedToPaymentCheckout()
        } else {
          axios.post(`invoice/payment?invoiceId=${this.invoice.id}&cargo=${cargo}`).then(it => {
            let response = new Response(it);

            this.getData();

            notificationService.showInfoModal(response.message)
          }).catch(it => {
            let response = new Response(it.response);

            notificationService.showInfoModal(response.message);
          }).finally(() => {
            if (cargo) {
              this.setLoadingPaymentCargo(false)
            } else {
              this.setLoadingPaymentPackage(false)
            }
          });
        }
      },
      changeToNextStatus(statusId, callback) {
        this.setShowingChangeStatusConfirm(true);

        this.setFlowableNextChosenStatusId(statusId);
        this.setFlowableRevertDeliveryProgressButtonLoading(callback)
      },
      doChangeToNextStatus() {
        this.setShowingChangeStatusConfirm(false);
        this.setShowingSignaturePadDialog(false);

        const body = {
          invoiceId: this.invoice.id,
          statusId: this.flowable.nextChosenStatusId
        }

        if (statusService.isBuyerSignatureRequired(body.statusId)) {
          if (!this.flowable.buyerSignature) {
            this.setShowingSignaturePadDialog(true);
            return;
          } else {
            body['buyersSignature'] = this.flowable.buyerSignature;
          }
        }

        axios.post('invoice/status', body).then(it => {
          const response = new Response(it);

          this.getData();

          notificationService.showInfoModal(response.message);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally(() => {
          this.flowable.revertDeliveryProgressButtonLoading();

          this.setFlowableBuyerSignature(undefined);
          this.setFlowableNextChosenStatusId(undefined);
        });
      },
      onBuyerSigned(signatureBase64) {
        this.setShowingSignaturePadDialog(false);

        this.setFlowableBuyerSignature(signatureBase64);

        this.doChangeToNextStatus();
      },
      assignInvoice() {
        this.setLoadingAssignInvoice(true);

        const body = {
          idInvoice: this.invoice.id,
          idEmployee: this.flowable.selectedEmployeeId
        }

        axios.post('invoiceAssignment/assign', body).then(it => {
          const response = new Response(it);

          this.setFlowableSelectedEmployeeId(undefined);

          this.getData();

          notificationService.showInfoModal(response.message);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally(() => {
          this.setLoadingAssignInvoice(false);
        });
      },
      getQrCodeLinkToThisInvoice() {
        return window.location.href;
      },
      getPayCargoConfirmationText() {
        if (permissionService.isAuthorizedSeller()) {
          return getLabel('label.confirmation.pay_cargo_fee')
        } else {
          return getLabel('label.confirmation.receive_cargo_payment')
        }
      },
      getPayCargoButtonText() {
        if (permissionService.isAuthorizedSeller()) {
          return getLabel('label.button.pay_cargo_fee')
        } else {
          return getLabel('label.button.receive_payment_for_cargo_fee')
        }
      },
      ...stateMutations
    },
    computed: {
      deletableInvoice() {
        return this.invoicePermissions.canDeleteInvoice() && this.invoice.deletable;
      },
      /** ------------------------------ Permissions ------------------------------ **/
      invoicePermissions() {
        return useInvoicePermissions()
      }
    }
  }
</script>

<style scoped src="../../../assets/css/partial/invoice-css.css" />