import { InvoiceStatus } from '@/models/entity/InvoiceStatus';

export const STATUS = {
  COLLECTION_OF_PACKAGE: 'COLLECTION_OF_PACKAGE',
  TRANSIT: 'TRANSIT',
  DELIVERY_SUCCESSFUL: 'DELIVERY_SUCCESSFUL',
  DELIVERY_UNSUCCESSFUL: 'DELIVERY_UNSUCCESSFUL',
  ARRIVED_IN_OFFICE: 'ARRIVED_IN_OFFICE',
  PICKUP_SUCCESSFUL: 'PICKUP_SUCCESSFUL',
  PICKUP_UNSUCCESSFUL: 'PICKUP_UNSUCCESSFUL',
  PACKAGE_RETURNED: 'PACKAGE_RETURNED'
}

/**
 * Returns the status as string by id, from the database (fixed ids, not changeable).
 *
 * @param id    The id of the status.
 */
const getStatusById = (id) => {
  switch (id) {
    case(1):
      return STATUS.COLLECTION_OF_PACKAGE
    case(2):
      return STATUS.TRANSIT
    case(3):
      return STATUS.DELIVERY_SUCCESSFUL
    case(4):
      return STATUS.DELIVERY_UNSUCCESSFUL
    case(5):
      return STATUS.ARRIVED_IN_OFFICE
    case(6):
      return STATUS.PICKUP_SUCCESSFUL
    case(7):
      return STATUS.PICKUP_UNSUCCESSFUL
    case(8):
      return STATUS.PACKAGE_RETURNED
    default:
      throw Error(`Status with id ${id} does not exist.`);
  }
}

/**
 * True if buyer's signature is needed for status change, otherwise false.
 *
 * @param id    The id of the status.
 */
const isBuyerSignatureRequired = (id) => {
  const newStatus = getStatusById(id);

  return (
    newStatus === STATUS.DELIVERY_SUCCESSFUL ||
    newStatus === STATUS.PICKUP_SUCCESSFUL ||
    newStatus === STATUS.PACKAGE_RETURNED
  );
}

/**
 * Returns true if there are not any next statuses available for the invoice.
 *
 * @param {InvoiceStatus} status    The invoice status.
 * @returns {boolean}               The return boolean value.
 */
const isStatusDone = (status) => {
  if (!status) {
    return false;
  }

  return (
    status.status.name === STATUS.DELIVERY_SUCCESSFUL ||
    status.status.name === STATUS.PICKUP_SUCCESSFUL ||
    status.status.name === STATUS.PACKAGE_RETURNED
  );
}

export const useStatusService = () => {
  return {
    getStatusById,
    isBuyerSignatureRequired,
    isStatusDone
  }
}