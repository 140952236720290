/**
 * Global mapped error codes - 6xx.
 */
const codes6xx = {
  ENTITY_NOT_REMOVABLE: 601,
  ENTITY_EXISTS_DUPLICATE: 602,
  ENTITY_NOT_FOUND: 603,
  ENTITY_NOT_VALID: 604,
  CREDENTIALS_NOT_VALID: 605,
  UNAUTHORIZED: 606,
  EXPIRED_AUTH_TOKEN: 607
}

/**
 * Email notification error codes - 7xx.
 */
const codes7xx = {
  EMAIL_NOTIFICATION_GLOBAL_CODE: 700
}

/**
 * Stripe payment error codes - 8xx.
 */
const codes8xx = {
  STRIPE_PAYMENT_GLOBAL_CODE: 800,
  SESSION_NOT_CREATED: 801,
  SESSION_NOT_EXISTENT: 802,
  SESSION_NOT_FINALIZED: 803,
  SESSION_EXPIRED: 804,
  ALREADY_PAID: 805
}

/**
 * Invoice error codes = 9xx.
 */
const codes9xx = {
  INVOICE_STATUS_CANNOT_BE_NEXT: 901
}

const codes = {
  OK: 200,
  UNKNOWN: 999,
  ...codes6xx,
  ...codes7xx,
  ...codes8xx,
  ...codes9xx
}

export const useResponseCodes = () => {
  return {
    ...codes
  }
}