<template>
  <div v-if="rows" class="row justify-content-center">
    <div class="col-sm-12">
      <router-link v-if="invoicePermissions.canCreateInvoice()" :to="pathToInvoiceNew">
        <MainButton
            style="float: right;"
            :text="getLabel('label.title.new_invoice')"
        >
          <template #icon>
            <i class="fa fa-plus-circle" />
          </template>
        </MainButton>
        <br /><br /><br />
      </router-link>
      <DataTable v-if="rows.length" :value="rows"
                 auto-layout
                 :sort-field="defaultSort" :sortOrder="-1" removableSort
                 paginator :rows="10" :rowsPerPageOptions="[10,20,50]"
                 :currentPageReportTemplate="getLabel('label.invoice.title.invoice_list_count', '{totalRecords}')"
                 paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport"
      >
        <Column v-for="col of columns" :field="col.field" :sort-field="col.sortableField"
                :header="col.header" :key="col.field" :sortable="col.sortable"
        >
          <template v-if="col.field === 'finished'" #body="slotProps">
            <i v-if="slotProps.data.finished" class="fa fa-check" style="color: green;" />
            <i v-else class="fa fa-times" style="color: red;" />
          </template>
        </Column>
        <Column :exportable="false">
          <template #body="slotProps">
            <router-link :to="pathToInvoiceNavigation(slotProps.data.uin)">
              <MainButton
                  small-btn
                  light-layer
                  :text="getLabel('label.button.access_invoice')"
              />
            </router-link>
          </template>
        </Column>
      </DataTable>
      <div v-else class="alert alert-danger">{{ emptyGridMessage }}</div>
    </div>
  </div>
</template>

<script>
import {useInvoicePermissions} from '@/service/permission/InvoicePermissions';
import MainButton from '@/screens/partial/MainButton';
import {useMessageSource} from "@/config/MessageSource";

const {getLabel} = useMessageSource()

export default {
  name: 'InvoicesGrid',
  components: {MainButton},
  props: {
    pathToInvoiceNew: {
      required: false,
      type: String
    },
    pathToInvoiceNavigation: {
        required: true,
        type: Function
      },
      rows: {
        required: false,
        type: Array(Object)
      },
    columns: {
      required: true,
      type: Array(Object)
    },
    emptyGridMessage: {
      required: true,
      type: String
    }
  },
  methods: {
    getLabel
  },
  computed: {
    defaultSort() {
      const sortDefault = this.columns.filter(col => col.defaultSortable)[0];

      if (sortDefault) {
        return sortDefault.sortableField ? sortDefault.sortableField : sortDefault.field;
      }
      return undefined;
    },
    /** ------------------------------ Permissions ------------------------------ **/
      invoicePermissions() {
        return useInvoicePermissions()
      }
    }
  }
</script>

<style scoped>

</style>