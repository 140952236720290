import axios from 'axios';
import { useLocalStorageUtils } from '@/utils/LocalStorageUtils';
import router, { ROUTES } from '@/router';
import { useAppConfig } from './AppConfig';
import {useResponseCodes} from '@/models/backend/ResponseCodes';
import {useLocalStore} from "@/utils/store";

const localStorageUtils = useLocalStorageUtils()
const localStore = useLocalStore()

export const configureAxios = () => {
  axios.defaults.baseURL = useAppConfig().backendBaseUrl;

  axios.defaults.params = {
    lang: localStorageUtils.getLanguage()
  }

  axios.interceptors.response.use(function (it) {
    return it;
  }, function (it) {
    // Any status code that is not 2xx
    const response = it.response.data;
    const substatusCode = response.httpCodes.substatusCode

    if (substatusCode === useResponseCodes().UNAUTHORIZED) {
      router.push(ROUTES.Home.path)
    }

    if (substatusCode === useResponseCodes().EXPIRED_AUTH_TOKEN) {
      localStore.localLogOut();
      router.push(ROUTES.Home.path)
    }

    return Promise.reject(it);
  });
}