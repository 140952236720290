<template>
  <PageLayout>
    <div class="row justify-content-md-center">
      <div class="col-md-12">
        <div class="card-form-box">
          <div v-if="!helperCreation" class="alert alert-info" style="text-align: justify;">
            Ве молиме внесете ги вашите информации со цел започнување на користење на
            услугите на „моеКарго“. Со ова го креирате вашиот профил на продавач.
          </div>
          <OptionSwitcher v-if="!helperCreation"
                          option1="Физичко лице"
                          option2="Правно лице"
                          v-on:click="switchSellerTypeOptions"
          >
          </OptionSwitcher>
          <form autocomplete="off" v-if="sellerTypeNaturalPerson">
            <div v-if="helperCreation" class="form-input-box" style="text-align: left;">
              <DisabledInputField
                  label="Корисничко име"
                  :value="person.username"
              />
            </div>
            <Input
                id="name"
                type="text"
                label="Име"
                v-model="person.name"
            />
            <Input
                id="surname"
                type="text"
                label="Презиме"
                v-model="person.surname"
            />
            <Input
                id="address"
                type="text"
                label="Адреса"
                v-model="person.address"
            />
            <div class="user-box" style="text-align: left;">
              <div class="row row-eq-height align-items-center d-flex align-items-stretch">
                <div class="col-sm-6 p-0">
                  <SelectInput
                      id="idCity"
                      label="Град"
                      :data="cities"
                      v-model="person.idCity"
                  />
                </div>
                <div class="col-sm-6" style="padding-top: 1.2rem!important;">
                  <span>Р. Северна Македонија</span>
                </div>
              </div>
            </div>
            <div v-if="helperCreation" class="user-box" style="text-align: left;">
              <SelectInput
                  id="idCompany"
                  label="Компанија"
                  :data="companies"
                  v-model="person.idCompany"
              />
            </div>
            <MaskedInput
                id="phoneNumber"
                v-model="person.phoneNumber"
                label="Телефонски број"
                mask="+389 7# ### ###"
            />
            <MainButton
                :loading="loading"
                text="Зачувај"
                :on-click="saveSellerInfo"
            />
          </form>
          <div v-else class="alert alert-danger" style="text-align: justify;">
            Доколку сакате да го користите вашиот профил во име на правно лице, тогаш потребно е
            вашата корисничка сметка да се поврзе со соодветната компанија. За оваа цел, потребно е да посетите
            една од нашите канцеларии. За повеќе информации, контактирајте нè.
          </div>
        </div>
      </div>
    </div>
  </PageLayout>
</template>

<script>
  import axios from 'axios';
  import Response from '../../models/backend/Response';
  import OptionSwitcher from '../partial/OptionSwitcher';
  import router, { ROUTES } from '../../router';
  import {useNotificationService} from '@/service/NotificationService';
  import {useFormFieldService} from '@/service/FormFieldService';
  import MainButton from '../partial/MainButton';
  import {ROLE} from '@/service/backend/RoleService';
  import {useRoute} from 'vue-router';
  import {useEntityParser} from '@/utils/EntityParser';
  import {City} from '@/models/entity/City';
  import {Company} from '@/models/entity/Company';
  import PageLayout from '@/shared/layout/PageLayout';
  import Input from "@/shared/input/Input";
  import MaskedInput from "@/shared/input/MaskedInput";
  import SelectInput from "@/shared/input/SelectInput";
  import DisabledInputField from "@/screens/partial/DisabledInputField";
  import {useLocalStore} from "@/utils/store";

  const notificationService = useNotificationService()
  const formFieldService = useFormFieldService()
  const entityParser = useEntityParser()
  const localStore = useLocalStore()

  const stateMutations = {
    /** @param {boolean} data **/
    setLoading: function (data) {
      this.loading = data
    },
    /** @param {Array<City>} data **/
    setCities: function (data) {
      this.cities = data;
    },
    /** @param {Array<Company>} data **/
    setCompanies: function (data) {
      this.companies = data
    }
  }

  export default {
    name: 'PersonalInfoRequest',
    components: {DisabledInputField, SelectInput, MaskedInput, Input, PageLayout, OptionSwitcher, MainButton},
    props: {
      helperCreation: {
        type: Boolean,
        required: false,
        default: () => false
      }
    },
    data() {
      const {username} = useRoute().params;

      return {
        /** @type {boolean} data **/
        loading: false,
        person: {
          name: undefined,
          surname: undefined,
          address: undefined,
          phoneNumber: undefined,
          idCity: undefined,
          idCompany: undefined,
          username: username
        },
        /** @type {Array<City>} data **/
        cities: [],
        /** @type {Array<Company>} data **/
        companies: [],
        sellerTypeNaturalPerson: true
      }
    },
    mounted() {
      this.fetchCities()

      if (this.helperCreation) {
        this.fetchCompanies();
      }
    },
    methods: {
      // TODO aboykov Extract this code for fetching cities to another service (because it is used on
      //  multiple places in the context of the whole application.
      fetchCities() {
        axios.get('city').then(it => {
          const response = new Response(it);

          const cities = entityParser.toCities(response.data);

          this.setCities(cities);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally()
      },
      fetchCompanies() {
        axios.get('company?active=true').then(it => {
          const response = new Response(it);

          const companies = entityParser.toCompanies(response.data);

          this.setCompanies(companies);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally()
      },
      saveSellerInfo() {
        this.setLoading(true);

        formFieldService.resetInputErrors();

        const mutationObject = {
          ...this.person,
        }

        axios.post('seller', mutationObject).then(it => {
          const response = new Response(it);

          if (!this.helperCreation) {
            // TODO aboykov Change this, maybe add response data to set this to, and not hardcode.
            localStore.setAuthorizationRole(ROLE.ROLE_SELLER);

            router.push({
              name: ROUTES.Dashboard.name,
              params: {
                infoMessage: response.message
              }
            });
          } else {
            router.push({
              name: ROUTES.Manager.nested.Accounts.name,
              params: {
                infoMessage: response.message
              }
            });
          }
        }).catch(it => {
          const response = new Response(it.response);

          if (response.hasErrors()) {
            formFieldService.populateErrorFields(response.errors);
          }

          // Do not show error if the validation violation happens. Errors will show up
          // under every input field.
          if (!response.isEntityNotValidError()) {
            notificationService.showInfoModal(response.message)
          }
        }).finally(() => this.setLoading(false));
      },
      switchSellerTypeOptions() {
        this.sellerTypeNaturalPerson = !this.sellerTypeNaturalPerson;
      },
      ...stateMutations
    }
  }
</script>

<style scoped>

</style>