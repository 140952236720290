<template>
  <header class="header-area header-sticky">

    <PageLayout>
      <nav class="main-nav">

        <a href="#" class="logo">
          <router-link :to="ROUTES.Home.path">
            <img style="width: 70px" :src="require('@/assets/logo.svg')" alt="myCargo"/>
          </router-link>
        </a>

        <Menubar
            :model="items"
        />

      </nav>

      <div v-if="userRole" class="usr-role">
        <i class="fa fa-user" style="margin-right: 10px;"/>
        {{ userRole }}
      </div>
    </PageLayout>

  </header>
</template>

<script>
import '@/assets/vendor/primevue/resources/themes/lara-light-indigo/theme.css'
import '@/assets/vendor/primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

import {ROUTES} from '@/router';
import Menubar from '@/assets/vendor/primevue/menubar';
import PageLayout from '@/shared/layout/PageLayout';
import {useNavigationMenuResolver} from '@/config/NavigationMenuResolver';
import {useEmitter} from "@/config/EmitterConfig";
import {usePermissionService} from "@/service/PermissionService";
import {useMessageSource} from "@/config/MessageSource";

const permissionService = usePermissionService()

const {getLabel} = useMessageSource()

export default {
  name: 'TopNavigation',
  components: {PageLayout, Menubar},
  data() {
    return {
      ROUTES,
      items: [],
      userRole: undefined
    }
  },
  mounted() {
    this.initMenu();

    this.resolveRole()
  },
  methods: {
    resolveRole() {
      if (permissionService.isAuthenticatedUser()) {
        if (permissionService.isAuthorizedSeller()) {
          this.userRole = getLabel('label.role.seller');
        } else if (permissionService.isAuthorizedEmployee()) {
          this.userRole = getLabel('label.role.employee');
        } else if (permissionService.isAuthorizedManager()) {
          this.userRole = getLabel('label.role.manager');
        } else {
          this.userRole = undefined;
        }
      } else {
        this.userRole = undefined
      }

    },
    initMenu() {
      this.items = useNavigationMenuResolver().resolveMenuItems();
      useEmitter().onReloadMenuItems(() => {
        this.items = useNavigationMenuResolver().resolveMenuItems();

        this.resolveRole();
      });
    }
  }
  }
</script>

<style scoped>
.usr-role {
  background: white;
  color: #495057;
  margin: 5px 10px 10px 10px;
  text-align: left;
  padding: 10px 20px 10px;
  border-radius: 20px;
  font-size: 12px;
  float: right;
}

@media (max-width: 991px) {
  .usr-role {
    display: none;
  }
}
</style>