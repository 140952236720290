<template>
  <div class="switch-button" :option2="option2">
    <input class="switch-button-checkbox" type="checkbox" />
    <label class="switch-button-label">
      <span class="switch-button-label-span">{{ option1 }}</span>
    </label>
  </div>
</template>

<script>
  export default {
    name: 'OptionSwitcher',
    props: {
      option1: String,
      option2: String
    }
  }
</script>

<style scoped>
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: sans-serif;
  }

  .switch-button {
    margin: 50px auto;
    background: rgba(255, 255, 255, 0.56);
    border-radius: 30px;
    overflow: hidden;
    width: 240px;
    text-align: center;
    font-size: 12px;
    letter-spacing: 1px;
    color: black;
    position: relative;
    padding-right: 120px;
  }

  .switch-button:before {
    content: attr(option2);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
  }

  .switch-button-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
  }

  .switch-button-checkbox:checked + .switch-button-label:before {
    transform: translateX(120px);
    transition: transform 300ms linear;
  }

  .switch-button-checkbox + .switch-button-label {
    position: relative;
    padding: 15px 0;
    display: block;
    user-select: none;
    pointer-events: none;
  }

  .switch-button-checkbox + .switch-button-label:before {
    content: "";
    background: #fff;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 30px;
    transform: translateX(0);
    transition: transform 300ms;
  }

  .switch-button-checkbox + .switch-button-label .switch-button-label-span {
    position: relative;
  }
</style>