<template>
  <div v-if="rows" class="row justify-content-center">
    <div class="col-sm-12">
      <div v-if="onCompanyCreate">
        <MainButton
            style="float: right;"
            text="Нова компанија"
            :on-click="onCompanyCreate"
        >
          <template #icon>
            <i class="fa fa-plus-circle" />
          </template>
        </MainButton>
        <br /><br /><br />
      </div>
      <DataTable v-if="rows.length" :value="rows"
                 auto-layout
                 :sort-field="defaultSort" :sortOrder="1" removableSort
                 paginator :rows="10" :rowsPerPageOptions="[10,20,50]"
                 currentPageReportTemplate="Вкупно {totalRecords} записи."
                 paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport"
      >
        <Column v-for="col of columns" :field="col.field" :sort-field="col.sortableField"
                :header="col.header" :key="col.field" :sortable="col.sortable" :hidden="col.hidden"
        >
          <template v-if="col.field === 'active'" #body="slotProps">
            <i v-if="slotProps.data.active" class="fa fa-check" style="color: green;" />
            <i v-else class="fa fa-times" style="color: red;" />
          </template>
        </Column>
        <Column :exportable="false">
          <template #body="slotProps">
            <div :onclick="() => onCompanyDetails(slotProps.data.id)">
              <MainButton
                  small-btn
                  light-layer
                  text="Види компанија"
              />
            </div>
          </template>
        </Column>
      </DataTable>
      <div v-else class="alert alert-danger">{{ emptyGridMessage }}</div>
    </div>
  </div>
</template>

<script>
  import MainButton from '@/screens/partial/MainButton';

  export default {
    name: 'CompanyGrid',
    components: {MainButton},
    props: {
      onCompanyCreate: {
        required: false,
        type: Function
      },
      onCompanyDetails: {
        required: true,
        type: Function
      },
      rows: {
        required: false,
        type: Array(Object)
      },
      columns: {
        required: true,
        type: Array(Object)
      },
      emptyGridMessage: {
        required: true,
        type: String
      }
    },
    computed: {
      defaultSort() {
        const sortDefault = this.columns.filter(col => col.defaultSortable)[0];

        if (sortDefault) {
          return sortDefault.sortableField ? sortDefault.sortableField : sortDefault.field;
        }
        return undefined;
      }
    }
  }
</script>

<style scoped>

</style>