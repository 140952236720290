import { Package } from '@/models/entity/Package';
import { City } from '@/models/entity/City';
import { Seller } from '@/models/entity/Seller';
import { InvoiceStatus } from '@/models/entity/InvoiceStatus';

export class Invoice {
  constructor(data) {
    if (data) {
      this.id = data.id;
      this.buyerAddress = data.buyerAddress;
      /** @type {City} **/
      this.buyerCity = data.buyerCity;
      this.buyerNameSurname = data.buyerNameSurname;
      this.buyerPhoneNumber = data.buyerPhoneNumber;
      this.buyerSignature = data.buyerSignature;
      this.cargoPrice = data.cargoPrice;
      this.creation = data.creation;
      this.description = data.description;
      this.discount = data.discount;
      this.fromMcOffice = data.fromMcOffice;
      /** @type {Package[]} **/
      this.packages = data.packages;
      this.paymentCargo = data.paymentCargo;
      this.paymentPackage = data.paymentPackage;
      /** @type {Seller} **/
      this.seller = data.seller;
      this.sellerPaysCargo = data.sellerPaysCargo;
      this.sellerSignature = data.sellerSignature;
      /** @type {InvoiceStatus[]} **/
      this.statuses = data.statuses;
      this.toMcOffice = data.toMcOffice;
      this.uin = data.uin;
      this.assignedEmployeeFullName = data.assignedEmployeeFullName;
      this.assignedTimestamp = data.assignedTimestamp;
      this.deletable = data.deletable;
    }
  }
}
