import $ from 'jquery';
import Error from '../models/backend/Error';
import {logg} from "@/config/Logging";

const functions = {
  /**
   * Reset input errors so that in the future they can be revalidated.
   */
  resetInputErrors() {
    logg('FormFieldService', 'Resetting input errors');
    $('.invalid-feedback').each(function () {
      $(this).css({display: 'none'});
    });
  },
  /**
   * Show input errors for all fields that have issues.
   *
   * @param errors  The backend response errors.
   */
  populateErrorFields(errors) {
    let thisS = this;
    errors.forEach(function (it) {
      let error = new Error(it);

      thisS.showErrorForField(error.field, error.message);
    });
  },
  /**
   * Show input errors for specific field that has an issue.
   *
   * @param field     The input field.
   * @param message   The error message.
   */
  showErrorForField(field, message) {
    const nestedField = field.includes('[') || field.includes(']') || field.includes('.');
    if (nestedField) {
      field = field.replace('[', '').replace(']', '').replace('.', '');
    }

    let element = $(`#${field}`).siblings('.invalid-feedback');

    logg('FormFieldService', `Backend error for field: ${field}, message: ${message}`);

    element.css({display: 'block'});
    element.text(message);
  }
};

const useFormFieldService = () => {
  return functions;
};

export { useFormFieldService };
