import { usePermissionService } from '@/service/PermissionService';

const permissionService = usePermissionService();

/**
 * Everyone can view list of invoices:
 *    - Seller: Own invoices. (backend logics)
 *    - Manager: Unassigned invoices. (backend logics)
 *    - Employee: Own assigned invoices. (backend logics)
 */
const canViewInvoiceList = () => {
  return permissionService.isAuthorizedSeller() ||
    permissionService.isAuthorizedEmployee() ||
    permissionService.isAuthorizedManager();
};

/**
 * A seller can view only his own invoice.
 * An employee can view every invoice.
 */
const canViewInvoiceSingle = () => {
  return (
    permissionService.isAuthorizedSeller() ||
    permissionService.isAuthorizedEmployee()
  );
};

/**
 * Only sellers can create invoices.
 */
const canCreateInvoice = () => {
  return permissionService.isAuthorizedSeller();
};

/**
 * Only sellers can delete THEIR OWN invoices.
 * Note: If a seller is logged in, he cannot see other seller's invoices.
 */
const canDeleteInvoice = () => {
  return permissionService.isAuthorizedSeller();
};

/**
 * Only employees can change invoice's status.
 */
const canUpdateInvoiceStatus = () => {
  return permissionService.isAuthorizedEmployee();
};

/**
 * Employees can make cash/terminal payment for packages.
 */
const canUpdateInvoicePaymentPackage = () => {
  return permissionService.isAuthorizedEmployee();
};

/**
 * Sellers can make online payment for cargo.
 * Employees can make cash/terminal payment for cargo.
 *
 * @param sellerPaysCargo   Boolean if the seller pays cargo (invoice data).
 * @returns {boolean}       True or false.
 */
const canUpdateInvoicePaymentCargo = (sellerPaysCargo) => {
  if (sellerPaysCargo) {
    return (
      !!permissionService.isAuthorizedSeller() ||
      !!permissionService.isAuthorizedEmployee()
    );
  }

  return !!permissionService.isAuthorizedEmployee()
};

/**
 * Only managers can assign an invoice to an employee.
 *
 * @returns {string}
 */
const canAssignInvoice = () => {
  return permissionService.isAuthorizedManager();
}

export const useInvoicePermissions = () => {
  return {
    canViewInvoiceList,
    canViewInvoiceSingle,
    canCreateInvoice,
    canDeleteInvoice,
    canUpdateInvoiceStatus,
    canUpdateInvoicePaymentPackage,
    canUpdateInvoicePaymentCargo,
    canAssignInvoice
  };
};
