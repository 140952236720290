<template>
  <TitledPage
      title="Основни податоци"
      :on-back="onBack"
      :on-edit="!loading.main ? handleEditBasicInformation : undefined"
      :on-delete="(!loading.main && employee.canBeDeleted) ? deleteEmployee : undefined"
      :loading-delete="loading.deleteEmployee"
  >
    <LoadingSpinner v-if="loading.main" />

    <div v-if="employee" class="tab-pane fade active show" id="account-user">

      <div class="card-body media align-items-center">
        <img :src="require('@/assets/imgs/user.png')" alt="" class="d-block ui-w-80">
        <div class="media-body ml-4">
          <MainButton
              text="Прикачи слика"
              small-btn
              :on-click="uploadProfilePicture"
          />
          <div class="text-light small mt-1">Дозволени се JPG и PNG формати. Максималната големина е 2 MB.</div>
        </div>
      </div>

      <hr class="border-light m-0">

      <div class="card-body form-input-box">
        <DisabledInputField
            label="Име"
            :value="employee.name"
        />
        <DisabledInputField
            label="Презиме"
            :value="employee.surname"
        />
        <DisabledInputField
            label="ЕМБГ"
            :value="employee.embg"
        />
        <DisabledInputField
            label="Адреса"
            :value="employee.address"
        />
        <DisabledInputField
            label="Град"
            :value="employee.city.name"
        />
        <DisabledInputField
            label="Телефонски број"
            :value="employee.phoneNumber"
        />
      </div>

    </div>
  </TitledPage>
  <ConfirmationDialog
      v-if="!loading.main && employee.canBeDeleted"
      id="delete-employee"
      text="Дали сте сигурни дека сакате да го избришете вработениот и сите негови податоци?"
      :showing="showing.deleteEmployee"
      :on-success="doDeleteEmployee"
      :on-cancel="cancelDeleteEmployee"
  />
</template>

<script>
  import { useNotificationService } from '@/service/NotificationService';
  import { useEntityParser } from '@/utils/EntityParser';
  import { useRoute } from 'vue-router';
  import axios from 'axios';
  import Response from '@/models/backend/Response';
  import { Employee } from '@/models/entity/Employee';
  import DisabledInputField from '@/screens/partial/DisabledInputField';
  import MainButton from '@/screens/partial/MainButton';
  import router, { resolveBaseRoute, resolvePathWithParams } from '@/router';
  import LoadingSpinner from '@/screens/partial/LoadingSpinner';
  import ConfirmationDialog from '@/screens/partial/ConfirmationDialog';
  import { City } from '@/models/entity/City';
  import TitledPage from '@/shared/layout/TitledPage';

  const notificationService = useNotificationService()
  const entityParser = useEntityParser()

  const stateMutations = {
    /** @param {Employee} data **/
    setEmployee: function (data) {
      this.employee = data
    },
    /** @param {Array<City>} data **/
    setCities: function (data) {
      this.cities = data
    },
    /** @param {boolean} data **/
    setLoadingMain: function (value) {
      this.loading.main = value
    },
    /** @param {boolean} data **/
    setLoadingDeleteEmployee: function (data) {
      this.loading.deleteEmployee = data
    },
    /** @param {boolean} value **/
    setShowingDeleteEmployee: function (value) {
      this.showing.deleteEmployee = value;
    }
  }

  export default {
    name: 'EmployeeBasicInformation',
    components: {TitledPage, ConfirmationDialog, LoadingSpinner, MainButton, DisabledInputField},
    data() {
      return {
        /** @type {Number} **/
        employeeId: undefined,
        loading: {
          /** @type {Boolean} **/
          main: true,
          /** @type {Boolean} **/
          deleteEmployee: false
        },
        showing: {
          /** @type {Boolean} **/
          deleteEmployee: false
        },
        /** @type {Employee} **/
        employee: undefined,
        /** @type {Array<City>} **/
        cities: undefined
      }
    },
    mounted() {
      const {infoMessage, id} = useRoute().params;
      this.employeeId = parseInt(id.toString());

      notificationService.maybeShowInfoModal(infoMessage)

      this.getData();
      this.fetchCities();
    },
    methods: {
      getData() {
        axios.get(`employees/${this.employeeId}`).then(it => {
          const response = new Response(it);

          const employee = new Employee(response.data);

          this.setEmployee(employee);
          this.setLoadingMain(false);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally()
      },
      fetchCities() {
        axios.get('city').then(it => {
          const response = new Response(it);

          const cities = entityParser.toCities(response.data);

          this.setCities(cities);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally(() => this.setLoadingMain(false))
      },
      handleEditBasicInformation() {
        const route = resolvePathWithParams(resolveBaseRoute().nested.Employees.nested.EmployeeEdit.path, this.employeeId);
        router.push(route)
      },
      deleteEmployee() {
        this.setLoadingDeleteEmployee(true)
        this.setShowingDeleteEmployee(true)
      },
      doDeleteEmployee() {
        this.setShowingDeleteEmployee(false);

        axios.delete(`employee/${this.employeeId}`).then(it => {
          let response = new Response(it);

          router.push({
            name: resolveBaseRoute().nested.Employees.name,
            params: {
              infoMessage: response.message
            }
          })
        }).catch(it => {
          let response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally(() => this.setLoadingDeleteEmployee(false));
      },
      cancelDeleteEmployee() {
        this.setLoadingDeleteEmployee(false);
        this.setShowingDeleteEmployee(false);
      },
      uploadProfilePicture() {
        notificationService.notImplNotify()
      },
      onBack() {
        router.push({
          name: resolveBaseRoute().nested.Employees.name
        })
      },
      ...stateMutations
    }
  }
</script>

<style scoped>
  .ui-w-80 {
    width: 80px !important;
    height: auto;
  }

</style>