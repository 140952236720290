<template>
  <PageLayout id="termsAndConditions">
    <div class="row">
      <div class="col-md-12">

        <h1>Одредби и услови за користење</h1>

        <hr />

        <h2>1. Услови</h2>

        <p>Со пристапување на оваа веб-страница, достапна од mycargo.aboykov.com, се согласувате да бидете обврзани со
          овие
          Услови и правила за користење на веб-страницата и се согласувате дека сте одговорни за договорот со сите
          важечки локални закони. Доколку не се согласувате со некој од овие услови, ви е забранет пристапот на оваа
          страница. Материјалите содржани на оваа веб-страница се заштитени со законот за авторски права и трговски
          марки.</p>

        <h2>2. Лиценца за користење</h2>

        <p>Се дава дозвола за привремено преземање на една копија од материјалите на веб-страницата на „моеКарго“
          само за лично, некомерцијално привремено користење. Ова е доделување лиценца, а не пренос, и според оваа
          лиценца не смеете и е забрането:</p>

        <ul>
          <li>изменување или копирање на материјалите</li>
          <li>користење на материјалите за која било комерцијална намена или за јавно прикажување</li>
          <li>обид да се направи обратен инженеринг на кој било софтвер содржан на веб-страницата на „моеКарго“</li>
          <li>отстранување на сите авторски права или други сопственички ознаки од материјалите</li>
          <li>пренесување на материјалите на друго лице или „отсликување“ на материјалите на кој било друг сервер
          </li>
        </ul>

        <p>Ова ќе му овозможи на „моеКарго“ да ја прекине испораката на услугите кон вас при прекршување на кое било
          од овие ограничувања. По раскинувањето, вашето право на пристап исто така ќе биде прекинато и треба да ги
          уништите сите преземени материјали што ги поседувате без разлика дали се печатени или електронски.</p>

        <h2>3. Одрекување од одговорност</h2>

        <p>Сите материјали на веб-страницата на „моеКарго“ се обезбедени „такви какви што се“. „моеКарго“ не дава
          никакви гаранции, било тоа да се искажани или имплицитни, и затоа ги негира сите други гаранции. Понатаму,
          „моеКарго“ не дава никакви забелешки во врска со точноста или веродостојноста на употребата на
          материјалите на неговата веб-страница или на друг начин во врска со таквите материјали или било кои
          страници поврзани со оваа веб-страница.</p>

        <h2>4. Ограничувања</h2>

        <p>„моеКарго“ или неговите добавувачи нема да бидат одговорни за каква било штета што ќе настане со
          употребата или неможноста да се користат материјалите на веб-страницата на „моеКарго“, дури и ако
          „моеКарго“ или овластен претставник на оваа веб-страница бил известен, усно или писмено, за можноста за
          таква штета. Некои надлежности не дозволуваат ограничувања на имплицитните гаранции или ограничувања на
          одговорноста за случајни штети, така што овие ограничувања можеби нема да важат за вас.</p>

        <h2>5. Ревизии и грешка</h2>

        <p>Материјалите што се појавуваат на веб-страницата на „моеКарго“ може да вклучуваат технички, типографски
          или фотографски грешки. „моеКарго“ нема да вети дека некој од материјалите на оваа веб-страница е точен,
          целосен или актуелен. „моеКарго“ може да ги промени материјалите содржани на неговата веб-страница во
          секое време без претходна најава. „моеКарго“ не презема никаква обврска да ги ажурира материјалите.</p>

        <h2>6. Врски</h2>

        <p>„моеКарго“ ги нема прегледано сите страници поврзани со оваа веб-страница и не е одговорна за содржината
          на која било таква поврзана страница. Присуството на каква било врска не значи одобрување од страна на
          „моеКарго“ на страницата. Употребата на која било поврзана веб-страница е на сопствен ризик на
          корисникот.</p>

        <h2>7. Измени на Условите за користење на страницата</h2>

        <p>„моеКарго“ може да ги ревидира овие Услови за користење за својата веб-страница во секое време без
          претходна најава. Со користење на оваа веб-страница, се согласувате да бидете обврзани со тековната
          верзија на овие Услови и правила за користење.</p>

        <h2>8. Вашата приватност</h2>

        <p>Ве молиме прочитајте ја нашата Политика за приватност.</p>

        <h2>9. Владејачки закон</h2>

        <p>Секое тврдење поврзано со веб-страницата на „моеКарго“ ќе биде регулирано со законитe без оглед на
          одредбите за судир на закони.</p>

        <h2>10. Важење на Условите за користење</h2>

        <p>Стапува на сила на 12.02.2022 и сè уште важи. Овој документ е генериран и сите наведени информации во
          него се фиктивни.</p>

      </div>
    </div>
  </PageLayout>
</template>

<script>
  import PageLayout from '@/shared/layout/PageLayout';

  export default {
    name: 'TermsAndConditions',
    components: {PageLayout}
  }
</script>

<style scoped>
  #termsAndConditions {
    text-align: justify;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p {
    margin: 10px 0;
  }

  ul li {
    list-style-type: circle;
    margin-left: 50px;
  }
</style>