<template>
  <TitledPage
      :title="getLabel('label.title.my_profile')"
      :on-back="onBack"
      :on-edit="onEdit"
  >
    <LoadingSpinner v-if="loading.main" />

    <div v-else class="card-body form-input-box">
      <div v-if="!myProfileModel.active" class="alert alert-danger">
        {{ getLabel('label.notification.inactive_user_profile') }}
      </div>
      <div>
        <Title :text="getLabel('label.title.personal_data')"/>

        <DisabledInputField
            :label="getLabel('label.input.name')"
            :value="myProfileModel.name"
        />
        <DisabledInputField
            :label="getLabel('label.input.surname')"
            :value="myProfileModel.surname"
        />
        <DisabledInputField
            v-if="myProfileModel.embg"
            :label="getLabel('label.input.embg')"
            :value="myProfileModel.embg"
        />
        <DisabledInputField
            :label="getLabel('label.input.address')"
            :value="myProfileModel.address"
        />
        <DisabledInputField
            :label="getLabel('label.input.city')"
            :value="myProfileModel.city.name"
        />
        <DisabledInputField
            :label="getLabel('label.input.phone_number')"
            :value="myProfileModel.phoneNumber"
        />
        <DisabledInputField
            v-if="myProfileModel.company"
            :label="getLabel('label.input.assigned_company')"
            :value="myProfileModel.company"
        />
      </div>
      <hr />
      <div>
        <Title :text="getLabel('label.title.user_profile')"/>

        <DisabledInputField
            :label="getLabel('label.input.username')"
            :value="myProfileModel.username"
        />
        <DisabledInputField
            :label="getLabel('label.input.email_address')"
            :value="myProfileModel.email"
        />
        <DisabledInputField
            :label="getLabel('label.input.user_role')"
            :value="myProfileModel.role"
        />
        <DisabledInputField
            :label="getLabel('label.input.user_since')"
            :value="myProfileModel.created"
        />
        <DisabledInputField
            :label="getLabel('label.input.last_login')"
            :value="myProfileModel.lastLogin"
        />
        <DisabledInputField
            :label="getLabel('label.input.active_profile')"
            :value="myProfileModel.active"
        />
      </div>

    </div>
  </TitledPage>
</template>

<script>
  import TitledPage from '../../../../shared/layout/TitledPage';
  import router, { ROUTES } from '@/router';
  import LoadingSpinner from '@/screens/partial/LoadingSpinner';
  import DisabledInputField from '@/screens/partial/DisabledInputField';
  import { MyProfile } from '@/models/entity/MyProfile';
  import Title from '@/screens/partial/Title';
  import axios from 'axios';
  import Response from '@/models/backend/Response';
  import { useNotificationService } from '@/service/NotificationService';
  import { useDateTimeUtils } from '@/utils/DateTimeUtils';
  import {useRoute} from 'vue-router';
  import {useMessageSource} from "@/config/MessageSource";

  const notificationService = useNotificationService()
  const dateTimeUtils = useDateTimeUtils()
  const {getLabel} = useMessageSource()

  const stateMutations = {
    /** @param {MyProfile} data **/
    setMyProfileModel: function (data) {
      this.myProfileModel = data
    },
    /** @param {boolean} value **/
    setLoadingMain: function (value) {
      this.loading.main = value
    }
  }

  export default {
    name: 'MyProfileViewPage',
    components: {Title, DisabledInputField, LoadingSpinner, TitledPage},
    data() {
      return {
        /** @type {MyProfile} **/
        myProfileModel: undefined,
        loading: {
          /** @type {Boolean} **/
          main: true
        }
      }
    },
    mounted() {
      const {infoMessage} = useRoute().params;
      notificationService.maybeShowInfoModal(infoMessage)

      this.getData();
    },
    methods: {
      getLabel,
      getData() {
        axios.get(`myProfile`).then(it => {
          const response = new Response(it);

          const myProfile = new MyProfile(response.data);

          myProfile.created = dateTimeUtils.toLocalDate(myProfile.created)
          myProfile.lastLogin = dateTimeUtils.toLocalDateTime(myProfile.lastLogin)

          this.setMyProfileModel(myProfile);
          this.setLoadingMain(false);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally()
      },
      onBack() {
        router.push({
          name: ROUTES.Dashboard.name
        })
      },
      onEdit() {
        router.push({
          name: ROUTES.Dashboard.nested.MyProfile.nested.MyProfileEdit.name
        })
      },
      ...stateMutations
    }
  }
</script>

<style scoped>

</style>