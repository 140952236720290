<template>
  <!-- Modal -->
  <div class="modal fade right" id="myCargoInfoModal" tabindex="-1" role="dialog" aria-labelledby="myCargoInfoModal"
       aria-hidden="true" data-backdrop="true"
  >
    <div class="modal-dialog modal-side modal-bottom-right modal-notify modal-info" role="document">
      <!--Content-->
      <div class="modal-content">
        <!--Header-->
        <div class="modal-header">
          <p class="heading">{{ getLabel('label.title.information') }}</p>
        </div>

        <!--Body-->
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <p><span id="mainMessage"></span></p>
            </div>
          </div>
        </div>

        <!--Footer-->
        <div class="modal-footer justify-content-center">
          <a type="button" class="ternary-button" data-dismiss="modal">{{ getLabel('label.button.ok') }}</a>
        </div>
      </div>
      <!--/.Content-->
    </div>
  </div>
</template>

<script>
import {useMessageSource} from "@/config/MessageSource";

const {getLabel} = useMessageSource()

export default {
  name: 'InfoModal',
  methods: {
    getLabel
  }
}
</script>

<style scoped src="@/assets/css/partial/dialog-css.css" />