<template>
  <div class="user-box input-box">
    <InputMask
        :id="id"
        class="localInputMask form-control"
        :class="[
            small ? 'form-control-sm' : '',
            !raw ? 'input-box-inner' : ''
        ]"
        :modelValue="modelValue"
        @change="onChange"
        v-bind:placeholder="placeholder"
        v-bind:mask="mask"
        required
    />
    <label v-if="label">{{ this.label }}</label>
    <span class="invalid-feedback"></span>
  </div>
</template>

<script>
import InputMask from "@/assets/vendor/primevue/inputmask";

export default {
  name: 'MaskedInput',
  components: {InputMask},
  props: {
    id: {
      type: String,
      required: true
    },
    raw: {
      type: Boolean,
      required: false,
      default: () => false
    },
    small: {
      type: Boolean,
      required: false,
      default: () => false
    },
    placeholder: {
      type: String,
      required: false,
      default: () => ''
    },
    label: {
      type: String,
      required: false,
      default: () => undefined
    },
    modelValue: {
      type: String,
      required: false,
      default: () => undefined
    },
    mask: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    return {
      onChange: (e) => {
        emit('update:modelValue', e.currentTarget.value)
      }
    }
  }
}
</script>

<style scoped>
.localInputMask {
  font-family: inherit;
}

/* DO NOT REMOVE, THIS IS USED ABOVE */
.input-box-inner {
  border-radius: 0;
  border-bottom: 1px solid #fff;
}

/* DO NOT REMOVE, THIS IS USED ABOVE */
.input-box-inner {
  border-radius: 0;
  border-bottom: 1px solid #fff;
}

/* DO NOT REMOVE, THIS IS USED ABOVE */
.input-box-inner:focus {
  box-shadow: none;
  border-color: white;
}

/* DO NOT REMOVE, THIS IS USED ABOVE */
.input-box-inner:hover {
  border-color: white;
}
</style>