<template>
  <LoadingSpinner v-if="loading.main" />

  <div v-if="employee" class="input-form">
    <div v-if="employee.hasConnectedAccount">
      <div class="alert alert-success" v-if="employee.accountActive">Корисникот има активен профил</div>
      <div class="alert alert-warning" v-else>Корисникот има неактивен профил</div>

      <div class="p-5 form-input-box">
        <DisabledInputField label="Емаил адреса" :value="employee.email" />
        <DisabledInputField label="Корисничко име" :value="employee.username" />
      </div>

      <MainButton
          :text="employee.accountActive ? 'Деактивирај профил' : 'Активирај профил'"
          :on-click="toggleProfileActive"
          :loading="loading.toggleActive"
      />
    </div>
    <div v-else>
      <div class="alert alert-danger">Корисникот сè уште нема активен профил</div>

      <div class="p-5 form-input-box">
        <Input
            id="email"
            type="text"
            label="Емаил адреса"
            v-model="email"
        />
        <Input
            id="username"
            type="text"
            label="Корисничко име"
            v-model="username"
        />

        <MainButton
            text="Креирај профил"
            :on-click="createAccountForEmployee"
            :loading="loading.createAccount"
        />
      </div>
    </div>
  </div>


</template>

<script>
  import axios from 'axios';
  import Response from '../../../../models/backend/Response';
  import { useFormFieldService } from '@/service/FormFieldService';
  import { useNotificationService } from '@/service/NotificationService';
  import MainButton from '@/screens/partial/MainButton';
  import { useRoute } from 'vue-router';
  import { Employee } from '@/models/entity/Employee';
  import LoadingSpinner from '@/screens/partial/LoadingSpinner';
  import DisabledInputField from '@/screens/partial/DisabledInputField';
  import Input from "@/shared/input/Input";

  const formFieldService = useFormFieldService()
  const notificationService = useNotificationService()

  const stateMutations = {
    /** @param {Employee} data **/
    setEmployee: function (data) {
      this.employee = data
    },
    /** @param {boolean} value **/
    setLoadingMain: function (value) {
      this.loading.main = value
    },
    /** @param {boolean} value **/
    setLoadingCreateAccount: function (value) {
      this.loading.createAccount = value
    },
    /** @param {boolean} value **/
    setLoadingToggleActive: function (value) {
      this.loading.toggleActive = value
    }
  }

  export default {
    name: 'EmployeeAccountCreation',
    components: {Input, DisabledInputField, LoadingSpinner, MainButton},
    data() {
      return {
        /** @type {Number} **/
        employeeId: undefined,
        email: undefined,
        username: undefined,
        loading: {
          /** @type {Boolean} **/
          main: true,
          /** @type {Boolean} **/
          createAccount: false,
          /** @type {Boolean} **/
          toggleActive: false
        },
        /** @type {Employee} **/
        employee: undefined
      }
    },
    mounted() {
      const {id} = useRoute().params;
      this.employeeId = parseInt(id.toString());

      this.getData();
    },
    methods: {
      getData() {
        axios.get(`employees/${this.employeeId}`).then(it => {
          const response = new Response(it);

          const employee = new Employee(response.data);

          this.setEmployee(employee);
          this.setLoadingMain(false);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally()
      },
      createAccountForEmployee() {
        this.setLoadingCreateAccount(true);

        formFieldService.resetInputErrors();

        const body = {
          email: this.email,
          username: this.username
        }

        axios.post(`employee/${this.employeeId}/addAccount`, body).then(it => {
          const response = new Response(it);

          notificationService.showInfoModal(response.message);

          this.getData();
        }).catch(it => {
          const response = new Response(it.response);

          if (response.hasErrors()) {
            formFieldService.populateErrorFields(response.errors);
          }

          // Do not show error if the validation violation happens. Errors will show up
          // under every input field.
          if (!response.isEntityNotValidError()) {
            notificationService.showInfoModal(response.message)
          }
        }).finally(() => this.setLoadingCreateAccount(false))
      },
      toggleProfileActive() {
        this.setLoadingToggleActive(true);

        const username = this.employee.username;

        axios.put(`account/${username}/active`).then(async it => {
          const response = new Response(it);

          notificationService.showInfoModal(response.message);

          this.getData();
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message)
        }).finally(() => this.setLoadingToggleActive(false))
      },
      ...stateMutations
    }
  }
</script>

<style scoped>

</style>