<template>
  <PageLayout>
    <div class="row text-left pb-md-1">
      <div v-if="!!onBack" class="col-md-1 align-self-center">
        <MainButton text="" :on-click="onBack" small-btn>
          <template #icon>
            <i style="margin: 0 10px;" class="fa fa-arrow-left" />
          </template>
        </MainButton>
      </div>
      <div class="col-md-7 align-self-center" :class="!onBack && 'col-md-8'">
        <h4 style="text-align: left">{{ title }}</h4>
      </div>
      <div class="col-md-4 text-right align-self-center">
        <MainButton v-if="!!onEdit" :text="getLabel('label.button.edit_data')" small-btn :on-click="onEdit"/>
        <MainButton v-if="!!onDelete" :text="getLabel('label.button.delete')" small-btn :on-click="onDelete"
                    :loading="loadingDelete"
                    :style="!!onEdit && 'margin-left: 10px;'"
        />
        <MainButton v-if="!!onSave" :text="getLabel('label.button.save')" small-btn :on-click="onSave"
                    :loading="loadingSave"/>
      </div>
      <div class="col-md-12">
        <hr />
      </div>
    </div>

    <div class="row justify-content-md-center">
      <div class="col-md-12 mt-4">
        <slot />
      </div>
    </div>
  </PageLayout>
</template>

<script>
import PageLayout from './PageLayout';
import MainButton from '@/screens/partial/MainButton';
import {useMessageSource} from "@/config/MessageSource";

const {getLabel} = useMessageSource()

export default {
  name: 'TitledPage',
  components: {MainButton, PageLayout},
  props: {
    title: {
      required: true,
      type: String
    },
    onBack: {
        required: false,
        type: Function
      },
      onEdit: {
        required: false,
        type: Function
      },
      onDelete: {
        required: false,
        type: Function
      },
      loadingDelete: {
        required: false,
        type: Boolean
      },
    onSave: {
      required: false,
      type: Function
    },
    loadingSave: {
      required: false,
      type: Boolean
    }
  },
  methods: {
    getLabel
  }
}
</script>

<style scoped>
  hr {
    border: var(--main-color) 1px solid;
  }
</style>