import { City } from '@/models/entity/City';

export class Seller {
  constructor(data) {
    if (data) {
      this.address = data.address;
      /** @type {City} **/
      this.city = data.city;
      // TODO aboykov JSDoc type to be added.
      this.company = data.company;
      this.id = data.id;
      this.name = data.name;
      this.phoneNumber = data.phoneNumber;
      this.surname = data.surname;
    }
  }
}
