import {useLocalI18n} from "@/main";

const getLabel = (label, ...args) => {
  try {
    let params = Array.from(args);
    return useLocalI18n().global.t(label, params)
  } catch {
    return label;
  }
}

export const useMessageSource = () => {
  return {
    getLabel
  }
}