const toLocalDate = (datetime) => {
  // Sample datetime format
  // 2021-11-09T14:47:00.170+01:00

  let onlyDate = datetime.split('T')[0];
  let month = onlyDate.split('-')[1];
  let day = onlyDate.split('-')[2];
  let year = onlyDate.split('-')[0];

  return `${day}.${month}.${year}`;
};
const toLocalDateTime = (datetime) => {
  // Sample datetime format
  // 2021-11-09T14:47:00.170+01:00

  let localDate = toLocalDate(datetime);

  let localTime = datetime.split('T')[1];
  localTime = localTime.substr(0, 5);

  return `${localDate}, ${localTime}`;
};
const getMinutesBetweenDates = (startDate, endDate) => {
  let diff = endDate.getTime() - startDate.getTime();
  return diff / 60000;
};
const havePassedMoreThanXMinutes = (startDate, endDate, minutes) => {
  let mins = getMinutesBetweenDates(startDate, endDate);

  return mins > minutes;
};

export const useDateTimeUtils = () => {
  return {
    toLocalDate,
    toLocalDateTime,
    havePassedMoreThanXMinutes
  };
};
