const functions = {
  /**
   * If objects exists, actually if it is not null;
   *
   * @param object    The object to be checked.
   */
  exists(object) {
    return object !== null;
  },
  generateRandomAlphanumericString(length) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
};

const useAppService = () => {
  return functions;
};

export { useAppService };
