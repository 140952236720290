import {logg} from "@/config/Logging";

const PAYMENT_SESSION_ID = 'payment_session_id';
const PAYMENT_SESSION_URL = 'payment_session_url';
const LANGUAGE = 'lang';

const manipulateLanguage = {
  /**
   * Fetches language from the browser local storage.
   *
   * @returns {string}  Language.
   */
  getLanguage: () => {
    logg('LocalStorageUtils', 'Getting the language');
    return localStorage.getItem(LANGUAGE);
  },

  /**
   * Saves the language to the browser local storage.
   *
   * @param language  Language.
   */
  setLanguage: (language) => {
    logg('LocalStorageUtils', `Setting the language to: ${language}`);
    localStorage.setItem(LANGUAGE, language);
  },

  /**
   * Removes the language from the browser local storage.
   */
  removeLanguage: () => {
    logg('LocalStorageUtils', 'Removing the language');
    localStorage.removeItem(LANGUAGE)
  }
}

const manipulatePaymentSessionId = {
  /**
   * Fetches payment session id from the browser local storage.
   *
   * @returns {string}  Payment session id.
   */
  getPaymentSessionId: () => {
    logg('LocalStorageUtils', 'Getting the payment session id');
    return localStorage.getItem(PAYMENT_SESSION_ID);
  },

  /**
   * Saves the payment session id to the browser local storage.
   *
   * @param paymentSessionId  Payment session id.
   */
  setPaymentSessionId: (paymentSessionId) => {
    logg('LocalStorageUtils', `Setting the payment session id to: ${paymentSessionId}`);
    localStorage.setItem(PAYMENT_SESSION_ID, paymentSessionId);
  },

  /**
   * Removes the payment session id from the browser local storage.
   */
  removePaymentSessionId: () => {
    logg('LocalStorageUtils', 'Removing the payment session id');
    localStorage.removeItem(PAYMENT_SESSION_ID)
  }
}

const manipulatePaymentSessionUrl = {
  /**
   * Fetches payment session url from the browser local storage.
   *
   * @returns {string}  Payment session url.
   */
  getPaymentSessionUrl: () => {
    logg('LocalStorageUtils', 'Getting the payment session url');
    return localStorage.getItem(PAYMENT_SESSION_URL);
  },

  /**
   * Saves the payment session url to the browser local storage.
   *
   * @param paymentSessionUrl  Payment session url.
   */
  setPaymentSessionUrl: (paymentSessionUrl) => {
    logg('LocalStorageUtils', `Setting the payment session url to: ${paymentSessionUrl}`);
    localStorage.setItem(PAYMENT_SESSION_URL, paymentSessionUrl);
  },

  /**
   * Removes the payment session url from the browser local storage.
   */
  removePaymentSessionUrl: () => {
    logg('LocalStorageUtils', 'Removing the payment session url');
    localStorage.removeItem(PAYMENT_SESSION_URL)
  }
}


export const useLocalStorageUtils = () => {
  return {
    ...manipulateLanguage,
    ...manipulatePaymentSessionId,
    ...manipulatePaymentSessionUrl
  }
}