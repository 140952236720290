// [1] Imports: Global
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

// [2] Imports: CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.css';

// [3] Imports: Default stuff for application
import '@/assets/css/my-cargo.css';

// [4] Imports: SignaturePad
import VueSignature from 'vue-signature-pad';

// [5] Imports: PrimeVue
import PrimeVue from '@/assets/vendor/primevue/config';
import '@/assets/css/primevue/primevue-theme.css'

// [6] Imports: Vuex store
import {store} from "@/utils/store";

// [7] Localization
import {createI18n} from "vue-i18n";
import {i18n_messages} from "@/localization/i18n";

const DEFAULT_LANG = 'mk'

const i18n = createI18n({
  // something vue-i18n options here ...
  locale: useLocalStorageUtils().getLanguage() ?? DEFAULT_LANG,
  fallbackLocale: DEFAULT_LANG,
  messages: i18n_messages
})
export const useLocalI18n = () => i18n;

// Default language setup
const localStorageUtils = useLocalStorageUtils()
if (!localStorageUtils.getLanguage()) {
  localStorageUtils.setLanguage(DEFAULT_LANG)
}

// JQuery (To be used in screens without importing it everywhere) NOT WORKING
// window.$ = window.jQuery = require('jquery/dist/jquery.min');

// Boostrap JS
// import 'popper.js/dist/popper'
// import 'bootstrap/dist/js/bootstrap.min.js'

const app = createApp(App)
  .use(router)
  .use(store)
  .use(VueSignature)
  .use(PrimeVue)
  .use(i18n);

// Axios API configuration
configureAxios();

// Emitter Configuration
configureMitt(app)

app.mount('#app');

// PrimeVue Components
import DataTable from '@/assets/vendor/primevue/datatable';
import Column from '@/assets/vendor/primevue/column';
import ColumnGroup from '@/assets/vendor/primevue/columngroup';
import Row from '@/assets/vendor/primevue/row';
import TabView from '@/assets/vendor/primevue/tabview'
import TabPanel from '@/assets/vendor/primevue/tabpanel'
import Menubar from '@/assets/vendor/primevue/menubar';
import InputText from '@/assets/vendor/primevue/inputtext';
import TextArea from '@/assets/vendor/primevue/textarea'
import InputMask from '@/assets/vendor/primevue/inputmask';
import Dropdown from '@/assets/vendor/primevue/dropdown';
import {useLocalStorageUtils} from '@/utils/LocalStorageUtils';
import {configureAxios} from '@/config/AxiosConfig';
import {configureMitt} from '@/config/EmitterConfig';

app.component('DataTable', DataTable)
  .component('Column', Column)
  .component('ColumnGroup', ColumnGroup)
  .component('Row', Row)
  .component('TabView', TabView)
  .component('TabPanel', TabPanel)
  .component('MenuBar', Menubar)
  .component('InputText', InputText)
  .component('TextArea', TextArea)
  .component('InputMask', InputMask)
  .component('Dropdown', Dropdown);

// Translations temp
// TODO aboykov Inspect this!
// import i18nT from '../locale/locales.json'
// {
//   let data;
//   data = {
//     "en": {
//       ...i18nT.en.translations
//     },
//     "mk": {
//       ...i18nT.mk.translations
//     }
//   }
//
//   console.log(data)
// }