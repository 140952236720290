var script = {
  name: 'ColumnGroup',
  props: {
    type: {
      type: String,
      default: null
    }
  },
  render() {
    return null;
  }
};

export {script as default};
