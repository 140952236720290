import {createStore} from 'vuex';
import axios from 'axios';
import {ROLE} from '@/service/backend/RoleService';
import {useLocalStorageUtils} from '@/utils/LocalStorageUtils';
import {useEmitter} from '@/config/EmitterConfig';
import {logg} from "@/config/Logging";

const AUTH_TOKEN = 'AUTH_TOKEN';
const AUTH_ADMIN = 'AUTH_ADMIN';
const AUTH_EMPLOYEE = 'AUTH_EMPLOYEE';
const AUTH_SELLER = 'AUTH_SELLER';
const AUTH_MANAGER = 'AUTH_MANAGER';

const localStorageUtils = useLocalStorageUtils();

export const store = createStore({
  state() {
    return {
      /** @type {Boolean | String} **/
      hasAuthenticatedUser: localStorage.getItem(AUTH_TOKEN),
      /** @type {Boolean | String} **/
      isAuthorizedAdmin: localStorage.getItem(AUTH_ADMIN),
      /** @type {Boolean | String} **/
      isAuthorizedEmployee: localStorage.getItem(AUTH_EMPLOYEE),
      /** @type {Boolean | String} **/
      isAuthorizedSeller: localStorage.getItem(AUTH_SELLER),
      /** @type {Boolean | String} **/
      isAuthorizedManager: localStorage.getItem(AUTH_MANAGER),
    };
  },
  actions: {
    localLogIn: async (state, payload) => {
      const token = payload.token;
      const role = payload.role;
      const callback = payload.callback;

      await localStorage.setItem(AUTH_TOKEN, token);

      await store.dispatch('setTokenForEveryRequest');
      store.state.hasAuthenticatedUser = true;
      await store.dispatch('setAuthorizationRole', {role});
      callback()
    },
    localLogOut: async () => {
      await localStorage.removeItem(AUTH_TOKEN);
      await localStorage.removeItem(AUTH_ADMIN);
      await localStorage.removeItem(AUTH_EMPLOYEE);
      await localStorage.removeItem(AUTH_SELLER);
      await localStorage.removeItem(AUTH_MANAGER);
      await localStorageUtils.removePaymentSessionId();
      await localStorageUtils.removePaymentSessionUrl();
      await store.dispatch('removeTokenForEveryRequest');
      logg('store', 'Cleared token from local storage!');
      store.state.hasAuthenticatedUser = false;
      store.state.isAuthorizedAdmin = false;
      store.state.isAuthorizedEmployee = false;
      store.state.isAuthorizedSeller = false;
      store.state.isAuthorizedManager = false;

      useEmitter().emitReloadMenuItems()
    },
    setAuthorizationRole: (state, payload) => {
      const role = payload.role;

      if (role === ROLE.ROLE_ADMIN) {
        localStorage.setItem(AUTH_ADMIN, true.toString());
        store.state.isAuthorizedAdmin = true;
      } else if (role === ROLE.ROLE_EMPLOYEE) {
        localStorage.setItem(AUTH_EMPLOYEE, true.toString());
        store.state.isAuthorizedEmployee = true;
      } else if (role === ROLE.ROLE_SELLER) {
        localStorage.setItem(AUTH_SELLER, true.toString());
        store.state.isAuthorizedSeller = true;
      } else if (role === ROLE.ROLE_MANAGER) {
        localStorage.setItem(AUTH_MANAGER, true.toString());
        store.state.isAuthorizedManager = true;
      }

      useEmitter().emitReloadMenuItems()
    },
    setTokenForEveryRequest: () => {
      let token = localStorage.getItem(AUTH_TOKEN);
      axios.defaults.headers.Authorization = `Bearer ${token}`;
      logg('store', 'Authorization token set for every request!');
    },
    removeTokenForEveryRequest: () => {
      axios.defaults.headers.Authorization = 'Bearer ';
      logg('store', 'Authorization token removed for every request!');
    }
  },
  mutations: {
    // Mutations are only synchronous, actions are async
    test() {
      alert('TEST');
    }
  }
});

export const useLocalStore = () => {
  return {
    localLogIn: (token, role, callback) => store.dispatch('localLogIn', {token, role, callback}),
    localLogOut: () => store.dispatch('localLogOut'),

    hasAuthenticatedUser: () => store.state.hasAuthenticatedUser,
    isAuthorizedAdmin: () => store.state.isAuthorizedAdmin,
    isAuthorizedEmployee: () => store.state.isAuthorizedEmployee,
    isAuthorizedSeller: () => store.state.isAuthorizedSeller,
    isAuthorizedManager: () => store.state.isAuthorizedManager,

    setAuthorizationRole: (role) => store.dispatch('setAuthorizationRole', {role}),
    setTokenForEveryRequest: () => store.dispatch('setTokenForEveryRequest'),
  }
}