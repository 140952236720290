<template>
  <div>
    <!-- Modal -->
    <InfoModal></InfoModal>
    <!-- ***** Preloader Start ***** -->
    <div id="preloader">
      <div class="jumper">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <!-- ***** Preloader End ***** -->

    <TopNavigation></TopNavigation>

    <div class="nav-space">
      <router-view />
    </div>
  </div>
</template>

<script>
import '@/assets/js/myCargo'
import 'bootstrap'

import TopNavigation from './screens/main/TopNavigation';
import InfoModal from '@/screens/partial/InfoModal';
import {useLocalStore} from "@/utils/store";

const localStore = useLocalStore()

export default {
  name: 'myCargo',
  components: {
    InfoModal,
    TopNavigation
  },
  mounted() {
    localStore.setTokenForEveryRequest();
  }
  }
</script>

<style>
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: white;
  }
</style>
