<template>
  <PageLayout>
    <InternalViewInvoices
        :title="title"
        :columns="columns"
        :empty-grid-message="emptyGridMessage"
    />
  </PageLayout>
</template>

<script>
  import InternalViewInvoices from '../../shared/invoice/InternalViewInvoices';
  import PageLayout from '@/shared/layout/PageLayout';

  const columns = [
    {
      field: 'uin',
      header: 'Бр. на фактура'
    },
    {
      field: 'creation',
      sortableField: 'creationSortable',
      header: 'Креирана',
      sortable: true,
      defaultSortable: true
    },
    {
      field: 'buyerNameSurname',
      header: 'Нарачал',
      sortable: true
    },
    {
      field: 'packagesLength',
      header: 'Вкупно пратки',
      sortable: true
    }
  ]

  export default {
    name: 'ManagerUnassignedInvoicesPage',
    components: {PageLayout, InternalViewInvoices},
    data() {
      return {
        columns: columns,
        emptyGridMessage: 'Листата на фактури кои треба да се доделат на вработен е празна.',
        title: 'Листа на недоделени фактури'
      }
    }
  }
</script>

<style scoped>

</style>