<template>
  <PageLayout>
    <div class="row justify-content-md-center">
      <div class="col-md-6">
        <div class="card-form-box">
          <h3>{{ getLabel('label.title.login') }}</h3>
          <form autocomplete="off">
            <Input
                id="username"
                type="text"
                :label="getLabel('label.input.username')"
                v-model="username"
            />
            <Input
                id="password"
                type="password"
                :label="getLabel('label.input.password')"
                v-model="password"
            />
            <MainButton
                :loading="loading"
                :text="getLabel('label.button.login')"
                :on-click="login"
            />
          </form>
          <h6>{{ getLabel('label.login.text.no.profile') }}</h6>
          <h6>{{ getLabel('label.login.text.create_profile_now') }}</h6>
          <br/>
          <MainButton
              small-btn
              :text="getLabel('label.button.signup')"
              :on-click="navigateToSignup"
          />
        </div>
      </div>
    </div>
  </PageLayout>
</template>

<script>
import axios from 'axios';
import Response from '../../models/backend/Response';
import router, {ROUTES} from '../../router';
import {useNotificationService} from '@/service/NotificationService';
import MainButton from '../partial/MainButton';
import {useRoute} from 'vue-router';
import PageLayout from '@/shared/layout/PageLayout';
import {useEmitter} from '@/config/EmitterConfig';
import Input from "@/shared/input/Input";
import {useLocalStore} from "@/utils/store";
import {useMessageSource} from "@/config/MessageSource";

const notificationService = useNotificationService()
const localStore = useLocalStore()
const {getLabel} = useMessageSource()

const stateMutations = {
  /** @param {boolean} data **/
  setLoading: function (data) {
    this.loading = data
  }
}

export default {
  name: 'Login',
    components: {Input, PageLayout, MainButton},
    data() {
      return {
        /** @type {boolean} data **/
        loading: false,
        /** @type {string} data **/
        username: '',
        /** @type {string} data **/
        password: ''
      }
    },
    mounted() {
      const {infoMessage} = useRoute().params;

      notificationService.maybeShowInfoModal(infoMessage)
    },
    methods: {
      getLabel,
      login() {
        this.setLoading(true)

        let body = {
          username: this.username,
          password: this.password
        }

        axios.post('login', null, {params: body}).then(it => {
          let response = new Response(it);

          localStore.localLogIn(response.data.token, response.data.role, () => {
            useEmitter().emitReloadMenuItems()
          })
          router.push(ROUTES.Home.path)
        }).catch(it => {
          let response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally(() => this.setLoading(false));
      },
      /** --------------------- Navigation resolver functions --------------------- **/
      navigateToSignup() {
        router.push(ROUTES.Home.nested.Signup.path);
      },
      ...stateMutations
    }
  }
</script>