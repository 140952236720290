<template>
  <TitledPage
      title="Промена на податоци за вработен"
      :on-back="onBack"
      :on-save="saveEmployee"
  >

    <LoadingSpinner v-if="loading.main" />

    <div v-if="!loading.main">
      <div class="card-body form-input-box">
        <form autocomplete="off">
          <Input
              id="name"
              type="text"
              label="Име"
              v-model="employee.name"
          />
          <Input
              id="surname"
              type="text"
              label="Презиме"
              v-model="employee.surname"
          />
          <MaskedInput
              id="embg"
              label="ЕМБГ"
              v-model="employee.embg"
              mask="#############"
          />
          <Input
              id="address"
              type="text"
              label="Адреса"
              v-model="employee.address"
          />

          <div class="input-box" style="text-align: left;">
            <div class="row row-eq-height align-items-center d-flex align-items-stretch">
              <div class="col-sm-6 p-0">
                <SelectInput
                    id="idCity"
                    label="Град"
                    :data="cities"
                    v-model="employee.city.id"
                />
              </div>
              <div class="col-sm-6" style="padding-top: 1.2rem!important;">
                <span>Р. Северна Македонија</span>
              </div>
            </div>
          </div>

          <MaskedInput
              id="phoneNumber"
              label="Телефонски број"
              v-model="employee.phoneNumber"
              mask="+389 7# ### ###"
          />
        </form>
      </div>
    </div>
  </TitledPage>
</template>

<script>
import axios from 'axios';
import Response from '@/models/backend/Response';
import {Employee} from '@/models/entity/Employee';
import {useNotificationService} from '@/service/NotificationService';
import {useEntityParser} from '@/utils/EntityParser';
import {useRoute} from 'vue-router';
import LoadingSpinner from '@/screens/partial/LoadingSpinner';
import router, {resolveBaseRoute} from '@/router';
import {useFormFieldService} from '@/service/FormFieldService';
import TitledPage from '@/shared/layout/TitledPage';
import Input from "@/shared/input/Input";
import MaskedInput from "@/shared/input/MaskedInput";
import SelectInput from "@/shared/input/SelectInput";

const notificationService = useNotificationService()
const entityParser = useEntityParser()
const formFieldService = useFormFieldService()

const stateMutations = {
  /** @param {Employee} data **/
  setEmployee: function (data) {
    this.employee = data
  },
    /** @param {Array<City>} data **/
    setCities: function (data) {
      this.cities = data
    },
    /** @param {boolean} data **/
    setLoadingMain: function (data) {
      this.loading.main = data
    },
    /** @param {boolean} data **/
    setLoadingSave: function (data) {
      this.loading.save = data
    }
  }

  export default {
    name: 'EmployeeBasicInformationEditPage',
    components: {SelectInput, MaskedInput, Input, TitledPage, LoadingSpinner},
    data() {
      return {
        /** @type {Number} **/
        employeeId: undefined,
        loading: {
          /** @type {Boolean} **/
          main: true,
          /** @type {Boolean} **/
          save: false
        },
        /** @type {Employee} **/
        employee: undefined,
        /** @type {Array<City>} **/
        cities: undefined
      }
    },
    mounted() {
      const {id} = useRoute().params
      this.employeeId = parseInt(id.toString())

      this.getData();
      this.fetchCities();
    },
    methods: {
      onBack() {
        router.push({
          name: resolveBaseRoute().nested.Employees.nested.Employee.name,
          params: {
            id: this.employeeId
          }
        })
      },
      getData() {
        axios.get(`employees/${this.employeeId}`).then(it => {
          const response = new Response(it);

          const employee = new Employee(response.data);

          this.setEmployee(employee);
          this.setLoadingMain(false);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally()
      },
      fetchCities() {
        // TODO aboykov If I add delay here, the page will be loaded with empty cities. They will be
        //  of course shown with delay. Solve this. There is implementation in SellerInvoiceNewPage using
        //  watchers.
        axios.get('city').then(it => {
          const response = new Response(it);

          const cities = entityParser.toCities(response.data);

          this.setCities(cities);
        }).catch(it => {
          const response = new Response(it.response);

          notificationService.showInfoModal(response.message);
        }).finally(() => this.setLoadingMain(false))
      },
      saveEmployee() {
        this.setLoadingSave(true);

        formFieldService.resetInputErrors();

        const requestObject = this.employee;
        requestObject.idCity = requestObject.city.id;

        axios.put(`employee/${this.employeeId}`, requestObject).then(it => {
          const response = new Response(it);

          router.push({
            name: resolveBaseRoute().nested.Employees.nested.Employee.name,
            params: {
              id: this.employeeId,
              infoMessage: response.message
            }
          });

        }).catch(it => {
          const response = new Response(it.response);

          if (response.hasErrors()) {
            formFieldService.populateErrorFields(response.errors);
          }

          // Do not show error if the validation violation happens. Errors will show up
          // under every input field.
          if (!response.isEntityNotValidError()) {
            notificationService.showInfoModal(response.message)
          }
        }).finally(() => this.setLoadingSave(false));
      },
      ...stateMutations
    }
  }
</script>

<style scoped>

</style>