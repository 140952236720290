<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog"
       aria-labelledby="myCargoConfirmationDialog"
       aria-hidden="true"
       data-keyboard="false" data-backdrop="static"
  >
    <div class="modal-dialog modal-notify modal-info" role="document">
      <!--Content-->
      <div class="modal-content text-center">

        <!--Header-->
        <div class="modal-header d-flex justify-content-center">
          <p class="heading">{{ getLabel('label.title.confirmation') }}</p>
        </div>

        <!--Body-->
        <div class="modal-body">
          <i class="fa fa-question-circle fa-4x mb-4"></i>
          <p>
            <span id="mainMessage"> {{ text }}</span>
          </p>
          <hr v-if="price" />
          <span v-if="price">
            <span>{{ getLabel('label.title.payment_total', price, getLabel('label.currency.mkd')) }}</span>
          </span>
        </div>

        <!--Footer-->
        <div class="modal-footer flex-center">
          <a type="button" class="ternary-button" style="float: left;" v-on:click="handleSuccess">
            {{ getLabel('label.button.yes') }}
          </a>
          <a type="button" class="ternary-button" v-on:click="handleCancel">
            {{ getLabel('label.button.no') }}
          </a>
        </div>
      </div>
      <!--/.Content-->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import {useMessageSource} from "@/config/MessageSource";

const {getLabel} = useMessageSource()

export default {
  name: 'ConfirmationDialog',
  props: {
    id: {
      required: true,
      type: String
    },
    showing: {
      required: true,
        type: Boolean
      },
      text: {
        required: true,
        type: String
      },
      price: {
        required: false,
        type: Number
      },
      onSuccess: {
        type: Function,
        required: true
      },
      onCancel: {
        type: Function,
        required: true
      }
    },
    methods: {
      getLabel,
      handleSuccess() {
        this.onSuccess();
      },
      handleCancel() {
        this.onCancel();
      },
      updateState() {
        const show = this.showing ? 'show' : 'hide';

        $(`#${this.id}`).modal(show);
      }
    },
    mounted() {
      this.updateState();
    },
    updated() {
      this.updateState();
    }
  }
</script>

<style scoped src="@/assets/css/partial/dialog-css.css" />